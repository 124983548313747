import { ChangeEvent, useCallback, useMemo } from 'react';

import {
  EnumCreditAllocationStatus,
  EnumCreditAllocationType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import { MpTextField, SingleSelection } from '../../../../components';
import { useDatePicker } from '../../../../components/DatePicker';
import FilterTemplate from '../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { useFilterFields, useGenFilterFields } from '../../../../hooks/useFilter';
import { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { IsearchParams, useZusParams } from '../hooks';
import { filterTableTranslateKey as TK } from '../mappings/translate';

const initFields = {
  transferOrderId: '',
  allocationType: '',
  from: '',
  to: '',
  status: '',
};

export default function Filter() {
  const translatePrefix: Iprefix = 'creditAllocationRecord';
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const DateFields = { CreationDate: useDatePicker(), LastModifiedDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    dateFields: DateFields,
  });

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const {
      transferOrderId: targetTransferOrderId,
      allocationType: type,
      from: fromWalletClientId,
      to: toWalletClientId,
      status,
    } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateFields.LastModifiedDate;

    return {
      targetTransferOrderId,
      type,
      fromWalletClientId,
      toWalletClientId,
      status,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  }, [
    fields,
    DateFields.CreationDate.start,
    DateFields.CreationDate.end,
    DateFields.LastModifiedDate.start,
    DateFields.LastModifiedDate.end,
  ]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },
      {
        label: t(TK.lastModifiedTime),
        element: <DateFields.LastModifiedDate.Picker type={'dateTime'} />,
      },

      {
        label: t(TK.transferOrderId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.transferOrderId) })}
            value={fields.transferOrderId}
            onChange={onChange('transferOrderId')}
          />
        ),
      },
      {
        label: t(TK.allocationType),
        element: (
          <SingleSelection
            enumData={EnumCreditAllocationType}
            clearSelect={() => setFields((prev) => ({ ...prev, allocationType: '' }))}
            label={tc('phInputField', { fieldName: t(TK.allocationType) })}
            value={fields.allocationType}
            onChange={onChange('allocationType')}
          />
        ),
      },
      {
        label: t(TK.from),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.from) })}
            value={fields.from}
            onChange={onChange('from')}
          />
        ),
      },
      {
        label: t(TK.to),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.to) })}
            value={fields.to}
            onChange={onChange('to')}
          />
        ),
      },
      {
        label: t(TK.status),
        element: (
          <SingleSelection
            enumData={EnumCreditAllocationStatus}
            clearSelect={() => setFields((prev) => ({ ...prev, status: '' }))}
            label={tc('phInputField', { fieldName: t(TK.status) })}
            value={fields.status}
            onChange={onChange('status')}
          />
        ),
      },
    ],
    [fields, DateFields]
  );

  return <FilterTemplate filterFields={filterFields} onSearch={onSearch} onReset={onReset} />;
}
