import { useCallback, useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

import APIs from '../../../api';
import AmsLogoSrc from '../../../assets/logo/ams-logo.png';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../common/dialogs/layout/contentLayout';
import SystemCurrencySingleSelection from '../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import CopyableText from '../../../components/CopyableText';
import { useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';

export default function ProgramAgentDeposit() {
  const { t } = useTranslation('programAgentDeposit');

  const [network, setNetwork] = useState<any>('');
  const [walletAddress, setWalletAddress] = useState('');

  const { showLoading, hideLoading } = useLoading();

  const isMobile = useAppSelector(selectIsMobileView);

  const isHaveWalletAddress = walletAddress && walletAddress.length > 0;
  const QR_CODE_SIZE = 300;

  const updateWalletAddress = useCallback(async () => {
    if (!network) {
      return setWalletAddress('');
    }

    const { chainCode, tokenName } = network;

    showLoading('getWalletAddress');

    const res = await APIs.programAgentManagement.programAgentDeposit.getWalletAddress({
      chainCode,
      tokenName,
    });

    hideLoading('getWalletAddress');

    if (!res) return;

    const walletAddress = res.address;

    setWalletAddress(walletAddress);
  }, [network]);

  useEffect(() => {
    updateWalletAddress();
  }, [updateWalletAddress]);

  return (
    <>
      <Container sx={{ maxWidth: '800px', padding: isMobile ? '0 32px' : '0' }}>
        <Row>
          <RowHeader>{t('currency')}</RowHeader>
          <RowContent>
            <SystemCurrencySingleSelection
              value={network as any}
              onChange={(e) => {
                setNetwork(JSON.parse(e.target.value));
              }}
              onClear={() => setNetwork('')}
            />
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('qrCode')}</RowHeader>
          <RowContent>
            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
              {isHaveWalletAddress && (
                <QRCode
                  value={walletAddress}
                  size={QR_CODE_SIZE}
                  eyeRadius={24}
                  fgColor="#333746"
                  bgColor="#F8FAFF"
                  logoImage={AmsLogoSrc}
                  logoWidth={QR_CODE_SIZE * 0.3}
                  ecLevel={'H'}
                />
              )}
            </div>
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('walletAddress')}</RowHeader>
          <RowContent>
            <div style={{ display: 'inline-block' }}>
              <CopyableText text={walletAddress} />
            </div>
          </RowContent>
        </Row>
      </Container>
    </>
  );
}
