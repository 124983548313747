import React from 'react';
import { Button } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  onClick: (e: any) => void;
}

const CancelButton = (props: IProps) => {
  const { onClick } = props;

  const { tc } = useTranslation();

  return (
    <Button
      className={'inheritColor'}
      color={'secondary'}
      variant={'contained'}
      // children={tc('cancel')}
      children={tc('cancel')}
      onClick={onClick}
    />
  );
};

export default CancelButton;
