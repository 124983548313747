import distributorAgentList from '../setting/distributorAgentList';
import distributorAgentProgram from '../setting/distributorAgentProgram';
import exchangeRate from '../setting/exchangeRate';
import userSetting from '../setting/userSetting';
import exchangeRateConfiguration from './exchangeRateConfiguration';

export default {
  exchangeRate,
  exchangeRateConfiguration,
  userSetting,
  distributorAgentList,
  distributorAgentProgram,
};
