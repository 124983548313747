import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumVerificationType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';
import { EPtAuth } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import { readVerificationToken } from '../../utils';
import { enumLanguage } from '../../utils/constant';
import axios from '../axiosInstance';
import { IloginResult } from '../types';

const endpointAuth = { ...EPtAuth.auth };
const getAccessToken = async (authToken: string, config = {}) => {
  const params = { accessToken: authToken };
  const res = await axios.post<never, IloginResult>(endpointAuth.verifyAccessToken, params, config);
  return res;
};

const getNewTokens = async (refreshToken: string, config = {}) => {
  return await axios.post<never, IloginResult>(
    AgentPortal.EPtAuth.auth.refreshToken,
    { refreshToken },
    {
      ...config,
      ignoreToken: true,
    } as any
  );
};

// PL1
const login = async (params: { email: string; password: string }, config = {}) => {
  const email = params.email.trim();

  const res = await axios.post<never, any>('agent-portal/auth/login', { ...params, email }, {});
  return res;
};

// PL1-2
const loginV2 = async (
  params: { email: string; password: string; deviceId: string; programAgentId: string },
  config = {}
) => {
  const email = params.email.trim();

  const res = await axios.post<never, any>(
    AgentPortal.EPtAuth.auth.portalAgentLoginV2,
    { ...params, email },
    {}
  );
  return res;
};

// determine reset password or login
// PL7 - agent-portal/auth/login-status
const getLoginStatus = async (params: { email: string }, config?: any) => {
  const email = params.email.trim();

  const input = { params: { ...params, email }, ...config };
  const res = await axios.get<never, { isForcePasswordChange: boolean }>(
    'agent-portal/auth/login-status',
    input
  );
  return res;
};

const getLoginStatusV2 = async (
  params: { email: string; deviceName: string; deviceId: string; programAgentId: string },
  config?: any
) => {
  const email = params.email.trim();

  const input = { params: { ...params, email }, ...config };
  const res = await axios.get<
    never,
    { isForcePasswordChange: boolean; isDeviceRegistered: boolean; isEmailExist: boolean }
  >(AgentPortal.EPtAuth.auth.loginStatusV2, input);
  return res;
};

// get temp token for calling request email code
// PL3 - agent-portal/auth/request-temp-token
const requestTempToken = async (
  params: { email: string; type: EnumVerificationType },
  config = {}
) => {
  const email = params.email.trim();

  const res = await axios.post<never, { token: string }>(
    AgentPortal.EPtAuth.auth.requestTempToken,
    { ...params, email },
    config
  );

  return res;
};

// PL4 - agent-portal/auth/request-email-code
const requestEmailVerificationCode = async (
  params: { lang: keyof typeof enumLanguage },
  config = {}
) => {
  const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.requestEmailCode, params, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });

  return res;
};

// verify email code
// PL5 - agent-portal/auth/verify-email-code
const verifyEmailVerificationCode = async (params: { code: string }, config = {}) => {
  const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.verifyEmailCode, params, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });

  return res;
};

// reset password
// PL6 - agent-portal/auth/reset-password
const resetPassword = async (params: { password: string }, config = {}) => {
  const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.resetPassword, params, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });

  return res;
};

const resetPasswordV2 = async (params: { password: string }, config = {}) => {
  const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.resetPasswordV2, params, {
    headers: {
      Authorization: `Bearer ${verificationToken}`,
    },
  });

  return res;
};

// PL8
const newDeviceLogin = async (
  params: {
    deviceId: string;
    deviceName: string;
    email: string;
    password: string;
    rememberDeviceId: boolean;
    token: string;
    programAgentId: string;
  },
  config = {}
) => {
  const email = params.email.trim();

  // const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(
    AgentPortal.EPtAuth.auth.newDeviceLogin,
    { ...params, email },
    {
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    }
  );

  return res;
};

// PL9
const registerDevice = async (
  params: { deviceId: string; deviceName: string; token: string },
  config = {}
) => {
  // const verificationToken = readVerificationToken();

  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.registerDevice, params, {
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  });

  return res;
};

// App Entry

const appLogin = async (params: { token: string | null }, config = {}) => {
  const res = await axios.post<never, any>(AgentPortal.EPtAuth.auth.redirectLogin, params, config);

  return res;
};

export default {
  getAccessToken,
  getNewTokens,
  login,
  loginV2,
  getLoginStatus,
  getLoginStatusV2,
  requestTempToken,
  requestEmailVerificationCode,
  verifyEmailVerificationCode,
  resetPassword,
  resetPasswordV2,
  newDeviceLogin,
  registerDevice,
  appLogin,
};
