import BigNumber from 'bignumber.js';
import { ChangeEvent, useState } from 'react';

import { EnumRebateMode } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import APIs from '../../../../../api';
import { PartialSearchDistributorAgentIdResponse } from '../../../../../api/types';
import DistributorAgentIdAutoComplete from '../../../../../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';
import { DialogInOne, SingleSelection } from '../../../../../components';
import AgentProgramNameSelection from '../../../../../components/AgentProgramNameSelection';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { GridBox } from '../../../../../components/Layout';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import MpTextFieldWithAdornment from '../../../../../components/TextField/MpTextFieldWithAdornment';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useGridBoxInputValidation from '../../../../../hooks/useGridBoxInputValidation';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../../zustand/store';
import { useZusParams } from '../../hooks';
import { assignProgramDialogTranslateKey as TK } from '../../mappings/translate';

export default function AssignProgramDialog() {
  const translatePrefix: Iprefix = 'distributorAgentProgram';

  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const initFields = {
    distributorAgentId: '',
    programName: '',
    rebateMode: '',
    rebateRateToDistributorAgent: '',
  };

  const [fields, setFields] = useState(initFields);
  const { validateGridBoxInput } = useGridBoxInputValidation();
  const { alerting } = useAlerting();
  const zusParams = useZusParams();

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((prev) => ({ ...prev, [field]: e.target.value }));
    };

  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);

  const handleDistributorAgentIdInputChange = async (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));

    if (value) {
      // showLoading('searchDistributorAgentId');
      const res = await APIs.setting.userSetting.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });
      // hideLoading('searchDistributorAgentId');

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value, programName: '' }));
  };

  const labelElePairArr: [string, JSX.Element][] = [
    [
      TK.distributorAgentId,
      <DistributorAgentIdAutoComplete
        value={fields.distributorAgentId}
        options={distributorAgentIdOptions}
        onInputChange={(value: string) => {
          handleDistributorAgentIdInputChange(value);
        }}
        handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
          handleDistributorAgentIdOptionChange(option?.distributorAgentId);
        }}
        setOptions={setDistributorAgentIdOptions}
      />,
    ],
    [
      TK.programName,
      <AgentProgramNameSelection
        value={fields.programName}
        onChange={onChange('programName')}
        clearSelect={() => {}}
        mode={'assignableOnly'}
        distributorAgentId={fields.distributorAgentId || ''}
      />,
    ],
    [
      TK.rebateMode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.rebateMode) })}
        value={fields.rebateMode}
        onChange={onChange('rebateMode')}
        enumData={EnumRebateMode}
        clearSelect={() => {}}
      />,
    ],
    [
      TK.rebateRateToDistributorAgentAndClient,
      <MpTextFieldWithAdornment
        mode="number"
        label={tc('phInputField', { fieldName: t(TK.rebateRateToDistributorAgentAndClient) })}
        value={fields.rebateRateToDistributorAgent}
        onChange={onChange('rebateRateToDistributorAgent')}
        endAdornment={{ adornmentNode: '%' }}
        decimalCount={4}
        showDecimalKeyboard
      />,
    ],
  ];

  const onClose = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const onConfirm = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr);

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const { rebateRateToDistributorAgent, rebateMode, ...rest } = fields;

    const rebateRateToDaNumber = Number(rebateRateToDistributorAgent);

    if (rebateRateToDaNumber > 100) {
      return alerting(
        'warning',
        tc('cannot_exceed_warning', {
          fieldName: t(TK.rebateRateToDistributorAgent),
          value: '100%',
        })
      );
    }

    const daRebateRate = new BigNumber(rebateRateToDaNumber).dividedBy(100).toNumber();

    const res = await APIs.setting.distributorAgentProgram.postAssignProgram({
      daRebateRate,
      rebateMode: Number(rebateMode),
      ...rest,
    });

    if (!res) {
      return;
    }

    await onClose();
    alerting('success', t(TK.assignProgramSuccess));
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.assignProgramBtn),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: onClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm,
    onCancel: onClose,
  };

  return <DialogInOne {...dialogConfig} />;
}
