import { TcspApplicationAnswer } from '../..';
import SeniorPublicFigureDeclarationList from './SeniorPublicFigureDeclarationList';
import SeniorPublicFigureDeclarationSection from './SeniorPublicFigureDeclarationSection';

const PersonalInfo3 = ({ data }: { data: TcspApplicationAnswer }) => {
  return (
    <>
      <SeniorPublicFigureDeclarationSection
        isHaveDeclaration={data.seniorPublicFigureDeclaration}
      />

      <SeniorPublicFigureDeclarationList list={data.seniorPublicFigureList} />
    </>
  );
};

export default PersonalInfo3;
