import { ApiResult } from '../../../../../api/types';
import { useTranslation } from '../../../../../hooks';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { displayAmountCurrying, toDisplayTime } from '../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc } = useTranslation();

  const profile = useAppSelector(selectProfile);

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const displayBalance = displayAmountCurrying(row.decimals)(row.balance);
      const displayFrozenBalance = displayAmountCurrying(row.decimals)(row.frozenBalance);

      return {
        id: index,
        [TableColumnKey.ClientId]: profile?.jwtDetails?.programAgentId || '',
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.Balance]: displayBalance,
        [TableColumnKey.FrozenBalance]: displayFrozenBalance,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
