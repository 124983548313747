import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../../axiosInstance';
import { IlistResult } from '../../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(AgentPortal.EptReport.daReport.rebateGetAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<any>>(AgentPortal.EptReport.daReport.rebateExport, input);
};

export default { getAll, getExport };
