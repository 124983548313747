import React from 'react';
import Header from './components/Header';
import HeaderRow from './components/HeaderRow';
import FeeSettingRow from './components/FeeSettingRow';

interface IProps {
  acceptorCountry: string;
  acquirerCurrency: string;
  localFee: string;
  overseasFee: string;
  localAgentMarkup: string;
  overseasAgentMarkup: string;
}

const FeeInfo = (props: IProps) => {
  const {
    acceptorCountry,
    acquirerCurrency,
    localFee,
    overseasFee,
    localAgentMarkup,
    overseasAgentMarkup,
  } = props;

  return (
    <div style={{ marginBottom: '32px' }}>
      <div>
        <HeaderRow acceptorCountry={acceptorCountry} acquirerCurrency={acquirerCurrency} />
      </div>

      <div style={{ marginTop: '6px' }}>
        <FeeSettingRow
          localFee={localFee}
          overseasFee={overseasFee}
          localAgentMarkup={localAgentMarkup}
          overseasAgentMarkup={overseasAgentMarkup}
        />
      </div>
    </div>
  );
};

export default FeeInfo;
