import React from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';

import { IconButton, MenuItem, Tooltip } from '../MuiGenerals';

interface IbuttonMenuProps {
  options: {
    name: string;
    onClickFn: (event?: React.MouseEvent<HTMLElement>) => void;
    noShow?: boolean;
    isDisabled?: boolean;
  }[];
  title?: string;
  menuBtnOnClickFn?: (event?: React.MouseEvent<HTMLElement>) => Promise<void> | void;
}

export default function ButtonMenu({ options, title = '', menuBtnOnClickFn }: IbuttonMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;

    if (menuBtnOnClickFn) {
      await menuBtnOnClickFn();
    }

    setAnchorEl(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(({ name, onClickFn, noShow, isDisabled }) => {
          const handleOnClick = () => {
            onClickFn();
            handleClose();
          };

          return (
            !noShow && (
              <MenuItem key={name} onClick={handleOnClick} disabled={isDisabled}>
                {name}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
}
