import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface LoadingState {
  loadingKeys: string[];
}

const initialState: LoadingState = {
  loadingKeys: [],
};
export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    addLoadingKey: (state, action: PayloadAction<{ key: string }>) => {
      let newLoadingKeys = state.loadingKeys;
      const isKeyAlreadyExist = state.loadingKeys.includes(action.payload.key);

      if (!isKeyAlreadyExist) {
        newLoadingKeys = [...state.loadingKeys, action.payload.key];
      }

      state.loadingKeys = newLoadingKeys;
    },
    removeLoadingKey: (state, action: PayloadAction<{ key: string }>) => {
      const newLoadingKeys = state.loadingKeys.filter((key) => key !== action.payload.key);
      state.loadingKeys = newLoadingKeys;
    },
  },
});

export const selectIsShowLoading = (state: RootState) => state.loading.loadingKeys.length > 0;

export const selectLoadingKeys = (state: RootState) => state.loading.loadingKeys;

export const { addLoadingKey, removeLoadingKey } = loadingSlice.actions;
export default loadingSlice.reducer;
