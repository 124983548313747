import React, {
  DependencyList,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';

import { DatePickerReturnType } from '../components/DatePicker';
import { Box, Button, Typography } from '../components/MuiGenerals';
import { customSx } from '../utils/styling';
import useGridFilterCustomSx from './useGridFilterCustomSx';
import usePermission from './usePermission';

type IfilterBtnProps = {
  name: string;
  clickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permission: string;
};

function useGenFilterBtnBar(buttons: IfilterBtnProps[]) {
  const { hasPermission } = usePermission();

  const filterBtnBar = useMemo(
    () => (
      <Box sx={customSx.filterCmdBar}>
        {buttons.map(({ name, clickHandler, permission }) => (
          <Button variant="outlined" onClick={clickHandler} key={permission}>
            {name}
          </Button>
          // hasPermission(permission) && (
          //   <Button variant="outlined" onClick={clickHandler}>
          //     {name}
          //   </Button>
        ))}
      </Box>
    ),
    [buttons]
  );

  return { filterBtnBar };
}

type IfilterProps = {
  label: string;
  element: ReactNode;
};

function useGenFilterFields(filters: IfilterProps[], dependencyList: DependencyList) {
  const { gridFilterSx } = useGridFilterCustomSx();

  const filterFields = useMemo(
    () => (
      <Box sx={gridFilterSx} className="gridFilter">
        {filters.map(({ label, element }) => (
          <React.Fragment key={label}>
            <Typography
              variant="subtitle2"
              sx={{
                padding: '25px 0px 0px 0px',
              }}
            >
              {label}
            </Typography>
            <div className="filterFieldInput">{element}</div>
          </React.Fragment>
        ))}
      </Box>
    ),
    dependencyList
  );

  return { filterFields };
}

function useFilterFields<T extends {}>({
  initFields,
  fieldWithDefaultValue,
  dateFields,
}: {
  initFields: T;
  fieldWithDefaultValue?: Partial<T>;
  dateFields?: Record<string, DatePickerReturnType>;
}) {
  const [fields, setFields] = useState({ ...initFields, ...fieldWithDefaultValue });

  const onReset = useCallback(() => {
    setFields(initFields);

    if (!dateFields) {
      return;
    }

    Object.values(dateFields).forEach(({ resetDate }) => {
      resetDate();
    });
  }, [dateFields]);

  return { onReset, fields, setFields };
}

export { useGenFilterBtnBar, useGenFilterFields, useFilterFields };
