import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { IlistResult } from '../types';

const balanceTab = {
  getAll: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get<any, IlistResult<any>>(
      AgentPortal.EPtPaManagement.programAgentWallet.getAll,
      input
    );
  },
  getExport: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };

    return await axios.get<any, IlistResult<any>>(
      AgentPortal.EPtPaManagement.programAgentWallet.export,
      input
    );
  },
};

const historyTab = {
  getAll: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };
    return await axios.get<any, IlistResult<any>>(
      AgentPortal.EPtPaManagement.programAgentWalletHistory.getAll,
      input
    );
  },
  getExport: async (params: any, config?: any) => {
    const { pageSize, page, ...rest } = params;

    const input = {
      params: {
        ...rest,
        limit: pageSize,
        offset: pageSize * page,
      },
      ...config,
    };

    return await axios.get<any, IlistResult<any>>(
      AgentPortal.EPtPaManagement.programAgentWalletHistory.export,
      input
    );
  },
};

export default { balanceTab, historyTab };
