import {
  EnumCreditSwapStatus,
  EnumCreditSwapStep,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import { IdateFilter, Ipage } from '../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import { amountDivideDecimals, toDisplayTime } from '../../../../utils';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  customerNumber: string;
  assetCurrency: string;
  status: string;
  depositRefNo: string;
}

type ApiParam = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const creditAmountDividedDecimals = amountDivideDecimals(
        String(item.creditAmount),
        item.creditCurrencyDecimals
      );
      const assetAmountDividedDecimals = amountDivideDecimals(
        String(item.assetAmount),
        item.assetCurrencyDecimals
      );

      const mapping: Array<[string, IapiGetAllRes | number | string]> = [
        ['rawData', item],
        [TK.referenceNumber, item.depositRefNo],
        [TK.pfhNumber, item.customerNumber],
        [TK.asset, item.assetCurrency],
        [TK.assetAmount, assetAmountDividedDecimals],
        [TK.exchangeRate, item.exchangeRate],
        [TK.creditCurrency, item.creditCurrency],
        [TK.creditAmount, creditAmountDividedDecimals],
        [TK.status, t(EnumCreditSwapStatus[item.status])],
        [TK.step, t(EnumCreditSwapStep[item.step])],
        [TK.sourceTransferOrderId, item.srcTransferOrderId],
        [TK.targetTransferOrderId, item.targetTransferOrderId],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
