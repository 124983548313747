import { COLUMN_WIDTH } from '../../../../../../constants/ColumnWidth';
import { usePermission } from '../../../../../../hooks';
import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    useGenGridCol(TableColumnKey.SettlementDate),
    useGenGridCol(TableColumnKey.DistributorAgentId),
    useGenGridCol(TableColumnKey.ProgramName),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Purchase),
    useGenGridCol(TableColumnKey.Refund),
    useGenGridCol(TableColumnKey.AccountFunding),
    useGenGridCol(TableColumnKey.Repayment),
    useGenGridCol(TableColumnKey.FundingAdjustment),
    useGenGridCol(TableColumnKey.WaivedLateCharge),
    useGenGridCol(TableColumnKey.WaivedInterest),
    useGenGridCol(TableColumnKey.Reward),
    useGenGridCol(TableColumnKey.Withdraw),
    useGenGridCol(TableColumnKey.ChargeBack),
    useGenGridCol(TableColumnKey.Transfer),
    useGenGridCol(TableColumnKey.RepaymentAdjustment),
    useGenGridCol(TableColumnKey.VipServiceFee),
    useGenGridCol(TableColumnKey.Withholding),
    useGenGridCol(TableColumnKey.Interest),
    useGenGridCol(TableColumnKey.Cashback),
    useGenGridCol(TableColumnKey.Fee),
    useGenGridCol(TableColumnKey.FeeAdjustment),
    useGenGridCol(TableColumnKey.WithdrawFeeAdjustment),
    useGenGridCol(TableColumnKey.AgentMarkupWithdrawFeeAdjustment),
    useGenGridCol(TableColumnKey.OverseaFeeAdjustment),
    useGenGridCol(TableColumnKey.AgentMarkupOverseaFeeAdjustment),
    useGenGridCol(TableColumnKey.UkFeeAdjustment),
    useGenGridCol(TableColumnKey.E6Fee),
    useGenGridCol(TableColumnKey.AnnualFee),
    useGenGridCol(TableColumnKey.FastFund),
    useGenGridCol(TableColumnKey.LoadReversal),
    useGenGridCol(TableColumnKey.LatePaymentFee),
    useGenGridCol(TableColumnKey.Others),
    useGenGridCol(TableColumnKey.TotalIn),
    useGenGridCol(TableColumnKey.TotalOut),
    useGenGridCol(TableColumnKey.TotalRebateTransactionAmount),
    useGenGridCol(TableColumnKey.TotalVisaFee),
    useGenGridCol(TableColumnKey.TotalSwapCost),
    useGenGridCol(TableColumnKey.TotalAgentRebate),
    useGenGridCol(TableColumnKey.TotalSystemCost),
    useGenGridCol(TableColumnKey.TotalProfit),
  ];

  return columns;
};

export default useTableColumns;
