import { defaultListParams } from '../../../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../../../common/filterTable/helpers/cleanParams';
import { convertDateStringOrDateToYearMonth } from '../../../../../../utils/dateUtils';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const { settlementMonthFrom, settlementMonthTo, distributorAgentId, programName, pfhNo } =
      fields;

    const params: GetListParams = {
      ...defaultListParams,
      settlementMonthFrom: convertDateStringOrDateToYearMonth(settlementMonthFrom),
      settlementMonthTo: convertDateStringOrDateToYearMonth(settlementMonthTo),
      distributorAgentId,
      programName,
      customerNumber: pfhNo,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
