export const removeKeysFromEnum = (
  enumObj: any,
  details: {
    keysToRemove?: string[];
    valuesToRemove?: unknown[];
  }
) => {
  const { keysToRemove = [], valuesToRemove = [] } = details;

  const newEnum = { ...enumObj };

  const newEnumObj = Object.fromEntries(
    Object.entries(newEnum).filter(
      ([key, value]: [string, unknown]) =>
        keysToRemove.every((k) => k !== key) && valuesToRemove.every((v) => v !== value)
    )
  );

  return newEnumObj;
};
