import React, { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../../api';
import AgentProgramNameSelection from '../../../components/AgentProgramNameSelection';
import { useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import FilterCard from './components/FilterCard';
import Filter from './components/FilterCard/Filter';
import NoRecords from './components/NoRecords';
import ResultSection from './components/ResultSection';
import { ActiveTabs, CountryRegion, Currency, FeeAdjustmentRes } from './types';

const initialFields = {
  programName: '',
};

const FeeAdjustmentRate = () => {
  const [fields, setFields] = useState<{ programName: string }>(initialFields);
  const [transactionType, setTransactionType] = useState<number>(Number(ActiveTabs.Purchase));
  const [countryRegion, setCountryRegion] = useState<string>(CountryRegion.All);
  const [currency, setCurrency] = useState<string>(Currency.All);

  const [feeAdjustmentRes, setFeeAdjustmentRes] = useState<FeeAdjustmentRes[]>();
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const { t } = useTranslation('feeAdjustmentRate');
  const { showLoading, hideLoading } = useLoading();
  const isMobile = useAppSelector(selectIsMobileView);

  const getFeeAdjustmentDetails = () => {
    // Filter feeAdjustmentRes by countryRegion and currency
    return feeAdjustmentRes?.filter((item: FeeAdjustmentRes) => {
      const countryFilter = countryRegion === CountryRegion.Oversea ? '' : countryRegion;
      const currencyFilter = currency === Currency.NonHKD ? '' : currency;

      if (countryFilter === CountryRegion.All && currencyFilter === Currency.All) return true;

      if (countryFilter === CountryRegion.All) {
        return item.acquirerCurrency === currencyFilter;
      }

      if (currencyFilter === Currency.All) {
        return item.cardAcceptorCountryCode === countryFilter;
      }

      return (
        item.cardAcceptorCountryCode === countryFilter && item.acquirerCurrency === currencyFilter
      );
    });
  };

  const fetchFeeAdjustmentRate = async () => {
    if (!fields.programName || !transactionType) return;

    showLoading('getFeeAdjustmentRate');
    const res = await APIs.programAgentManagement.feeAdjustmentRate.getFeeAdjustmentRate({
      programName: fields.programName,
      transactionType,
    });
    hideLoading('getFeeAdjustmentRate');

    setIsApiCalled(true);

    if (!res) return;

    return res;
  };

  useQuery(['getFeeAdjustmentRate', fields.programName, transactionType], fetchFeeAdjustmentRate, {
    onSuccess: (res: FeeAdjustmentRes[]) => {
      setFeeAdjustmentRes(res);
    },
  });

  return (
    <div>
      <FilterCard title={t('selectProgram')}>
        <div style={{ width: isMobile ? '100%' : '30%' }}>
          <Filter title={t('programName')}>
            <AgentProgramNameSelection
              mode={'all'}
              value={fields.programName}
              onChange={(e) => setFields({ ...fields, programName: e.target.value })}
              clearSelect={() => setFields({ ...fields, programName: '' })}
            />
          </Filter>
        </div>
      </FilterCard>

      {feeAdjustmentRes && feeAdjustmentRes.length > 0 && (
        <ResultSection
          countryRegion={countryRegion}
          setCountryRegion={setCountryRegion}
          currency={currency}
          setCurrency={setCurrency}
          setTransactionType={setTransactionType}
          feeAdjustmentDetails={getFeeAdjustmentDetails()}
        />
      )}

      {/* Fetched API and No Data UI */}
      {isApiCalled && (!feeAdjustmentRes || feeAdjustmentRes.length === 0) && <NoRecords />}
    </div>
  );
};

export default FeeAdjustmentRate;
