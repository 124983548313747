import { customSx } from '../../utils/styling';
import { Box } from '../MuiGenerals';
import GeneralBtn from './GeneralBtn';

export default function ExportBtn(props: { onExport: () => Promise<void>; isShow?: boolean }) {
  const { onExport, isShow } = props;

  if (!isShow) return <></>;

  return (
    <Box sx={customSx.tableExportBar}>
      <GeneralBtn label="export" onClick={onExport} />
    </Box>
  );
}
