import React from 'react';

import { useTranslation } from '../../../../../hooks';
import { FeeAdjustmentRes } from '../../types';
import Wrapper from '../Wrapper';
import ResultFilters from './components/ResultFilters';
import FeeAdjustmentDetails from './components/FeeAdjustmentDetails';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../reducer/mediaSlice';

interface IProps {
  countryRegion: string;
  setCountryRegion: (countryRegion: string) => void;
  currency: string;
  setCurrency: (currency: string) => void;
  setTransactionType: (transactionType: number) => void;
  feeAdjustmentDetails?: FeeAdjustmentRes[];
}

const ResultSection = (props: IProps) => {
  const {
    countryRegion,
    setCountryRegion,
    currency,
    setCurrency,
    setTransactionType,
    feeAdjustmentDetails,
  } = props;

  const { t } = useTranslation('feeAdjustmentRate');
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div>
      <div
        style={{
          color: '#838DA4',
          fontSize: '16px',
          padding: '24px 0 16px',
          paddingLeft: isMobile ? '32px' : '0',
        }}
      >
        {t('result')}
      </div>

      <Wrapper style={{ padding: '24px' }}>
        <ResultFilters
          countryRegion={countryRegion}
          setCountryRegion={setCountryRegion}
          currency={currency}
          setCurrency={setCurrency}
          setTransactionType={setTransactionType}
        />

        <div style={{ marginTop: '32px' }}>
          <FeeAdjustmentDetails feeAdjustmentDetails={feeAdjustmentDetails || []} />
        </div>
      </Wrapper>
    </div>
  );
};

export default ResultSection;
