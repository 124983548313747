import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import useSystemCurrency from '../../../../hooks/useSystemCurrency';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
  isSelectingCurrency?: boolean;
  tokenNameFilter?: string;
}

const SystemCurrencySingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear, isSelectingCurrency, tokenNameFilter } = props;

  const { SystemCurrencyEnum } = useSystemCurrency({ tokenNameFilter });

  const CurrencyOnlyEnum = Object.keys(SystemCurrencyEnum).reduce(
    (acc, currency) => ({ ...acc, [currency]: currency }),
    {}
  );

  const enumData = isSelectingCurrency ? CurrencyOnlyEnum : SystemCurrencyEnum;

  const displayValue = isSelectingCurrency
    ? value
    : value && Object.keys(value).length > 0
    ? JSON.stringify(value)
    : '';

  const { tc } = useTranslation();
  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('currency') })}
      value={displayValue}
      clearSelect={onClear}
      onChange={onChange}
      enumData={enumData}
      nameFn={(item) => item}
    />
  );
};

export default SystemCurrencySingleSelection;
