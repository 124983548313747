import React from "react";

import { Box } from "../../../../components/MuiGenerals";
import { Skeleton } from "@mui/material";
import { COLOR_THEME } from "../../../../style/colorTheme";
import { IOverviewResponse } from "../../../../api/types";
import { useTranslation } from "../../../../hooks";
import NoRecordContent from "../../../../components/Dashboard/DashboardContent/NoRecordContent";
import { calculateTotalAmount } from "./helpers/calculateTotalAmount";

interface IHkdmAgentBalanceProps {
    data: IOverviewResponse["walletBalances"] | null;
    isLoading: boolean;
}

const DISPLAY_TOKEN_NAME = "HKDM";
const HkdmAgentBalance: React.FC<IHkdmAgentBalanceProps> = ({ data, isLoading }) => {
    const { t } = useTranslation("dashboard");

    const amountItems = data?.filter((item) => item.tokenName === DISPLAY_TOKEN_NAME);

    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Box>
                <span style={{
                    color: COLOR_THEME.CustomSx.Dashboard.programAgentBalance.Title.Color,
                    fontSize: "1rem",
                }}
                >
                    {t("hkdmBalanceTitle")}
                </span>
            </Box>
            <Box sx={{
                marginTop: '0.5rem',
            }}>
                {isLoading ? <Skeleton
                    variant="rounded"
                    width={"30%"}
                    height={50}
                /> : data ?
                    <span style={{
                        color: COLOR_THEME.CustomSx.Dashboard.programAgentBalance.Amount.Color,
                        fontSize: "2rem",
                    }}>
                        {`${calculateTotalAmount(amountItems)} ${t("hkdm")}`}
                    </span> :
                    <NoRecordContent />
                }
            </Box>
        </Box>
    );
};

export default HkdmAgentBalance;
