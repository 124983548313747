export enum TableColumnKey {
  TaskId = 'taskId',
  OrderId = 'orderId',
  Sequence = 'sequence',
  Month = 'month',
  ProgramName = 'programName',
  rebateType = 'rebateType',
  From = 'from',
  To = 'to',
  Currency = 'currency',
  Amount = 'amount',
  RebateCurrency = 'rebateCurrency',
  RebateExchangeRate = 'rebateExchangeRate',
  RebateAmount = 'rebateAmount',
  Status = 'status',
  CreationTime = 'creationTime',
}

export interface TableRow {
  id: number;

  [TableColumnKey.TaskId]: string | number;
  [TableColumnKey.OrderId]: string | number;
  [TableColumnKey.Sequence]: number | string;
  [TableColumnKey.Month]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.rebateType]: string;
  [TableColumnKey.From]: string;
  [TableColumnKey.To]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.Amount]: string;
  [TableColumnKey.RebateCurrency]: string;
  [TableColumnKey.RebateExchangeRate]: string;
  [TableColumnKey.RebateAmount]: string;
  [TableColumnKey.Status]: string;
  [TableColumnKey.CreationTime]: string;

  rawData: any;
}
