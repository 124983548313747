import { isNil } from 'lodash';
import { useState } from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import {
  EnumCustomerDocumentType,
  EnumIdNumberType
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import api from '../../../../../api';
import { OpDownload, OpHidden, OpShow } from '../../../../../assets/icons';
import { OpIconButton } from '../../../../../components';
import { GridBoxSections } from '../../../../../components/Layout';
import { Box } from '../../../../../components/MuiGenerals';
import { usePermission, useTranslation } from '../../../../../hooks';
import { importantStyle } from '../../../../../utils';
import { FreeObj } from '../../../../../utils/constant';
import { useZusDialogStore } from '../../../../../zustand/store';
import { detailDialogTranslateKey as TK } from '../../mappings/translate';
import { IapiGetAllRes, IdetailsApiRes, Idocument } from '../../types';

interface IDetailTabContentProp {}

export const documentTypeMapping = {
  IdFront: 'front',
  IdBack: 'back',
} as FreeObj;

const idTypeMapping = {
  SSN: 'ssn',
  SocialInsurance: 'social_insurance',
  TaxId: 'tax_id',
  IdentityCard: 'identity_card',
  DrivingLicense: 'driving_license',
  ShareCode: 'share_code',
  VoterId: 'voter_id',
  Passport: 'passport',
  Other: 'other',
} as FreeObj;

const DetailTabContent: React.FC<IDetailTabContentProp> = (props) => {
  const zusDialog = useZusDialogStore();
  const { rowData, detailsData } =
    (zusDialog.meta as
      | {
          rowData: { rawData: IapiGetAllRes };
          detailsData: IdetailsApiRes & { customer_account_info_document: Idocument[] };
        }
      | undefined) || {};
  const {
    customerNumber,
    firstName,
    lastName,
    email,
    phoneCountryCode: rawPhoneCountryCode,
    phoneNumber,
  } = rowData?.rawData || {};

  const { hasPermission } = usePermission();

  const { tc, t, tcc } = useTranslation('enumConstants');

  const initIdNumberObject = {
    value: '',
    isHidden: true,
  };
  const [idNumberObject, setIdNumberObject] = useState(initIdNumberObject);

  const downloadBtnFn =
    ({ documentType, fileName }: { documentType: EnumCustomerDocumentType; fileName: string }) =>
    async () => {
      if (!detailsData?.applicationNumber) {
        return;
      }

      zusDialog.openExtra('loadingDialog');

      const res: Blob = await api.clientManagement.clientList.getDocumentFile(
        { applicationNumber: detailsData.applicationNumber, type: documentType },
        { responseType: 'blob' }
      );
      zusDialog.closeExtra();

      if (!res) {
        return;
      }

      const element = document.createElement('a');

      const objUrl = URL.createObjectURL(res);

      document.body.appendChild(element); // Required for this to work in FireFox

      element.href = objUrl;

      element.download = fileName;

      element.click();
      return res;
    };

  const showIdNumber = (customerNumber: string) => async () => {
    if (idNumberObject.value) {
      setIdNumberObject((prev) => ({ ...prev, isHidden: !prev.isHidden }));
      return;
    }
    const res = await api.clientManagement.clientList.getIdNumber({ customerNumber });
    if (!res) {
      return;
    }
    setIdNumberObject((prev) => ({
      ...prev,
      isHidden: false,
      value: res.idNumber,
    }));
  };

  const dateTransform = (rawDate: unknown) => {
    if (typeof rawDate !== 'string') {
      return '';
    }

    if (rawDate.split('-').length === 3) {
      return rawDate;
    }

    if (rawDate.length !== 8) {
      return '';
    }
    const year = rawDate.slice(0, 4);
    const month = rawDate.slice(4, 6);
    const day = rawDate.slice(-2);

    return year + '-' + month + '-' + day;
  };

  const phoneCountryCode = rawPhoneCountryCode || '';
  const phoneNumberDisplay = phoneNumber ? phoneCountryCode + ' ' + phoneNumber : '';

  const hasViewIdNumberPermssion = hasPermission(
    AgentPortalFeatureCode.ClientManagement.ClientList.Details.ViewIdNumber
  );
  const hasDownloadDocumentPermssion = hasPermission(
    AgentPortalFeatureCode.ClientManagement.ClientList.Details.DownloadDocument
  );

  const detailSection: Array<[string, JSX.Element]> = [
    [TK.pfhNo, <Box>{customerNumber}</Box>],
    [TK.firstName, <Box>{firstName}</Box>],
    [TK.lastName, <Box>{lastName}</Box>],
    [TK.emailAddress, <Box>{email}</Box>],
    [TK.phoneNo, <Box>{phoneNumberDisplay}</Box>],
    [
      TK.identificationDocumentType,
      <Box>{isNil(detailsData?.idType) ? '' : t(EnumIdNumberType[detailsData!.idType])}</Box>,
    ],
    [
      TK.identificationNo,
      <>
        {detailsData?.idNumberHash && customerNumber && (
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: importantStyle('max-content'),
                paddingTop: idNumberObject.isHidden ? '4px' : undefined,
              }}
            >
              {idNumberObject.isHidden ? '********' : idNumberObject.value}
            </Box>

            {hasViewIdNumberPermssion && (
              <OpIconButton
                sxBox={{
                  width: importantStyle('1.4rem'),
                  marginLeft: importantStyle('12px'),
                }}
                title={''}
                svgUrl={idNumberObject.isHidden ? OpHidden : OpShow}
                onClick={showIdNumber(customerNumber)}
              />
            )}
          </Box>
        )}
      </>,
    ],
    [
      TK.uploadedDocument,
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '>div:nth-last-of-type(n + 2)': {
            marginBottom: importantStyle('4px'),
          },
        }}
      >
        {detailsData?.customer_account_info_document.map(
          ({ documentType, fileName: rawFileName }, i) => {
            const rawFileNameArr = rawFileName?.split('.');
            const fileExtension = rawFileNameArr && rawFileNameArr[rawFileNameArr.length - 1];
            const fileName =
              documentTypeMapping[EnumCustomerDocumentType[documentType]] +
              '_' +
              idTypeMapping[EnumIdNumberType[detailsData.idType]] +
              '_' +
              customerNumber +
              (fileExtension ? '.' + fileExtension : '');
            return (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 'max-content',
                }}
              >
                <Box>{fileName}</Box>
                <Box>
                  {hasDownloadDocumentPermssion && (
                    <OpIconButton
                      sxBox={{
                        width: importantStyle('1.2rem'),
                        marginLeft: importantStyle('12px'),
                      }}
                      title={''}
                      svgUrl={OpDownload}
                      onClick={downloadBtnFn({ documentType, fileName })}
                    />
                  )}
                </Box>
              </Box>
            );
          }
        )}
      </Box>,
    ],
  ];

  const answerData = detailsData?.customerApplicationAnswer.answers;

  const residentAddressSection: Array<[string, JSX.Element]> = [
    [TK.addressLine1, <Box>{answerData?.residentialAddressLine1}</Box>],
    [TK.addressLine2, <Box>{answerData?.residentialAddressLine2}</Box>],
    [TK.addressLine3, <Box>{answerData?.residentialAddressLine3}</Box>],
    [TK.postalCode, <Box>{answerData?.residentialPostalCode}</Box>],
  ];
  const deliveryAddressSection: Array<[string, JSX.Element]> = [
    [TK.addressLine1, <Box>{answerData?.deliveryAddressLine1}</Box>],
    [TK.addressLine2, <Box>{answerData?.deliveryAddressLine2}</Box>],
    [TK.addressLine3, <Box>{answerData?.deliveryAddressLine3}</Box>],
    [TK.postalCode, <Box>{answerData?.deliveryPostalCode}</Box>],
  ];
  const sectionHeaderList = [
    { headerValue: TK.details },
    { headerValue: TK.residentAddress },
    { headerValue: TK.deliveryAddress },
  ];

  const labelElePairArrList: [string, JSX.Element][][] = [
    detailSection,
    residentAddressSection,
    deliveryAddressSection,
  ];
  return (
    <GridBoxSections
      sectionHeaderList={sectionHeaderList}
      labelElePairArrList={labelElePairArrList}
    />
  );
};

export default DetailTabContent;
