import { GridRenderCellParams } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../../constants/ColumnWidth';
import { usePermission } from '../../../../../hooks';
import { useGenGridCol } from '../../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

interface IProps {
  renderOperationCell: (params: GridRenderCellParams) => JSX.Element;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationCell } = props;
  const { hasPermission } = usePermission();

  const isShowHistoryBtn = hasPermission(
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramAgentWallet.HistoryTab.prefix
  );

  const isHideOperationColumn = !isShowHistoryBtn;

  const columns = [
    useGenGridCol(TableColumnKey.Operation, {
      renderCell: renderOperationCell,
      hide: isHideOperationColumn,
    }),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Balance),
    useGenGridCol(TableColumnKey.FrozenBalance),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
