import React, { useEffect } from 'react';

// In React.StrictMode, useEffect will be called twice
// This hook will ensure that the callback is only called once

const useRunOnceEffect = (callback: () => void) => {
  const hasRunEffect = React.useRef(false);

  useEffect(() => {
    if (hasRunEffect.current) return;

    callback();
    hasRunEffect.current = true;
  }, [callback]);
};

export default useRunOnceEffect;
