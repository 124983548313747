export enum TableColumnKey {
  operation = 'operation',
  programName = 'programName',
  from = 'from',
  to = 'to',
  currentExchangeRate = 'currentExchangeRate',
  adjustmentType = 'adjustmentType',
  adjustmentAmount = 'adjustmentAmount',
  exchangeRateAfterAdjustment = 'exchangeRateAfterAdjustment',
}
