import { EXPECTED_TX_PER_MONTH } from '../constants/TcspExpectedTxPerMonth';

export const getTcspExpectedTxPerMonthDisplayValue = (
  expectedTxPerMonth: number,
  translateFunc: (key: string) => string
) => {
  const value = EXPECTED_TX_PER_MONTH[String(expectedTxPerMonth)];

  if (!value) return '';

  return translateFunc(`tcspAccountApplication.tcspExpectedTxPerMonth.${value}`);
};
