import { useState } from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumRebateTaskStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/AgentRebate';

import APIs from '../../../api';
import { ApiResult } from '../../../api/types';
import ApprovalProgressDialog from '../../../common/approval/components/ApprovalProgressDialog';
import ApprovalProgressTableCell from '../../../common/approval/components/ApprovalProgressTableCell';
import ApproveRejectCell from '../../../common/approval/components/ApproveRejectCell';
import ApproveRejectDialog from '../../../common/approval/components/ApproveRejectDialog';
import Table from '../../../common/filterTable/components/Table';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../common/page/hooks/useNewFilterTablePage';
import { ExportBtn } from '../../../components/Button';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import ApproveRejectContent from './components/ApproveRejectContent';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/Table';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.monthFrom]: '',
  [FilterFieldsKeys.monthTo]: '',
  [FilterFieldsKeys.taskId]: '',
  [FilterFieldsKeys.programName]: '',
  [FilterFieldsKeys.status]: '',
};

const translatePrefix = 'rebateDistributionTask' as IPrefix;
const getTableDataApiFunc = APIs.rebate.rebateDistributionTask.getAll;
const exportTableApiFunc = APIs.rebate.rebateDistributionTask.getExport;

const exportBtnPermission = AgentPortalFeatureCode.Rebate.RebateDistributionTask.Export;

const RebateDistributionTask = () => {
  const { getListParams } = useGetListParams();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const pageConfig = {
    initFields,
    DateObj,
    translatePrefix,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  };

  const {
    page,
    onPageChange,
    fields,
    setFields,
    list,
    onFilterSearch,
    onFilterReset,
    onExport,
    getIsHavePermission,
    Tabs,
    refreshTable,
  } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [isApprovalProgressDialogOpen, setIsApprovalProgressDialogOpen] = useState(false);
  const [isApproveRejectDialogOpen, setIsApproveRejectDialogOpen] = useState(false);

  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const columns = useTableColumns({
    renderApprovalProgressCell,
    renderOperationsCell,
  });
  const { t, tc } = useTranslation('rebateDistributionTask');
  const profile = useAppSelector(selectProfile);

  const isShowExportBtn = getIsHavePermission(exportBtnPermission);

  const handleExport = async () => {
    await onExport('Rebate Distribution Task', totalCounts, convertToTableRows);
  };

  function renderApprovalProgressCell({ row }: { row: TableRow }) {
    return (
      <ApprovalProgressTableCell
        approvalsCount={row.rawData.approvalsCount}
        approvalsRequired={row.rawData.approvalsRequired}
        applicationStatus={row.rawData.status}
        approvedStatus={row.rawData.status}
        onButtonClick={() => {
          setSelectedRow(row);
          setIsApprovalProgressDialogOpen(true);
        }}
      />
    );
  }
  function renderOperationsCell({ row }: { row: TableRow }) {
    const isDisabled =
      row.rawData.status !== EnumRebateTaskStatus.ReportReady || !row.rawData.approvalsRequired;

    const handleApproveRejectCellClick = async () => {
      setSelectedRow(row);
      setIsApproveRejectDialogOpen(true);
    };

    return (
      <>
        <ApproveRejectCell onClick={handleApproveRejectCellClick} isDisabled={isDisabled} />
      </>
    );
  }

  return (
    <>
      <FilterTableLayoutContainer>
        <ApprovalProgressDialog
          isOpen={isApprovalProgressDialogOpen}
          closeDialog={() => setIsApprovalProgressDialogOpen(false)}
          id={selectedRow?.rawData.id || ''}
          getApproveHistory={APIs.rebate.rebateDistributionTask.getApproveHistory}
        />

        <ApproveRejectDialog
          isOpen={isApproveRejectDialogOpen}
          closeDialog={() => setIsApproveRejectDialogOpen(false)}
          refreshTable={refreshTable}
          data={{
            operator: profile.email,
            createdBy: selectedRow?.rawData?.createdBy || '',
            approvalsRequired: selectedRow?.rawData?.approvalsRequired,
            approvalsCount: selectedRow?.rawData?.approvalsCount,
            id: selectedRow?.rawData?.id,
          }}
          getApproveHistory={APIs.rebate.rebateDistributionTask.getApproveHistory}
          actions={{
            approve: {
              apiFunc: APIs.rebate.rebateDistributionTask.approve,
              permission: AgentPortalFeatureCode.Rebate.RebateDistributionTask.Approve.prefix,
            },
            reject: {
              apiFunc: APIs.rebate.rebateDistributionTask.reject,
              permission: AgentPortalFeatureCode.Rebate.RebateDistributionTask.Reject,
            },
          }}
          tailorMadeAlertMessages={{
            rejectSuccessMessage: t('reject_success_alert'),
            approveSuccessMessage: t('approve_success_alert'),
            lastApproveSuccessMessage: t('approve_success_alert'),
            approvedBeforeMessage: t('same_approver_alert'),
          }}
        >
          <ApproveRejectContent selectedRow={selectedRow} />
        </ApproveRejectDialog>
        <FilterContainer>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={onFilterSearch}
            onReset={onFilterReset}
            DateObj={DateObj}
          />
        </FilterContainer>

        <DivideLine />

        <TableContainer>
          <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={totalCounts}
              page={page}
              onPageChange={onPageChange}
            />
          </Tabs>
        </TableContainer>
      </FilterTableLayoutContainer>
    </>
  );
};

export default RebateDistributionTask;
