import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameSingleSelection from '../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import MonthRangePicker from '../../../../components/MonthRangePicker';
import { generateLimitedYearDateTime } from '../../../../utils/dateUtils';
import RebateTaskStatusSingleSelection from '../../../../common/filterTable/components/Filters/RebateTaskStatusSingleSelection';
import TaskIdTextField from '../../../../common/filterTable/components/Filters/TaskIdTextField';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const filterFields: ReactNode[] = useGenFilterField([
    ['creationTime', <DateObj.CreationTime.Picker type="dateTime" />],
    ['month', <MonthRangePicker
      startMonth={fields[FilterFieldsKeys.monthFrom]}
      endMonth={fields[FilterFieldsKeys.monthTo]}
      onChangeStartMonth={(value) => updateField(FilterFieldsKeys.monthFrom, value)}
      onChangeEndMonth={(value) => updateField(FilterFieldsKeys.monthTo, value)}
      maxMonth={lastMonth}
      minMonth={generateLimitedYearDateTime(2)}
    />],

    [
      FilterFieldsKeys.taskId,
      <TaskIdTextField
        value={fields[FilterFieldsKeys.taskId]}
        onChange={(e) => updateField(FilterFieldsKeys.taskId, e.target.value)}
      />,
    ],
    [
      FilterFieldsKeys.programName,
      <ProgramNameSingleSelection
        value={fields[FilterFieldsKeys.programName]}
        onChange={(e) => updateField(FilterFieldsKeys.programName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.programName, '')}
      />,
    ],
    [
      FilterFieldsKeys.status,
      <RebateTaskStatusSingleSelection
        value={fields[FilterFieldsKeys.status]}
        onChange={(e) => updateField(FilterFieldsKeys.status, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.status, '')}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
