import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import { CreateExchangeRateFields } from '../types';

interface IProps {
  fields: CreateExchangeRateFields;
  isInfinity: boolean;
}

const useExchangeRateValidation = (props: IProps) => {
  const { fields, isInfinity } = props;

  const { translate, t } = useTranslation('exchangeRate');

  const validationConfig: ValidationConfigProps = [
    {
      key: 'currentExchangeRate',
      value: fields['currentExchangeRate'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('currentExchangeRate'),
          })
        ),
      ],
    },
    {
      key: 'nextExchangeRate',
      value: fields['nextExchangeRate'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('nextExchangeRate'),
          }),
          fields.startFrom !== null || !isInfinity
        ),
      ],
    },
    {
      key: 'startFrom',
      value: fields['startFrom'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('startFrom'),
          }),
          (!!fields.nextExchangeRate && fields.nextExchangeRate !== '') || !isInfinity
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useExchangeRateValidation;
