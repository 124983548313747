import { useLocaleState } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';

import APIs from '../../../api';
import { useAlerting, useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import useRunOnceEffect from '../../../hooks/useRunOnceEffect';
import useLoginRedirect from '../IndividualLogin/helpers/useLoginRedirect';
import { getIsRedirectLoginPath } from '../../../utils';

const APP_PORTAL_LANG_CONVERSION: { [key: string]: string } = {
  en: 'en',
  'zh-TW': 'zh_cn',
};

const RedirectLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { alerting } = useAlerting();
  const { navigateToRedirect } = useLoginRedirect();
  const { showLoading, hideLoading } = useLoading();
  const { tc } = useTranslation();
  const [, setLocale] = useLocaleState();

  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  const urlLang = params.get('lang') || 'en';

  const lang = APP_PORTAL_LANG_CONVERSION[urlLang] || 'en';

  const redirectToWebPortalLoginPage = (errorMsg?: string) => {
    if (errorMsg && errorMsg.length > 0) {
      alerting('error', errorMsg);
    }

    navigate('/login');
  };

  const appLogin = async () => {
    showLoading('appLogin');
    const res = await APIs.merchantPortal.auth.appLogin({ token });
    hideLoading('appLogin');

    if (!res) {
      return redirectToWebPortalLoginPage();
    }

    setLocale(lang);

    // Web Portal Login
    navigateToRedirect({
      accessToken: res.accessToken,
      refreshToken: res.refreshToken,
      features: res.features,
    });
  };

  useRunOnceEffect(async () => {
    if (!token) {
      redirectToWebPortalLoginPage(tc('tokenNotFound'));
      return;
    }

    if (!getIsRedirectLoginPath()) return;

    await appLogin();
  });

  return <></>;
};

export default RedirectLogin;
