import { useState } from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumCreditAllocationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import APIs from '../../../api';
import { ApiResult } from '../../../api/types';
import ApprovalProgressDialog from '../../../common/approval/components/ApprovalProgressDialog';
import ApprovalProgressTableCell from '../../../common/approval/components/ApprovalProgressTableCell';
import ApproveRejectCell from '../../../common/approval/components/ApproveRejectCell';
import ApproveRejectDialog from '../../../common/approval/components/ApproveRejectDialog';
import Table from '../../../common/filterTable/components/Table';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../common/filterTable/layout/FilterTableLayoutContainer';
import FilterTopActionRow from '../../../common/filterTable/layout/FilterTopActionRow';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../common/page/hooks/useNewFilterTablePage';
import { Currency } from '../../../common/types/Currency';
import { ExportBtn } from '../../../components/Button';
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import ApproveRejectContent from './components/ApproveRejectContent';
import CreateRequestDialog from './components/CreateRequestDialog';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/Table';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.OrderId]: '',
  [FilterFieldsKeys.DistributorAgentId]: '',
  [FilterFieldsKeys.Currency]: {} as Currency,
};

const translatePrefix = 'creditAllocation' as IPrefix;
const getTableDataApiFunc = APIs.distributorAgentManagement.creditAllocation.getAll;
const exportTableApiFunc = APIs.distributorAgentManagement.creditAllocation.getExport;

const exportBtnPermission =
  AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.Export;
const createUserBtnPermission =
  AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.CreateRequest;

const CreditAllocation = () => {
  const { getListParams } = useGetListParams();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const pageConfig = {
    initFields,
    DateObj,
    translatePrefix,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  };

  const {
    page,
    onPageChange,
    fields,
    setFields,
    list,
    onFilterSearch,
    onFilterReset,
    onExport,
    getIsHavePermission,
    Tabs,
    refreshTable,
  } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

  const [isCreateRequestDialogOpen, setIsCreateRequestDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [isApprovalProgressDialogOpen, setIsApprovalProgressDialogOpen] = useState(false);
  const [isApproveRejectDialogOpen, setIsApproveRejectDialogOpen] = useState(false);

  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const columns = useTableColumns({ renderApprovalProgressCell, renderOperationsCell });
  const { t } = useTranslation(translatePrefix);
  const profile = useAppSelector(selectProfile);

  const isShowExportBtn = getIsHavePermission(exportBtnPermission);
  const isShowCreateUserBtn = getIsHavePermission(createUserBtnPermission);

  const handleExport = async () => {
    await onExport('Credit Allocation', totalCounts, convertToTableRows);
  };

  const handleCreateUserBtnClick = () => {
    setIsCreateRequestDialogOpen(true);
  };

  function renderApprovalProgressCell({ row }: { row: TableRow }) {
    return (
      <ApprovalProgressTableCell
        approvalsCount={row.rawData.approvalsCount}
        approvalsRequired={row.rawData.approvalsRequired}
        applicationStatus={row.rawData.status}
        approvedStatus={row.rawData.status}
        onButtonClick={() => {
          setSelectedRow(row);
          setIsApprovalProgressDialogOpen(true);
        }}
      />
    );
  }
  function renderOperationsCell({ row }: { row: TableRow }) {
    const isDisabled = row.rawData.status !== EnumCreditAllocationStatus.Pending;

    const handleApproveRejectCellClick = async () => {
      setSelectedRow(row);
      setIsApproveRejectDialogOpen(true);
    };

    return (
      <>
        <ApproveRejectCell onClick={handleApproveRejectCellClick} isDisabled={isDisabled} />
      </>
    );
  }

  return (
    <>
      <FilterTableLayoutContainer>
        <CreateRequestDialog
          isOpen={isCreateRequestDialogOpen}
          closeDialog={() => setIsCreateRequestDialogOpen(false)}
          refreshTable={refreshTable}
        />

        <ApprovalProgressDialog
          isOpen={isApprovalProgressDialogOpen}
          closeDialog={() => setIsApprovalProgressDialogOpen(false)}
          id={selectedRow?.rawData.id || ''}
          getApproveHistory={APIs.distributorAgentManagement.creditAllocation.getApproveHistory}
        />

        <ApproveRejectDialog
          isOpen={isApproveRejectDialogOpen}
          closeDialog={() => setIsApproveRejectDialogOpen(false)}
          refreshTable={refreshTable}
          getApproveHistory={APIs.distributorAgentManagement.creditAllocation.getApproveHistory}
          data={{
            operator: profile.email,
            createdBy: selectedRow?.createdBy || '',
            approvalsRequired: selectedRow?.rawData?.approvalsRequired,
            approvalsCount: selectedRow?.rawData?.approvalsCount,
            id: selectedRow?.rawData?.id,
          }}
          actions={{
            approve: {
              apiFunc: APIs.distributorAgentManagement.creditAllocation.approve,
              permission:
                AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.Approve.prefix,
            },
            reject: {
              apiFunc: APIs.distributorAgentManagement.creditAllocation.reject,
              permission: AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.Reject,
            },
          }}
        >
          <ApproveRejectContent selectedRow={selectedRow} />
        </ApproveRejectDialog>
        <FilterContainer>
          <FilterTopActionRow>
            <FilterBarCreateRecordButton
              title={t('createRequest')}
              isShow={isShowCreateUserBtn}
              onClick={handleCreateUserBtnClick}
            />
          </FilterTopActionRow>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={onFilterSearch}
            onReset={onFilterReset}
            DateObj={DateObj}
          />
        </FilterContainer>

        <DivideLine />

        <TableContainer>
          <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={totalCounts}
              page={page}
              onPageChange={onPageChange}
            />
          </Tabs>
        </TableContainer>
      </FilterTableLayoutContainer>
    </>
  );
};

export default CreditAllocation;
