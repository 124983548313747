import en from '../../../components/i18nProvider/en';
import zh_cn from '../../../components/i18nProvider/zh_cn';
import { getEnumTranslationEntries } from '../../../helper/getEnumTranslationEntries';
import { Iprefix } from '../../../hooks/useTranslation';
import { enumLanguage } from '../../../utils/constant';

interface IProps {
  data: Record<string, any>[];
  omitKeys?: string[];
  translatePrefix: Iprefix;
  // translateFunc: (key: string) => string;
  selectedLang: keyof typeof enumLanguage;
}

const translateMapping = {
  en,
  zh_cn,
};

export const convertToExportRows = (props: IProps) => {
  const { data, omitKeys, translatePrefix, selectedLang } = props;

  return data.map((row: Record<string, any>) => {
    let result: Record<string, any> = {};

    Object.keys(row).forEach((key) => {
      if (omitKeys && omitKeys.length > 0 && omitKeys.includes(key)) return;

      let resultKey = '';

      let translationObj = en as any;

      const translatePrefixArr = translatePrefix.split('.');

      if (translatePrefixArr.length > 1) {
        for (const translatePrefix of translatePrefixArr) {
          translationObj = translationObj[translatePrefix];
        }
        resultKey = translationObj[key];
      } else {
        resultKey = en[translatePrefix][key as keyof (typeof en)[typeof translatePrefix]];
      }

      let resultVal;

      resultVal = row[key];

      if (!/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(row[key])) {
        const selectedLangEnumMappingsArr = getEnumTranslationEntries(
          translateMapping[selectedLang]
        );
        const englishEnumMappingsArr = getEnumTranslationEntries(en);

        for (const [selectedLangKey, selectedLangVal] of selectedLangEnumMappingsArr) {
          if (selectedLangVal === row[key]) {
            resultVal = englishEnumMappingsArr.find(([engKey]) => selectedLangKey === engKey)?.[1];
            break;
          }
        }
      }

      result[resultKey] = resultVal;

      //   // result[translateFunc(key)] = row[key];
    });

    return result;
  });
};
