import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface AgentProgramCurrency {
  programAgentId: string;
  programName: string;
  programDisplayName: string;
  programCurrency: string;
  creditTokenName: string;
  creditCurrency: string;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
}

interface InitState {
  list: AgentProgramCurrency[];
}

const initialState: InitState = { list: [] };

export const agentProgramCurrency = createSlice({
  name: 'agentProgramCurrency',
  initialState,
  reducers: {
    setAgentProgramCurrencyList: (state, action: PayloadAction<AgentProgramCurrency[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setAgentProgramCurrencyList } = agentProgramCurrency.actions;

export const selectAgentProgramCurrency = (state: RootState) => state.agentProgramCurrency;

export default agentProgramCurrency.reducer;
