import { GridRenderCellParams } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/Table';

interface IProps {
  renderOperationCell: (params: GridRenderCellParams) => JSX.Element;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationCell } = props;
  const { hasPermission } = usePermission();

  const isShowEditBtn = hasPermission(AgentPortalFeatureCode.Setting.UserSetting.Edit);

  const isHideOperationColumn = !isShowEditBtn;

  const columns = [
    useGenGridCol('operations', {
      renderCell: renderOperationCell,
      hide: isHideOperationColumn,
    }),
    useGenGridCol(TableColumnKey.DistributorAgentId, {
      minWidth: 180,
    }),
    useGenGridCol(TableColumnKey.AgentType, {
      minWidth: 120,
    }),
    useGenGridCol(TableColumnKey.LoginEmail, {
      minWidth: 150,
    }),
    useGenGridCol(TableColumnKey.Name, {
      minWidth: 150,
    }),
    useGenGridCol(TableColumnKey.Role, {
      minWidth: 200,
    }),
    useGenGridCol(TableColumnKey.Status),
    useGenGridCol(TableColumnKey.Remarks),
    useGenGridCol(TableColumnKey.CreatedBy, {
      minWidth: COLUMN_WIDTH.CreatedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedBy, {
      minWidth: COLUMN_WIDTH.LastModifiedBy.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
