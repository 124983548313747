import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

export const requiredSymbolValidator =
  (errorMessage: string) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.RequiredSymbol,
    };

    // regex to check if string have at least 1 symbol
    const regex = new RegExp(/[!@#$%^&*(),.?":{}|<>\-=~/\\[\];'_`+]/);

    const isInvalid = !regex.test(value);

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
