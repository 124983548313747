import { ReactNode, useState } from 'react';
import { useQuery } from 'react-query';

import { EnumApprovalStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne } from '../../../components';
import { Box } from '../../../components/MuiGenerals';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import useLoading from '../../../hooks/useLoading';
import { ApproveHistory } from '../types/ApproveHistory';
import ActionButtons from './ActionButtons';
import ApprovalProgressDialogContent from './ApprovalProgressDialogContent';

interface IDataProps {
  createdBy: string;
  operator: string;
  approvalsRequired: number;
  approvalsCount: number;
  approveHistory?: ApproveHistory[];
  id: string;
}

interface IActionsProps {
  [key: string]: {
    apiFunc: (params: { id: string | number; remarks: string }, config?: any) => Promise<any>;
    permission: string;
  };
}
interface IProps {
  title?: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  data: IDataProps;
  children?: ReactNode;
  actions: IActionsProps;
  getApproveHistory: ({ id }: { id: string }) => Promise<ApproveHistory[]>;
  tailorMadeAlertMessages?: {
    approveSuccessMessage?: string;
    lastApproveSuccessMessage?: string;
    rejectSuccessMessage?: string;
    approvedBeforeMessage?: string;
    creatorApprovingMessage?: string;
  };
}

const ApproveRejectDialog = (props: IProps) => {
  const {
    isOpen,
    closeDialog,
    data,
    refreshTable,
    children,
    actions,
    getApproveHistory,
    tailorMadeAlertMessages,
  } = props;
  const { createdBy, operator, approvalsRequired, approvalsCount, id } = data;

  const {
    approveSuccessMessage,
    lastApproveSuccessMessage,
    rejectSuccessMessage,
    approvedBeforeMessage,
    creatorApprovingMessage,
  } = tailorMadeAlertMessages || {};

  const [approveHistory, setApproveHistory] = useState<ApproveHistory[]>();

  const [remarksInput, setRemarksInput] = useState<string>('');

  const { tc } = useTranslation();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { showLoading, hideLoading } = useLoading();

  const getFirstApprover = () => {
    if (!approveHistory || approveHistory?.length === 0) return null;

    const sortedApprovedHistory = approveHistory
      .filter((item) => {
        return item.status === EnumApprovalStatus.Approved;
      })
      .sort((a, b) => a.seq - b.seq);

    return sortedApprovedHistory?.[0]?.approvedBy;
  };

  const isLastApprover = approvalsRequired - approvalsCount === 1;
  const isSameOperator = createdBy === operator;
  const firstApprover = getFirstApprover();
  const isSameApprover = firstApprover === operator;
  const isShowApproveHistory = (approveHistory?.length || 0) > 0;

  const hasApprovePermission = hasPermission(actions.approve.permission);

  const hasRejectPermission = hasPermission(actions.reject.permission);

  const handleCloseDialog = () => {
    closeDialog();
    setRemarksInput('');
  };

  const closeDialogAndRefresh = () => {
    handleCloseDialog();
    refreshTable();
  };

  const checkIsCanApproveProceed = () => {
    if (!hasApprovePermission) {
      alerting('error', tc('no_relevant_permission'));
      return false;
    }

    if (isSameOperator) {
      const displayCreatorApprovingMessage =
        creatorApprovingMessage || tc('no_same_operator_creator');

      alerting('error', displayCreatorApprovingMessage);
      return false;
    }

    if (isSameApprover) {
      const displayApprovedBeforeMessage = approvedBeforeMessage || tc('no_same_approver');

      alerting('error', displayApprovedBeforeMessage);
      return false;
    }

    return true;
  };

  const selectApprovalLevel = () => {
    if (!approveHistory || approveHistory?.length === 0) return null;

    const sortedPendingApprovals = approveHistory
      .filter((item) => item.status === EnumApprovalStatus.Pending)
      .sort((a, b) => {
        return a.seq - b.seq;
      });

    return sortedPendingApprovals[0];
  };

  const handleApproveClick = async () => {
    const isCanProceed = checkIsCanApproveProceed();

    if (!isCanProceed) return;

    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    if (!hasPermission(approvalLevel.approvalPermission)) {
      return alerting('error', tc('no_relevant_permission'));
    }

    const res = await actions.approve.apiFunc({
      id: approvalLevel.id,
      remarks: remarksInput,
    });

    if (!res) return;

    const displayLastApproveSuccessMessage = lastApproveSuccessMessage || tc('request_approved');
    const displayApproveSuccessMessage = approveSuccessMessage || tc('approve_successful');

    const msg = isLastApprover ? displayLastApproveSuccessMessage : displayApproveSuccessMessage;

    alerting('success', msg);
    closeDialogAndRefresh();
  };

  const handleRejectClick = async () => {
    if (!hasRejectPermission) {
      return alerting('error', tc('no_relevant_permission'));
    }

    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    const res = await actions.reject.apiFunc({
      id: approvalLevel.id,
      remarks: remarksInput,
    });

    if (!res) return;

    const displayRejectSuccessMessage = rejectSuccessMessage || tc('request_rejected');

    alerting('success', displayRejectSuccessMessage);
    closeDialogAndRefresh();
  };

  const handleRemarksChange = (e: any) => {
    setRemarksInput(e.target.value);
  };

  const fetchApproveHistory = async () => {
    if (!id) return;

    showLoading('getApproveHistory');
    const res = getApproveHistory({ id });
    hideLoading('getApproveHistory');

    if (!res) return [];

    return res;
  };

  useQuery(`approveHistory-${id}`, fetchApproveHistory, {
    onSuccess: (data) => {
      setApproveHistory(data);
    },
  });

  const dialogConfig = {
    title: props.title || tc('approveReject'),
    self: {
      open: isOpen,
      onClose: () => {
        handleCloseDialog();
      },
    },
    onConfirm: () => {},
    onCancel: () => {
      handleCloseDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: 'xl' as any,
    isFullScreen: true,
    isCancelHidden: true,
    isShowCrossButton: true,
    content: (
      <div style={{ marginBottom: '24px' }}>
        {isShowApproveHistory && (
          <Box style={{ marginTop: '62px' }}>
            <ApprovalProgressDialogContent id={id} getApproveHistory={getApproveHistory} />
          </Box>
        )}
        {children && <Box>{children}</Box>}
      </div>
    ),
    actionButtons: (
      <ActionButtons
        handleApproveClick={handleApproveClick}
        handleRejectClick={handleRejectClick}
        closeDialog={handleCloseDialog}
        remarksInput={remarksInput}
        handleRemarksChange={handleRemarksChange}
      />
    ),
  };

  return <DialogInOne {...dialogConfig}></DialogInOne>;
};

export default ApproveRejectDialog;
