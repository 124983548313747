import React from 'react';

import { Button } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  onReset: () => void;
}

const ResetButton = (props: IProps) => {
  const { onReset } = props;
  const { tc } = useTranslation();

  return (
    <Button
      onClick={onReset}
      className="resetBtn"
      variant="contained"
      sx={{
        background: '#838DA4',
        color: '#FFFFFF',
      }}
    >
      {tc('reset')}
    </Button>
  );
};

export default ResetButton;
