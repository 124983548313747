import React from 'react';

import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import AgentRoleMultipleSelection from '../../../../../common/filterTable/components/Filters/AgentRoleMultipleSelection';
import RemarksTextFields from '../../../../../common/filterTable/components/Filters/RemarksTextFields';
import StatusRadio from '../../../../../common/filterTable/components/Filters/StatusRadio';
import { useTranslation } from '../../../../../hooks';
import { EditUserFields } from './types';
import AgentRoleSingleSelection from '../../../../../common/filterTable/components/Filters/AgentRoleSingleSelection';
import AgentNameTextField from '../../../../../common/filterTable/components/Filters/AgentNameTextField';

interface IProps {
  fields: EditUserFields;
  setFields: React.Dispatch<React.SetStateAction<EditUserFields>>;
  selectedRow: any;
}

const Content = (props: IProps) => {
  const { fields, setFields, selectedRow } = props;

  const { distributorAgentId, agentType, loginEmail } = selectedRow;

  const { t } = useTranslation('userSetting');

  const updateField = (fieldName: keyof EditUserFields, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  return (
    <Container>
      <Row>
        <RowHeader>{t('agentType')}</RowHeader>
        <RowContent>{agentType}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t('distributorAgentId')}</RowHeader>
        <RowContent>{distributorAgentId}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t('loginEmail')}</RowHeader>
        <RowContent>{loginEmail}</RowContent>
      </Row>
      <Row>
        <RowHeader>{t('name')}</RowHeader>
        <RowContent>
          <AgentNameTextField
            value={fields.name}
            onChange={(e) => updateField('name', e.target.value)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('roles')}</RowHeader>
        <RowContent>
          <AgentRoleSingleSelection
            value={fields.roles}
            onChange={(e) => updateField('roles', [e.target.value])}
            onClear={() => updateField('roles', [])}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('status')}</RowHeader>
        <RowContent>
          <StatusRadio
            value={Number(fields.status)}
            onChange={(e) => updateField('status', e.target.value)}
          />
        </RowContent>
      </Row>
      <Row>
        <RowHeader>{t('remarks')}</RowHeader>
        <RowContent>
          <RemarksTextFields
            value={fields.remarks}
            onChange={(e) => updateField('remarks', e.target.value)}
          />
        </RowContent>
      </Row>
    </Container>
  );
};

export default Content;
