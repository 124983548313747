import { OpEdit } from '../../../../assets/icons';
import { OpIconButton } from '../../../../components';
import { useTranslation } from '../../../../hooks';

interface IProps {
  onClick: () => void;
  isDisabled: boolean;
  isHide?: boolean;
}

const EditConfigCell = (props: IProps) => {
  const { onClick, isDisabled, isHide } = props;
  const { tc } = useTranslation();

  if (isHide) return <></>;

  return (
    <div>
      <OpIconButton
        title={tc('edit')}
        svgUrl={OpEdit}
        onClick={onClick}
        size="1.5rem"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default EditConfigCell;
