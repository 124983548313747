import React from 'react';
import FeeSettingCard from './FeeSettingCard';
import { useTranslation } from '../../../../../../../../hooks';

interface IProps {
  localFee: string;
  overseasFee: string;
  // gbFee: string;
  localAgentMarkup: string;
  overseasAgentMarkup: string;
}

const FeeSettingRow = (props: IProps) => {
  const { localFee, overseasFee, localAgentMarkup, overseasAgentMarkup } = props;

  const { t } = useTranslation('feeAdjustmentRate');

  return (
    <div style={{ display: 'flex', background: '#404555', borderRadius: '6px' }}>
      <FeeSettingCard title={t('localFee')} value={localFee} />
      <FeeSettingCard title={t('overseasFee')} value={overseasFee} />
      <FeeSettingCard title={t('localAgentMarkup')} value={localAgentMarkup} />
      <FeeSettingCard title={t('overseaAgentMarkup')} value={overseasAgentMarkup} />
    </div>
  );
};

export default FeeSettingRow;
