import { useEffect, useState } from 'react';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

import { useAppSelector } from '../../reducer/hooks';
import { selectStuff } from '../../reducer/stuffSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { Box, Dialog, DialogContent, Typography } from '../MuiGenerals';

const sx = {
  circular: {
    '& .MuiCircularProgress-circle': { transition: 'none' },
  },
};
export function Circular(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        color={'info'}
        {...props}
        sx={sx.circular}
        size={'60px'}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          fontSize={'12px'}
          variant="caption"
          component="div"
          color={`${COLOR_THEME.Components.LoadingProgressDialog.Text}`}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LoadingProgressDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const { progress, showLoadingSpin } = useAppSelector(selectStuff);
  useEffect(() => {
    setIsOpen(showLoadingSpin);
  }, [showLoadingSpin]);
  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ padding: '20px 24px' }}>
        <Circular value={progress * 100} />
      </DialogContent>
    </Dialog>
  );
}
