export enum TableColumnKey {
  DistributorAgentId = 'distributorAgentId',
  AgentType = 'agentType',
  LoginEmail = 'loginEmail',
  Name = 'name',
  Role = 'role',
  Status = 'status',
  Remarks = 'remarks',
  CreatedBy = 'createdBy',
  CreationTime = 'creationTime',
  LastModifiedBy = 'lastModifiedBy',
  LastModifiedTime = 'lastModifiedTime',
}

export interface TableRow {
  id: number;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.AgentType]: string;
  [TableColumnKey.LoginEmail]: string;
  [TableColumnKey.Name]: string;
  [TableColumnKey.Role]: string;
  [TableColumnKey.Status]: string;
  [TableColumnKey.Remarks]: string;
  [TableColumnKey.CreatedBy]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedBy]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
