import { EnumMerchantExchangeRateAdjustType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { roundDown, safeAdd, safeMultiply } from '../../../../utils/math';

interface IProps {
  exchangeRateOfCurrentDate: string;
  type: number;
  adjustAmount: string;
}

export const getAdjustedExchangeRate = (props: IProps) => {
  const { exchangeRateOfCurrentDate, type, adjustAmount } = props;

  const originalExchangeRate = Number(exchangeRateOfCurrentDate);

  if (!adjustAmount) return '-';

  let result;

  if (type === EnumMerchantExchangeRateAdjustType.AbsoluteValue) {
    result = safeAdd(originalExchangeRate, adjustAmount);
  }

  if (type === EnumMerchantExchangeRateAdjustType.OverwriteValue) {
    result = Number(adjustAmount);
  }

  if (type === EnumMerchantExchangeRateAdjustType.Percentage) {
    // adjustAmount will be a multiplier (0.1, 1.5, etc)
    result = safeMultiply(originalExchangeRate, adjustAmount);
  }

  if (!result || isNaN(Number(result))) return '-';

  return roundDown(result, 6);
};
