import { COLOR_THEME } from '../../../../style/colorTheme';

const Header = ({ title }: { title: string }) => {
  return (
    <div style={{ color: COLOR_THEME.Page.Text, margin: '32px 0 16px 0', fontSize: '12px' }}>
      {title}
    </div>
  );
};

export default Header;
