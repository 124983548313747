// import assetsReducer from "./assetsSlice";
// import chainsReducer from "./chainsSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import agentRolesReducer from './agentRolesSlice';
import alerterReducer from './alerterSlice';
import loadingReducer from './loadingSlice';
import profileReducer from './profileSlice';
import { programReducer } from './programSlice';
import stuffReducer from './stuffSlice';
import systemCurrencyReducer from './systemCurrencySlice';
import agentCurrencyReducer from './agentCurrencySlice';
import agentProgramCurrencyReducer from './agentProgramCurrencySlice';
import mediaReducer from './mediaSlice';

const STORE_VERSION = Number(process.env.REACT_APP_STORE_VERSION || '0');

export const PERSIST_STORE_KEY = 'root';

const persistConfig = {
  key: PERSIST_STORE_KEY,
  version: STORE_VERSION,
  storage, //save in localStorage
  whitelist: ['profile' /* "assets", "chains" */], // profile and visitor will be persisted
};

const reducers = combineReducers({
  alerter: alerterReducer,
  profile: profileReducer,
  stuff: stuffReducer,
  loading: loadingReducer,
  agentsRoles: agentRolesReducer,
  program: programReducer,
  systemCurrency: systemCurrencyReducer,
  agentCurrency: agentCurrencyReducer,
  agentProgramCurrency: agentProgramCurrencyReducer,
  media: mediaReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
