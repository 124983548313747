import { Route, Routes } from 'react-router-dom';

import App from './App';
import { Alerter } from './components';
import { useReduxSync } from './hooks';
import LoadingModal from './components/LoadingModal';

const LoginRouter = () => {
  useReduxSync();

  return (
    <>
      <Alerter />
      <LoadingModal />
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </>
  );
};

export default LoginRouter;
