import './index.css';

import React, { useEffect, useState } from 'react';

import EmailVerification from './Pages/EmailVerification';
import EnterEmail from './Pages/EnterEmail';
import EnterPassword from './Pages/EnterPassword';
import ResetPassword from './Pages/ResetPassword';
import { LoginFields, LoginFieldsKeys, LoginPageShareContext, PageKeys } from './types';
import EnterPasswordWithVerify from './Pages/EnterPasswordWithVerify';
import ForgotPassword from './Pages/EnterForgotPasswordEmail';

const initFields: LoginFields = {
  email: '',
  password: '',
  emailVerificationCode: '',
  newPassword: '',
  confirmNewPassword: '',
  programAgentId: '',
};

const initPageShareContext = {
  isDeviceRegistered: false,
};

interface LoginContextProps {
  fields: LoginFields;
  setFields: React.Dispatch<React.SetStateAction<LoginFields>>;
  pageShareContext: LoginPageShareContext;
  setPageShareContext: React.Dispatch<React.SetStateAction<LoginPageShareContext>>;
  page: PageKeys;
  setPage: React.Dispatch<React.SetStateAction<PageKeys>>;
  reset: () => void;
}

export const LoginContext = React.createContext<LoginContextProps>({
  fields: initFields,
  setFields: () => {},
  pageShareContext: initPageShareContext,
  setPageShareContext: () => {},
  page: PageKeys.EnterEmail,
  setPage: () => {},
  reset: () => {},
});

const IndividualLogin = () => {
  const [fields, setFields] = useState<LoginFields>(initFields);
  const [pageShareContext, setPageShareContext] =
    useState<LoginPageShareContext>(initPageShareContext);
  const [page, setPage] = useState(PageKeys.EnterEmail);

  console.log('[DEBUG] IndividualLogin', {
    fields,
  });

  // reset fields and page
  const reset = () => {
    setFields(initFields);
    setPage(PageKeys.EnterEmail);
  };

  // reset email verification code when page changes
  useEffect(() => {
    setFields({ ...fields, [LoginFieldsKeys.EmailVerificationCode]: '' });
  }, [page]);

  const LoginContextValue = {
    fields,
    setFields,
    pageShareContext,
    setPageShareContext,
    page,
    setPage,
    reset,
  };

  const PageMap = {
    [PageKeys.EnterEmail]: <EnterEmail />,
    [PageKeys.EmailVerification]: <EmailVerification />,
    [PageKeys.ResetPassword]: <ResetPassword />,
    [PageKeys.EnterPassword]: <EnterPassword />,
    [PageKeys.EnterPasswordWithVerify]: <EnterPasswordWithVerify />,
    [PageKeys.ForgotPassword]: <ForgotPassword />,
  };

  return <LoginContext.Provider value={LoginContextValue}>{PageMap[page]}</LoginContext.Provider>;
};

export default IndividualLogin;
