import React, { useContext, useEffect, useState } from 'react';

import { LoginContext } from '../';
import APIs from '../../../../api';
import passwordIconSrc from '../../../../assets/icons/login-page-password-icon.png';
import { Container, Row, RowContent } from '../../../../common/dialogs/layout/contentLayout';
import { useAlerting, useTranslation } from '../../../../hooks';
import useLoading from '../../../../hooks/useLoading';
import LoginInput from '../components/LoginInput';
import PageWrapper from '../components/PageWrapper';
import getDeviceId from '../helpers/getDeviceId';
import useResetPasswordValidation from '../helpers/useResetPasswordValidation';
import { LoginFieldsKeys, LoginPageShareContextKeys } from '../types';
import getDeviceName from '../helpers/getDeviceName';
import TrustDeviceCheckbox from '../components/TrustDeviceCheckbox';
import { ErrorInfo } from '../../../../hooks/useValidation/types';

const ResetPassword = () => {
  const loginContext = useContext(LoginContext);

  const { fields, setFields, reset, pageShareContext } = loginContext;

  const [isTrustDevice, setIsTrustDevice] = useState(false);

  const { t } = useTranslation('login');
  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();
  const { validate } = useResetPasswordValidation({ fields });

  const onSubmit = async () => {
    let registerDeviceRes;
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    showLoading('resetPassword');
    const resetPasswordRes = await APIs.merchantPortal.auth.resetPasswordV2({
      password: fields[LoginFieldsKeys.NewPassword],
    });
    hideLoading('resetPassword');

    if (!resetPasswordRes) return;

    if (pageShareContext[LoginPageShareContextKeys.IsDeviceRegistered] === false && isTrustDevice) {
      showLoading('register-device');
      registerDeviceRes = await APIs.merchantPortal.auth.registerDevice({
        deviceId: getDeviceId(),
        deviceName: getDeviceName(),
        token: resetPasswordRes.token,
      });
      hideLoading('register-device');
    }

    if (isTrustDevice && !registerDeviceRes) return;

    alerting('success', t('resetPassword.successResetAlert'));
    reset();
  };

  const isFulfilPasswordLengthCheck = () => {
    const validationResult = validate();
    if (validationResult.validationResult.newPassword.errorInfo.some((error: ErrorInfo) => error.validatorType === 'lengthLimit')) {
      return false;
    } else {
      return true
    }
  }

  const isFulfilPasswordNumberCheck = () => {
    const validationResult = validate();
    if (validationResult.validationResult.newPassword.errorInfo.some((error: ErrorInfo) => error.validatorType === "requiredNumberAmount")) {
      return false;
    } else {
      return true
    }
  }

  const isFulfilPasswordSymbolCheck = () => {
    const validationResult = validate();
    if (validationResult.validationResult.newPassword.errorInfo.some((error: ErrorInfo) => error.validatorType === 'requiredSymbol')) {
      return false;
    } else {
      return true
    }
  }

  const isFulfilUpperCaseCheck = () => {
    const validationResult = validate();
    if (validationResult.validationResult.newPassword.errorInfo.some((error: ErrorInfo) => error.validatorType === "requiredUpperCaseLetter")) {
      return false;
    } else {
      return true
    }
  }


  const [isValidPasswordLength, setIsValidPasswordLength] = useState(false);
  const [isValidPasswordNumber, setIsValidPasswordNumber] = useState(false);
  const [isValidPasswordSymbol, setIsValidPasswordSymbol] = useState(false);
  const [isValidUpperCase, setIsValidUpperCase] = useState(false);

  useEffect(() => {
    setIsValidPasswordLength(isFulfilPasswordLengthCheck());
    setIsValidPasswordNumber(isFulfilPasswordNumberCheck());
    setIsValidPasswordSymbol(isFulfilPasswordSymbolCheck());
    setIsValidUpperCase(isFulfilUpperCaseCheck());
  }
    , [fields[LoginFieldsKeys.NewPassword]]);

  return (
    <PageWrapper onSubmit={onSubmit}>
      <div className="login-field">
        <LoginInput
          label={t('resetPassword.password')}
          value={fields[LoginFieldsKeys.NewPassword]}
          onChange={(e) =>
            setFields((fields) => ({ ...fields, [LoginFieldsKeys.NewPassword]: e.target.value }))
          }
          iconSrc={passwordIconSrc}
          type={'password'}
        />
      </div>
      <div className="login-field">
        <LoginInput
          label={t('resetPassword.confirmPassword')}
          value={fields[LoginFieldsKeys.ConfirmNewPassword]}
          onChange={(e) =>
            setFields((fields) => ({
              ...fields,
              [LoginFieldsKeys.ConfirmNewPassword]: e.target.value,
            }))
          }
          iconSrc={passwordIconSrc}
          type={'password'}
        />
      </div>
      <div className="login-field">
        {
          pageShareContext[LoginPageShareContextKeys.IsDeviceRegistered] === false
          &&
          <TrustDeviceCheckbox
            value={isTrustDevice}
            onChange={(e) => setIsTrustDevice(e.target.checked)}
          />
        }
      </div>

      <Container sx={{ width: '100%', color: '#838DA4', paddingLeft: '24px', fontSize: '12px' }}>
        <Row>
          <RowContent>
            <span style={{ color: isValidPasswordLength ? 'green' : '#838DA4' }}>{t('resetPassword.characterLimit')}</span>
          </RowContent>
        </Row>
        <Row>
          <RowContent>
            <span style={{ color: isValidUpperCase ? 'green' : '#838DA4' }}>{t('resetPassword.requiredUpperCase')}</span>
          </RowContent>
        </Row>
        <Row>
          <RowContent>
            <span style={{ color: isValidPasswordSymbol ? 'green' : '#838DA4' }}>{t('resetPassword.requiredSymbol')}</span>
          </RowContent>
        </Row>
        <Row>
          <RowContent>
            <span style={{ color: isValidPasswordNumber ? 'green' : '#838DA4' }}>{t('resetPassword.requiredNumber')}</span>
          </RowContent>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default ResetPassword;
