import { useTranslation } from '../../../../../../../hooks';
import { displayAmountCurrying } from '../../../../../../../utils';
import { FeeAdjustmentRes, FeeSetting, FeeSettings } from '../../../../types';
import NoRecords from '../../../NoRecords';
import FeeInfo from '../FeeInfo';

const FeeAdjustmentDetails = (props: { feeAdjustmentDetails: FeeAdjustmentRes[] }) => {
  const { feeAdjustmentDetails } = props;

  const { t } = useTranslation('feeAdjustmentRate');
  const toDisplayAmount = displayAmountCurrying(0);

  const negateNumber = (value: string | number) => {
    return Number(value) * -1;
  };

  const formatFeeSettingFixValue = (value: string | number) => {
    return toDisplayAmount(negateNumber(value));
  };

  const normalizeAcquirerCurrency = (acquirerCurrency: string) => {
    if (!acquirerCurrency) return t('all');

    return acquirerCurrency;
  };

  const normalizeCardAcceptorCountryCode = (cardAcceptorCountryCode: string) => {
    if (cardAcceptorCountryCode === 'HK') return t('hk');

    if (cardAcceptorCountryCode === 'GB') return t('gb');

    return t('oversea');
  };

  const normalizeFeeSettings = (feeSettings: FeeSettings | null) => {
    const defaultValue = '0.00%';

    const normalizeFeeSetting = (feeSetting: FeeSetting) => {
      if (!feeSetting) return defaultValue;

      const { fix, percent } = feeSetting;

      if (fix && percent) return `${percent}% + ${t('hkd')} ${formatFeeSettingFixValue(fix)}`;

      if (fix) return `${t('hkd')} ${formatFeeSettingFixValue(fix)}`;

      if (percent) return `${percent}%`;

      return defaultValue;
    };

    const getOverseaFee = (gbFee: FeeSetting, overseaFee: FeeSetting) => {
      if (!gbFee && !overseaFee) return defaultValue;

      const { fix: gbFix, percent: gbPercent } = gbFee || {};
      const { fix: overseaFix, percent: overseaPercent } = overseaFee || {};

      const result = [];

      if (overseaPercent) {
        result.push(`${overseaPercent}%`);
      }

      if (overseaFix) {
        result.push(`${t('hkd')} ${formatFeeSettingFixValue(overseaFix)}`);
      }

      if (gbPercent) {
        result.push(`${gbPercent}%`);
      }

      if (gbFix) {
        result.push(`${t('hkd')} ${formatFeeSettingFixValue(gbFix)}`);
      }

      return result.join(' + ');
    };

    if (!feeSettings) {
      return {
        localAgentMarkup: defaultValue,
        overseaAgentMarkup: defaultValue,
        localFee: defaultValue,
        overseasFee: defaultValue,
      };
    }

    const { localAgentMarkup, overseaAgentMarkup, local, oversea, gb } = feeSettings;

    return {
      localAgentMarkup: normalizeFeeSetting(localAgentMarkup),
      overseaAgentMarkup: normalizeFeeSetting(overseaAgentMarkup),
      localFee: normalizeFeeSetting(local),
      overseasFee: getOverseaFee(gb, oversea),
    };
  };

  const normalizeFeeAdjustmentDetail = (feeAdjustmentDetail: FeeAdjustmentRes) => {
    const { acquirerCurrency, cardAcceptorCountryCode, feeSettings } = feeAdjustmentDetail;

    const normalizedAcquirerCurrency = normalizeAcquirerCurrency(acquirerCurrency);

    const normalizedCardAcceptorCountryCode =
      normalizeCardAcceptorCountryCode(cardAcceptorCountryCode);

    const normalizedFeeSettings = normalizeFeeSettings(feeSettings);

    return {
      acceptorCountry: normalizedCardAcceptorCountryCode,
      acquirerCurrency: normalizedAcquirerCurrency,
      ...normalizedFeeSettings,
    };
  };

  if (!feeAdjustmentDetails || feeAdjustmentDetails.length === 0) {
    return <NoRecords />;
  }

  return (
    <>
      {feeAdjustmentDetails?.map((item) => {
        const normalizedFeeAdjustmentDetail = normalizeFeeAdjustmentDetail(item);

        const {
          acceptorCountry,
          acquirerCurrency,
          localFee,
          overseasFee,
          localAgentMarkup,
          overseaAgentMarkup,
        } = normalizedFeeAdjustmentDetail;

        return (
          <FeeInfo
            key={`${item.cardAcceptorCountryCode}-${item.acquirerCurrency}`}
            acceptorCountry={acceptorCountry}
            acquirerCurrency={acquirerCurrency}
            localFee={localFee}
            overseasFee={overseasFee}
            localAgentMarkup={localAgentMarkup}
            overseasAgentMarkup={overseaAgentMarkup}
          />
        );
      })}
    </>
  );
};

export default FeeAdjustmentDetails;
