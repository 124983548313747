import { useEffect } from 'react';

import { setRedirectTo } from '../reducer/alerterSlice';
import { useAppDispatch } from '../reducer/hooks';
import { decodeJwt } from '../utils';
import useAlerting from './useAlerting';
import useTranslation from './useTranslation';

const translatePrefix = 'common';

export default function useAutoLogout() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const autoLogoutReason = t('autoLogoutAlert');
  const logoutReason = t('logoutAlert');

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      const isAccessTokenChanged = e.key === 'accessToken';

      if (isAccessTokenChanged) {
        let oldTokenInfo;
        let newTokenInfo;

        if (e.oldValue) {
          oldTokenInfo = decodeJwt(e.oldValue);
        }

        if (e.newValue) {
          newTokenInfo = decodeJwt(e.newValue);
        }

        const { email: oldEmail, appUuid: oldAppUuid } = oldTokenInfo || {};
        const { email: newEmail, appUuid: newAppUuid } = newTokenInfo || {};

        const isAccessTokenRemoved = e.oldValue && (!e.newValue || e.newValue === '');

        const isNewLogin = Boolean(!e.oldValue && e.newValue);

        const isDiffUser = !isAccessTokenRemoved && !isNewLogin && oldEmail !== newEmail;

        const isDiffMerchant = !Boolean(!oldAppUuid && newAppUuid) && oldAppUuid !== newAppUuid;

        const getAlertReason = () => {
          if (isDiffUser) {
            return autoLogoutReason;
          }

          return logoutReason;
        };

        if (isDiffMerchant || isDiffUser || isAccessTokenRemoved) {
          const reason = getAlertReason();

          alerting('error', reason, {
            autoHideDuration: null,
          });

          dispatch(setRedirectTo('/login'));
        }
      }
    });
  }, []);
}
