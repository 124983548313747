const commonTranslateKey = {
  distributorName: 'distributor_name',
  amlDetails: 'aml_details',
  uploadedDocuments: 'uploaded_documents',
};

const filterTableTranslateKey = {
  distributorAgentId: 'distributor_agent_id',
  programName: 'program_name',
  applicationStatus: 'application_status',
  referralCode: 'referral_code',
  emailAddress: 'email_address',
  phoneCountryCode: 'phone_country_code',
  phoneNumber: 'phone_number',
  pfhNumber: 'pfh_number',
  clientName: 'client_name',
  identificationDocumentType: 'identification_document_type',
  identificationNumber: 'identification_number',
  dateOfBirth: 'date_of_birth',
  pmpCustomerId: 'pmp_customer_id',
  applicationStep: 'application_step',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  createdBy: 'created_by',
  lastModifiedBy: 'last_modified_by',
  ...commonTranslateKey,
};

const uploadedDocumentsDialogTranslateKey = {
  uploadTime: 'upload_time',
  ...commonTranslateKey,
};

export { filterTableTranslateKey, uploadedDocumentsDialogTranslateKey };
