import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../common/filterTable/layout/FilterGrid';
import AgentProgramNameSelection from '../../../../components/AgentProgramNameSelection';
import { useGenFilterField } from '../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ExchangeRateConfigAdjustmentTypeMultipleSelection from './InputField/ExchangeRateConfigAdjustmentTypeMultipleSelection';
import ExchangeRateFromCurrencySingleSelection from './InputField/ExchangeRateFromCurrencySingleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filterFields: ReactNode[] = useGenFilterField([
    [
      FilterFieldsKeys.programName,
      <AgentProgramNameSelection
        mode={'all'}
        value={fields[FilterFieldsKeys.programName]}
        clearSelect={() => {
          setFields((fields) => ({ ...fields, [FilterFieldsKeys.programName]: '' }));
        }}
        onChange={(e) => {
          setFields((fields) => ({
            ...fields,
            [FilterFieldsKeys.programName]: e.target.value,
          }));
        }}
      />,
    ],
    [
      FilterFieldsKeys.from,
      <ExchangeRateFromCurrencySingleSelection
        value={fields[FilterFieldsKeys.from]}
        onClear={() => {
          setFields((fields) => ({ ...fields, [FilterFieldsKeys.from]: undefined }));
        }}
        onChange={(e) => {
          setFields((fields) => ({
            ...fields,
            [FilterFieldsKeys.from]: JSON.parse(e.target.value),
          }));
        }}
      />,
    ],
    [
      FilterFieldsKeys.adjustmentType,
      <ExchangeRateConfigAdjustmentTypeMultipleSelection
        value={fields[FilterFieldsKeys.adjustmentType]}
        onClear={() => {
          setFields((fields) => ({ ...fields, [FilterFieldsKeys.adjustmentType]: [] }));
        }}
        onChange={(arr) => {
          updateField(FilterFieldsKeys.adjustmentType, arr);
        }}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
