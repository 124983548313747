import React from 'react';
import { useAppSelector } from '../../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../../reducer/mediaSlice';

const Header = ({ title, value }: { title: string; value: string }) => {
  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div style={{ display: ' flex', alignItems: 'center', fontSize: isMobile ? '13px' : '16px' }}>
      <div style={{ color: '#ADB7C3' }}>{`${title}: `}</div>
      <div style={{ color: 'white', padding: '4px' }}>{value}</div>
    </div>
  );
};

export default Header;
