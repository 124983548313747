export enum FilterFieldsKeys {
  monthFrom = 'monthFrom',
  monthTo = 'monthTo',
  distributorAgentId = 'distributorAgentId',
  programName = 'programName',
  rebateMode = 'rebateMode',
}

export interface FilterFields {
  [FilterFieldsKeys.monthFrom]: string;
  [FilterFieldsKeys.monthTo]: string;
  [FilterFieldsKeys.distributorAgentId]: string;
  [FilterFieldsKeys.programName]: string;
  [FilterFieldsKeys.rebateMode]: string;
}
