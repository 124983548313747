import React, { useEffect, useState } from 'react';
import { Admin, CustomRoutes, localStorageStore, Resource } from 'react-admin';
import { Navigate, Route } from 'react-router-dom';

import { ensureAccessToken } from './api/axiosInstance';
import i18nProvider from './components/i18nProvider';
import { LayoutBar } from './components/Layout';
import { Box } from './components/MuiGenerals';
import { useRoutePermission } from './hooks';
import { useAppSelector } from './reducer/hooks';
import { selectProfile } from './reducer/profileSlice';
import { COLOR_THEME } from './style/colorTheme';
import { queryClient } from './utils/config';
import { versionNumber } from './utils/constant';
import { theme } from './utils/theme';
import { Redirect } from './views/Auth';
import IndividualLogin from './views/Auth/IndividualLogin';
import NoPermissionElement from './views/Auth/NoPermissionElement';
import ClientApplication from './views/ClientManagement/ClientApplication';
import ClientList from './views/ClientManagement/ClientList';
import Dashboard from './views/Dashboard';
import CreditAllocation from './views/DistributorAgentManagement/CreditAllocation';
import DistributorAgentWallet from './views/DistributorAgentManagement/DistributorAgentWallet';
import CreditSwapRecord from './views/ProgramAgentManagement/CreditSwapRecord';
import CustodyWallet from './views/ProgramAgentManagement/CustodyWallet';
import ProgramAgentDeposit from './views/ProgramAgentManagement/ProgramAgentDeposit';
import ProgramAgentWallet from './views/ProgramAgentManagement/ProgramAgentWallet';
import RebateRecord from './views/Rebate/RebateRecord';
import ClientReport from './views/Report/ClientReport';
import ClientReportDetail from './views/Report/ClientReportDetail';
import CreditAllocationRecord from './views/Report/CreditAllocationRecord';
import DistributorAgentReport from './views/Report/DistributorAgentReport';
import DistributorAgentReportDetail from './views/Report/DistributorAgentReportDetail';
import MonthlyRebateReport from './views/Report/MonthlyRebateReport';
import ProgramAgentReport from './views/Report/ProgramAgentReport';
import ProgramAgentReportDetail from './views/Report/ProgramAgentReportDetail';
import DistributorAgentList from './views/Setting/DistributorAgentList';
import DistributorAgentProgram from './views/Setting/DistributorAgentProgram';
import ExchangeRate from './views/Setting/ExchangeRate';
import ExchangeRateConfiguration from './views/Setting/ExchangeRateConfiguration';
import UserSetting from './views/Setting/UserSetting';
import AccountSetting from './views/User/AccountSetting';
import ChangePasswordPage from './views/User/ChangePasswordPage';
import RedirectLogin from './views/Auth/RediectLogin';
import RebateDistributionTask from './views/Rebate/RebateDistributionTask';
import CustomerSpendingReport from './views/Report/CustomerSpendingReport';
import FeeAdjustmentRate from './views/ProgramAgentManagement/FeeAdjustmentRate';
import ProgramList from './views/ProgramAgentManagement/ProgramList';
import ProgramAgentRebateWallet from './views/ProgramAgentManagement/ProgramAgentRebateWallet';

//###add-new-page
const rawList = {
  accountSetting: <AccountSetting />,
  changePassword: <ChangePasswordPage />,

  //  // Program Agent Management
  programList: <ProgramList />,
  programAgentDeposit: <ProgramAgentDeposit />,
  programAgentWallet: <ProgramAgentWallet />,
  programAgentRebateWallet: <ProgramAgentRebateWallet />,
  custodyWallet: <CustodyWallet />,
  creditAllocation: <CreditAllocation />,
  exchangeRate: <ExchangeRate />,
  exchangeRateConfiguration: <ExchangeRateConfiguration />,
  userSetting: <UserSetting />,
  feeAdjustmentRate: <FeeAdjustmentRate />,

  //  // Distributor Agent Management
  distributorAgentList: <DistributorAgentList />,
  distributorAgentWallet: <DistributorAgentWallet />,
  distributorAgentProgram: <DistributorAgentProgram />,
  creditSwapRecord: <CreditSwapRecord />,
  creditAllocationRecord: <CreditAllocationRecord />,

  //   // Client Management
  clientApplication: <ClientApplication />,
  clientList: <ClientList />,

  //   // Report
  programAgentReportDetail: <ProgramAgentReportDetail />,
  distributorAgentReportDetail: <DistributorAgentReportDetail />,
  clientReportDetail: <ClientReportDetail />,
  distributorAgentReport: <DistributorAgentReport />,
  programAgentReport: <ProgramAgentReport />,
  clientReport: <ClientReport />,

  monthlyRebateReport: <MonthlyRebateReport />,
  customerSpendingReport: <CustomerSpendingReport />,

  // Might be removed
  //   transferRecord: <TransferRecord />,
  //   cardTransaction: <CardTransaction />,

  // Rebate
  rebateDistributionTask: <RebateDistributionTask />,
  rebateRecord: <RebateRecord />,
};

const App = () => {
  useEffect(() => console.log('versionNumber', versionNumber), []);

  const { hasRoutePermission } = useRoutePermission();
  const { timezone } = useAppSelector(selectProfile);

  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;

  const list: { [key: string]: any } =
    Object.entries(rawList).map(([key, comp]) => {
      const hasPermission = hasRoutePermission(key);
      // const hasPermission = true;

      const element = hasPermission ? comp : <NoPermissionElement />;

      return [key, element];
    }) || {};

  const isTokenReady = useToken();

  if (isLogin && !isTokenReady) return <></>;

  return (
    <Box
      sx={{
        main: {
          backgroundColor: COLOR_THEME.Page.Background,
          color: COLOR_THEME.Page.Text,
        },
      }}
    >
      <Admin
        theme={theme}
        store={localStorageStore('0.1')}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        queryClient={queryClient}
        layout={LayoutBar}
        loginPage={<IndividualLogin />}
      >
        <Resource name="notExist" />
        <CustomRoutes>
          <Route path="redirect" element={<Redirect />} />
          <Route path="redirectLogin" element={<RedirectLogin />} />

          {!isLogin && <Route path="*" element={<Navigate replace to="/login" />} />}
          {isLogin &&
            isTokenReady &&
            list.map(([path, element]: [string, JSX.Element], i: number) => (
              <Route key={i} path={path} element={React.cloneElement(element, { timezone })} />
            ))}
        </CustomRoutes>
      </Admin>
    </Box>
  );
};
function useToken() {
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      return;
    }

    async function fetch() {
      const isTokenValid = await ensureAccessToken();
      if (isTokenValid) {
        setIsReady(true);
      }
    }
    fetch();
  }, [isLogin]);

  return isReady;
}
export default App;
