import { ReactNode } from 'react';

const Filter = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        style={{ marginRight: '12px', marginTop: '16px', whiteSpace: 'nowrap', fontSize: '12px' }}
      >
        {title}
      </div>
      <div style={{ width: '100%' }}>{children}</div>
    </div>
  );
};

export default Filter;
