const commonTranslateKey = {
  details: 'details',
};

const detailDialogTranslateKey = {
  availableCredit: 'available_credit',
  maxCreditLimit: 'max_credit_limit',
  overallCreditLimit: 'overall_credit_limit',
  totalBalance: 'total_balance',
  merchantName: 'merchant_name',
  pfhNo: 'pfh_no',
  firstName: 'first_name',
  lastName: 'last_name',
  emailAddress: 'email_address',
  phoneNo: 'phone_no',
  identificationDocumentType: 'identification_document_type',

  identificationNo: 'identification_no',
  uploadedDocument: 'uploaded_document',
  addressLine1: 'address_line_1',
  addressLine2: 'address_line_2',
  addressLine3: 'address_line_3',
  postalCode: 'postal_code',
  residentAddress: 'resident_address',
  deliveryAddress: 'delivery_address',
  ...commonTranslateKey,
};

const filterTableTranslateKey = {
  distributorAgentId: 'distributor_agent_id',
  programName: 'program_name',
  status: 'status',
  emailAddress: 'email_address',
  phoneCountryCode: 'phone_country_code',
  phoneNumber: 'phone_number',
  pfhNumber: 'pfh_number',
  clientName: 'client_name',
  pmpCustomerId: 'pmp_customer_id',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  createdBy: 'created_by',
  lastModifiedBy: 'last_modified_by',
  ...commonTranslateKey,
};

export { filterTableTranslateKey, detailDialogTranslateKey };
