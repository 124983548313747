import { EnumDocumentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';
import {
  EnumAgentCustomerApplicationStatus,
  EnumAgentCustomerApplicationStep,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import { IdateFilter, Ipage } from '../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import { toDisplayTime } from '../../../../utils';
import { initZusParams } from '../../../../utils/config';
import { createZusInstance } from '../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends IdateFilter {
  distributorAgentId: string;
  status: string;
  programName: string;
  referralCode: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
}

type IapiParams = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<IapiParams>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const displayPhoneNumber =
        item.phoneCountryCode && item.phoneNumber
          ? item.phoneCountryCode + ' ' + item.phoneNumber
          : '';
      const displayClientName =
        item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : '';
      const mapping: Array<[string, IapiGetAllRes | string | number | null]> = [
        ['rawData', item],
        [TK.distributorAgentId, item.distributorAgentId],
        [TK.applicationStatus, t(EnumAgentCustomerApplicationStatus[item.status])],
        [TK.programName, item.programName],
        [TK.referralCode, item.referralCode],
        [TK.emailAddress, item.email],
        [TK.phoneNumber, displayPhoneNumber],
        [TK.pfhNumber, item.customerNumber],
        [TK.clientName, displayClientName],
        [TK.identificationDocumentType, t(EnumDocumentType[item.idType])],
        [TK.dateOfBirth, item.dateOfBirth],
        [TK.pmpCustomerId, item.customerId],
        [TK.applicationStep, t(EnumAgentCustomerApplicationStep[item.step])],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedBy, item.lastModifiedBy],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
