import clientReport from './clientReport';
import clientReportDetail from './clientReportDetail';
import creditAllocationRecord from './creditAllocationRecord';
import customerSpendingReport from './customerSpendingReport';
import distributorAgentReport from './distributorAgentReport';
import distributorAgentReportDetail from './distributorAgentReportDetail';
import monthlyRebateReport from './monthlyRebateReport';
import programAgentReport from './programAgentReport';
import programAgentReportDetail from './programAgentReportDetail';

export default {
  clientReportDetail,
  clientReport,
  distributorAgentReportDetail,
  programAgentReport,
  programAgentReportDetail,
  creditAllocationRecord,
  monthlyRebateReport,
  distributorAgentReport,
  customerSpendingReport,
};
