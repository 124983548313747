import { useEffect } from 'react';

import { Iprefix as IPrefix } from '../../../../../hooks/useTranslation';
import { useZusTranslatePrefixStore } from '../../../../../zustand/store';

const useUpdateTranslatePrefix = (translatePrefix: IPrefix) => {
  const { setTranslatePrefix } = useZusTranslatePrefixStore();

  useEffect(() => setTranslatePrefix(translatePrefix), [setTranslatePrefix, translatePrefix]);
};

export default useUpdateTranslatePrefix;
