import { TOTAL_VOLUME_OF_ASSET_MAP } from '../constants/TcspTotalVolumnOfAsset';

export const getTcspTotalVolumeOfAssetDisplayValue = (
  totalVolumeOfAsset: string,
  translateFunc: (key: string) => string
) => {
  const value = TOTAL_VOLUME_OF_ASSET_MAP[totalVolumeOfAsset];

  if (!value) return '';

  return translateFunc(`tcspAccountApplication.tcspTotalVolumeOfAsset.${value}`);
};
