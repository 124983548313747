import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface AgentCurrency {
  chainCode: string;
  networkDisplayName: string;
  tokenDisplayName: string;
  tokenName: string;
  currency: string;
}

interface InitState {
  list: AgentCurrency[];
}

const initialState: InitState = { list: [] };

export const agentCurrency = createSlice({
  name: 'agentCurrency',
  initialState,
  reducers: {
    setAgentCurrencyList: (state, action: PayloadAction<AgentCurrency[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setAgentCurrencyList } = agentCurrency.actions;

export const selectAgentCurrencyList = (state: RootState) => state.agentCurrency;

export default agentCurrency.reducer;
