import { ValidationConfig, ValidatorResponseWithKey } from '../types';
import { executeValidators } from './executeValidators';
import { normalizeValidationResult } from './normalizeValidationResult';

export const getValidationResult = (config: ValidationConfig[]) => {
  const result: ValidatorResponseWithKey[] = [];
  let isClean = true;

  for (let i = 0; i < config.length; i++) {
    const { key, value, validators = [] } = config[i];

    const fieldValidationResult = executeValidators({
      key,
      value,
      validators,
    });

    if (!fieldValidationResult.isValid) {
      isClean = false;
    }

    result.push({ key, ...fieldValidationResult });
  }

  return { result: normalizeValidationResult(result), isClean };
};
