export enum TableColumnKey {
  ProgramName = 'programName',
  ProgramCurrency = 'programCurrency',

  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
}

export interface TableRow {
  id: number;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.ProgramCurrency]: string;
  [TableColumnKey.CreationTime]: string;
  [TableColumnKey.LastModifiedTime]: string | undefined;

  rawData: any;
}
