const filterTableTranslateKey = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  transferOrderId: 'transfer_order_id',
  allocationType: 'allocation_type',
  from: 'from',
  to: 'to',
  creditCurrency: 'credit_currency',
  creditAmount: 'credit_amount',
  status: 'status',
  createdBy: 'created_by',
};

export { filterTableTranslateKey };
