import { ChangeEvent } from 'react';

import { Box, SelectChangeEvent } from './MuiGenerals';
import PhoneCodeSelection from './Selection/PhoneCodeSelection';
import { MpTextFieldNumberOnly } from './TextField';

export default function PhoneCodeNumberBundle({
  phoneCode,
  phoneNumber,
}: {
  phoneCode: { label?: string; value: string; onChange: (e: SelectChangeEvent<string>) => void };
  phoneNumber: {
    label?: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  };
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <PhoneCodeSelection
        label={phoneCode.label}
        value={phoneCode.value}
        onChange={phoneCode.onChange}
        sx={{ formControl: { flex: 1 } }}
      />
      <MpTextFieldNumberOnly
        label={phoneNumber.label}
        value={phoneNumber.value}
        onChange={phoneNumber.onChange}
        sx={{ marginLeft: '12px', flex: 3 }}
        integerOnly
        showNumericKeyboard
      />
    </Box>
  );
}
