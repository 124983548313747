import { OpApprove } from '../../../assets/icons';
import { OpIconButton } from '../../../components';
import { useTranslation } from '../../../hooks';

interface IProps {
  onClick: () => void;
  isDisabled: boolean;
  isHide?: boolean;
}

const ApproveRejectCell = (props: IProps) => {
  const { onClick, isDisabled, isHide } = props;
  const { tc } = useTranslation();

  if (isHide) return <></>;

  return (
    <div style={{ padding: '0 12px' }}>
      <OpIconButton
        title={tc('approve_reject_request_btn')}
        svgUrl={OpApprove}
        onClick={onClick}
        size="1.5rem"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ApproveRejectCell;
