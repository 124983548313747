import { MenuItemLink, useTranslate } from 'react-admin';
import { usePermission } from '../../hooks';
import { Box } from '../MuiGenerals';
import { COLOR_THEME } from '../../style/colorTheme';
import { importantStyle } from '../../utils';

const sx = {
  menuItem: {
    a: {
      color: COLOR_THEME.Menu.Expanded.Text,
      '&.RaMenuItemLink-active': {
        color: COLOR_THEME.Menu.Expanded.Text,
        fontWeight: 'bold',
        backgroundColor: importantStyle(COLOR_THEME.Menu.HoverItem),
      },
      fontSize: '1rem',
    },
    'div:hover': {
      a: {
        backgroundColor: importantStyle(COLOR_THEME.Menu.HoverItem),
        color: COLOR_THEME.Menu.Collapsed.Text,
        '&.RaMenuItemLink-active': {
          color: COLOR_THEME.Menu.Expanded.Text,
        },
      },
    },
  },
};

interface IMenuItemProps {
  title: string;
  to: string;
  permission: string;
  onClick?: () => void;
}

const MenuItem = (props: IMenuItemProps) => {
  const translate = useTranslate();
  const { hasPermission } = usePermission();

  const t = (key: string) => translate(`menu.${key}`);
  const { title, to, permission, onClick } = props;

  return permission && hasPermission(permission) ? (
    <Box
      sx={{
        width: 'calc(100% - .5rem)',
        display: 'flex',
        ...sx.menuItem,
      }}
      key={title}
      onClick={onClick}
    >
      <MenuItemLink
        to={to}
        primaryText={t(title)}
        sx={{
          flex: '1',
        }}
      />
    </Box>
  ) : (
    <></>
  );
};

export default MenuItem;
