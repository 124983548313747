import '../../../src/App.module.scss';

import { useEffect, useState } from 'react';
import { MenuItemLink, useLocaleState, useTranslate } from 'react-admin';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { usePermission } from '../../hooks';
import { routeList } from '../../hooks/useRoutePermission';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';
import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB, importantStyle } from '../../utils';
import { versionNumber } from '../../utils/constant';
import { useZusListStore } from '../../zustand/store';
import Logo from '../Logo';
import MenuItem from './MenuItem';

const sx = {
  logo: {
    padding: '32px 20px 80px',
  },
  menuPadding: {
    paddingLeft: '10px',
  },
  expanded: {
    color: COLOR_THEME.Menu.Expanded.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  notExpanded: {
    color: COLOR_THEME.Menu.Collapsed.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  muiAccordion: {
    '&.MuiAccordion-root:before': { content: 'none' },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(270deg)',
      '>svg': { color: importantStyle(COLOR_THEME.Menu.Collapsed.Text) },
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(360deg)',
      '>svg': { color: importantStyle(COLOR_THEME.Menu.Expanded.Text) },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      maxHeight: '30px',
      margin: '5px 0px',
    },
    minWidth: '250px;',
  },
  accordionSummary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionDetails: {
    padding: '5px',
    a: {
      color: `rgba(${hexToRGB(COLOR_THEME.Menu.Collapsed.Text)}, 0.7)`,
      '&.RaMenuItemLink-active': {
        color: COLOR_THEME.Menu.Expanded.Text,
      },
    },
    'div:hover': {
      a: {
        backgroundColor: importantStyle(COLOR_THEME.Menu.HoverItem),
        color: COLOR_THEME.Menu.Collapsed.Text,
        '&.RaMenuItemLink-active': {
          color: COLOR_THEME.Menu.Expanded.Text,
        },
      },
    },
  },
};

export default function Menu(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const [selected, setSelected] = useState('');

  const isMobile = useAppSelector(selectIsMobileView);
  const [locale] = useLocaleState();

  const L = routeList;

  const getCurrentActiveMenu = (type: string) => {
    const currentPath = window.location.pathname.replace('/', '');

    for (const [_, info] of Object.entries(menuInfos)) {
      for (const routeArr of info.routeInfo) {
        if (routeArr[1] === currentPath) {
          if (type === 'parent') return info.title;
          if (type === 'children') return routeArr[0];
        }
      }
    }
  };

  const filterRouteInfoByMedia = (routeInfo: any) => {
    if (!isMobile) return routeInfo;

    const mobileHiddenRoute = [
      // Setting
      'distributorAgentList',

      // Program Agent Management
      'programAgentWallet',
      'creditSwapRecord',

      // Client Management
      'clientApplication',

      // Report
      'programAgentReport',
      'programAgentReportDetail',
      'distributorAgentReport',
      'distributorAgentReportDetail',
      'clientReport',
      'clientReportDetail',
      'creditAllocationRecord',
      'monthlyRebateReport',
      'customerSpendingReport',
    ];

    return routeInfo.filter((routeArr: any) => {
      const route = routeArr[0];

      if (mobileHiddenRoute.includes(route)) return false;

      return true;
    });
  };

  const redirectToDownloadApp = () => {
    const localeToLangMap: Record<string, string> = {
      en: 'en',
      zh_cn: 'zh-TW',
    };

    if (!process.env.REACT_APP_DOWNLOAD_APP_URL) return;

    const redirectUrl = `${process.env.REACT_APP_DOWNLOAD_APP_URL}?lang=${localeToLangMap[locale]}`;

    window.open(redirectUrl);
  };

  //###add-new-page
  const menuInfos = {
    setting: {
      title: t('setting'),
      routeInfo: [
        L.distributorAgentList,
        L.distributorAgentProgram,
        L.userSetting,
        L.exchangeRate,
        L.exchangeRateConfiguration,
      ],
    },
    programAgentManagement: {
      title: t('programAgentManagement'),
      routeInfo: [
        L.programList,
        L.programAgentDeposit,
        L.programAgentWallet,
        L.programAgentRebateWallet,
        L.custodyWallet,
        L.creditSwapRecord,
        L.feeAdjustmentRate,
      ],
    },
    distributorAgentManagement: {
      title: t('distributorAgentManagement'),
      routeInfo: [L.distributorAgentWallet, L.creditAllocation],
    },
    clientManagement: {
      title: t('clientManagement'),
      routeInfo: [L.clientApplication, L.clientList],
    },
    report: {
      title: t('reportAms'),
      routeInfo: [
        L.programAgentReportDetail,
        L.distributorAgentReportDetail,
        L.clientReportDetail,
        L.programAgentReport,
        L.distributorAgentReport,
        L.clientReport,
        L.creditAllocationRecord,
        L.monthlyRebateReport,
        L.customerSpendingReport,
        // L.transferRecord,
        // L.cardTransaction,
      ],
    },
    rebate: {
      title: t('rebate'),
      routeInfo: [L.rebateDistributionTask, L.rebateRecord],
    },
  };

  // check which item is currently active and expand the menu stack
  useEffect(() => {
    const currentActiveMenuStack = getCurrentActiveMenu('parent');

    setSelected(currentActiveMenuStack || '');
  }, []);

  return (
    <div style={sx.menuPadding}>
      <div style={sx.logo}>
        <Logo closeMobileSideMenu={props.closeMobileSideMenu} />
      </div>
      <div
        style={{
          height: '100%',
          overflowY: 'auto',
          maxHeight: 'calc((100dvh - 163px) * 0.8)',
        }}
      >
        {/* Dashboard */}
        <MenuItem
          title={L['dashboard'][1]}
          to={L['dashboard'][0]}
          permission={L['dashboard'][2]}
          onClick={props.closeMobileSideMenu}
        />

        {/* MenuStacks */}
        <>
          {
            // loop through menuInfos and render each menu stack
            Object.entries(menuInfos).map(([key, info]) => {
              return (
                <MenuStack
                  key={key}
                  selected={selected}
                  setSelected={setSelected}
                  title={info.title}
                  list={filterRouteInfoByMedia(info.routeInfo)}
                  closeMobileSideMenu={props.closeMobileSideMenu}
                />
              );
            })
          }
        </>

        {/* Version Number */}
        <Box
          sx={{
            width: '320px',
            textAlign: 'center',
            color: COLOR_THEME.Menu.Collapsed.Text,
            position: 'fixed',
            bottom: '12px',
          }}
        >
          <div
            style={{
              width: '130px',
              border: '1px solid #fff',
              borderRadius: '32px',
              padding: '4px',
              margin: '8px auto',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={redirectToDownloadApp}
          >
            {t('downloadAppButton')}
          </div>
          {t('version')}: {versionNumber}
        </Box>
      </div>
    </div>
    // </RaMenu>
  );
}

export function MenuStack(props: any) {
  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const resetZusList = () => zusListStore.clear();
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const { title, list, selected, setSelected } = props;
  const expanded = selected === title;
  const [selectedPagePathname, setSelectedPagePathname] = useState(
    window.location.pathname.substring(1)
  );
  const toggle = () =>
    setSelected((key: string) => {
      if (key === title) return '';
      return title;
    });
  const canRender = (key: string) => {
    if (key === '*****' || key === '') return true; //for temporary use
    return hasPermission(key);
  };
  const pendingOperationsCount: {
    merchantOperation: string;
    ledgerDepositRecord: string;
  } = props?.meta?.pendingOperationsCount || {
    merchantOperation: '0',
    ledgerDepositRecord: '0',
  };
  const genItem = ([text, to, permission]: any = []) => {
    return (
      canRender(permission) && (
        <Box sx={{ width: 'calc(100% - .5rem)', display: 'flex' }} key={text}>
          <MenuItemLink
            to={to}
            primaryText={t(text)}
            sx={{
              flex: '1',
              '&.RaMenuItemLink-active': {
                fontWeight: 'bold',
              },
            }}
            onClick={
              selectedPagePathname !== to
                ? () => {
                    resetZusList();
                    setSelectedPagePathname(to);
                    props.closeMobileSideMenu && props.closeMobileSideMenu();
                  }
                : undefined
            }
          />
        </Box>
      )
    );
  };
  const subMenus = list.map(genItem);
  const isHidden = subMenus.filter((dom: any) => dom).length === 0;
  if (isHidden) return <></>;
  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        ...sx.muiAccordion,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Typography variant="h6">{list.map(genItem)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
