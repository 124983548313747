import { ReactNode } from 'react';

import { Box } from '../../../components/MuiGenerals';
import useGridFilterCustomSx from '../../../hooks/useGridFilterCustomSx';

const FilterGrid = ({ children }: { children: ReactNode }) => {
  const { gridFilterSx } = useGridFilterCustomSx();

  return (
    <Box sx={gridFilterSx} className="gridFilter">
      {children}
    </Box>
  );
};

export default FilterGrid;
