import React, { useState } from 'react';

import APIs from '../../../../../api';
import { ApiResult } from '../../../../../api/types';
import Table from '../../../../../common/filterTable/components/Table';
import FilterContainer from '../../../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../../../common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../../../common/page/hooks/useNewFilterTablePage';
import { Currency } from '../../../../../common/types/Currency';
import { ExportBtn } from '../../../../../components/Button';
import { useDatePicker } from '../../../../../components/DatePicker';
import { DivideLine } from '../../../../../components/Layout';
import { Iprefix as IPrefix } from '../../../../../hooks/useTranslation';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/Table';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import SearchFirstDataGridOverlay from '../../../../../components/Layout/SearchFirstDataGridOverlay';
import { convertDateTimeStringToFormat } from '../../../../../utils/dateUtils';

interface IDailyReportTabProps { }

const DailyReportTab: React.FC<IDailyReportTabProps> = (props) => {
  const translatePrefix = 'programAgentReportDetail.dailyReportTab' as IPrefix;
  const { getListParams } = useGetListParams();

  const settlementDateStart = new Date();
  settlementDateStart.setHours(0, 0, 0, 0);
  settlementDateStart.setDate(settlementDateStart.getDate() - 1);
  const settlementDateEnd = new Date();
  settlementDateEnd.setHours(0, 0, 0, 0);
  settlementDateEnd.setDate(settlementDateEnd.getDate() - 1);
  const DateObj = {
    SettlementDate: useDatePicker({
      start: {
        defaultDate: settlementDateStart,
      },
      end: {
        defaultDate: settlementDateEnd,
      },
      isEndOfSecond: false,
    }),
  };

  const initFields = {
    [FilterFieldsKeys.settlementDateFrom]: convertDateTimeStringToFormat(
      settlementDateStart.toISOString(),
      'yyyy-MM-dd'
    ),
    [FilterFieldsKeys.settlementDateTo]: convertDateTimeStringToFormat(
      settlementDateEnd.toISOString(),
      'yyyy-MM-dd'
    ),
    [FilterFieldsKeys.programName]: '',
    // Currency is Required, so currently will use the string, maybe change to Enum in the future
    [FilterFieldsKeys.currency]: 'HKD',
  };
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const getTableDataApiFunc = async (params: any) => {
    if (isFirstSearch) {
      return;
    }
    return APIs.report.programAgentReportDetail.dailyReport.getAll(params);
  };
  const exportTableApiFunc = APIs.report.programAgentReportDetail.dailyReport.getExport;

  const pageConfig = {
    initFields,
    DateObj,
    translatePrefix,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  };

  const {
    page,
    onPageChange,
    fields,
    setFields,
    list,
    onFilterSearch,
    onFilterReset,
    onExport,
    getIsHavePermission,
    Tabs,
  } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const columns = useTableColumns();

  const isShowExportBtn = getIsHavePermission(
    AgentPortalFeatureCode.Report.ProgramAgentReportDetail.DailyReportTab.Export
  );

  const handleExport = async () => {
    await onExport('Program Agent Daily Report(Detail)', totalCounts, convertToTableRows);
  };
  return (
    <>
      <FilterTableLayoutContainer>
        <FilterContainer>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={() => {
              onFilterSearch();
              setIsFirstSearch(false);
            }}
            onReset={() => {
              onFilterReset();
              DateObj.SettlementDate.resetDate();
            }}
            DateObj={DateObj}
          />
        </FilterContainer>

        <DivideLine />

        <TableContainer>
          <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={totalCounts}
              page={page}
              onPageChange={onPageChange}
              customNoRowOverlay={isFirstSearch ? SearchFirstDataGridOverlay : undefined}
            />
          </Tabs>
        </TableContainer>
      </FilterTableLayoutContainer>
    </>
  );
};

export default DailyReportTab;
