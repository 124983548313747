import '../index.css';

import { useState } from 'react';

import SideMenuLeftArrow from '../../../../assets/icons/side-menu-left-arrow.png';
import doubleArrowImgSrc from '../../../../assets/icons/side-menu-left-right-arrow.png';
import SideMenuRightArrow from '../../../../assets/icons/side-menu-right-arrow.png';
// import SideMenuToggleBtnIcon from '../../../../assets/icons/side-menu-toggle-btn-icon.svg';
import logo from '../../../../assets/logo/ams-logo.png';
import { useTranslation } from '../../../../hooks';

interface ToggleButtonProps {
  isCollapse: boolean;
  onCollapseClick?: () => void;
}

const ToggleButton = (props: ToggleButtonProps) => {
  const { onCollapseClick, isCollapse } = props;

  // boolean for animation usage
  const [isClicked, setIsClicked] = useState(false);

  const { t } = useTranslation('menu');

  const arrowImgSrc = doubleArrowImgSrc;
  const text = isCollapse ? `${t('toggleBtnExpand')}` : `${t('toggleBtnCollapse')}`;

  const onClick = () => {
    setIsClicked(true);
    onCollapseClick && onCollapseClick();
  };

  const onAnimationEnd = () => {
    setIsClicked(false);
  };

  return (
    <>
      {isCollapse && <div className={`toggle-btn-overlay`}></div>}
      <div
        className={`toggle-btn 
          ${isCollapse ? 'expand' : 'collapse'} 
          ${isClicked ? 'fade-in-out' : ''}`}
        onClick={onClick}
        onAnimationEnd={onAnimationEnd}
      >
        {isCollapse && <img src={logo} alt="toggle-logo" className="toggle-btn-icon" />}
        <div className="text-container">
          <img src={arrowImgSrc} alt="arrow" className="arrow" />
          {text}
        </div>
      </div>
    </>
  );
};

export default ToggleButton;
