import React, { useContext } from 'react';

import PageWrapper from '../components/PageWrapper';
import emailIconSrc from '../../../../assets/icons/login-page-email-icon.png';
import backBtnIcon from '../../../../assets/icons/login-page-back-button.png';

import { LoginContext } from '..';
import { LoginFieldsKeys, LoginPageShareContextKeys, PageKeys } from '../types';
import LoginInput from '../components/LoginInput';
import useTranslation from '../../../../hooks/useTranslation';
import { PALETTE } from '../../../../style/colorTheme';
import useLoading from '../../../../hooks/useLoading';
import { useAlerting } from '../../../../hooks';
import useEnterEmailValidation from '../helpers/useEnterEmailValidation';
import APIs from '../../../../api';
import getDeviceId from '../helpers/getDeviceId';
import getDeviceName from '../helpers/getDeviceName';
import { getUrlProgramAgentId } from '../helpers/getUrlProgramAgentId';

interface IProps {}

const EnterForgotPasswordEmail = (props: IProps) => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation('login');
  const { showLoading, hideLoading } = useLoading();
  const { fields, setFields, setPage, setPageShareContext } = loginContext;
  const { alerting } = useAlerting();
  const { validate } = useEnterEmailValidation({ fields });

  async function onSubmit() {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    showLoading('getLoginStatus');
    const res = await APIs.merchantPortal.auth.getLoginStatusV2({
      email: fields.email,
      deviceId: getDeviceId(),
      deviceName: getDeviceName(),
      programAgentId: getUrlProgramAgentId(fields[LoginFieldsKeys.ProgramAgentId]),
    });
    hideLoading('getLoginStatus');

    if (!res) {
      return;
    }

    if (!res.isEmailExist) {
      return alerting('error', t('emailNotExistError'));
    }

    if (res.isDeviceRegistered) {
      setPageShareContext({ [LoginPageShareContextKeys.IsDeviceRegistered]: true });
    } else {
      setPageShareContext({ [LoginPageShareContextKeys.IsDeviceRegistered]: false });
    }

    setPage(PageKeys.EmailVerification);
  }
  return (
    <PageWrapper onSubmit={onSubmit}>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: PALETTE.Primary,
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
          padding: '12px 12px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div style={{ width: '20px', height: '20px', cursor: 'pointer' }}>
            <img
              src={backBtnIcon}
              alt="back"
              onClick={() => {
                setFields({ ...fields, [LoginFieldsKeys.Email]: '' });
                setPage(PageKeys.EnterEmail);
              }}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#919BB4',
              marginLeft: '12px',
            }}
          >
            {t('enterForgotPasswordEmail.title')}
          </div>
        </div>
        <div
          style={{
            fontSize: '12px',
            color: '#919BB4',
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          {t('enterForgotPasswordEmail.enterVerifiedEmail')}
        </div>
        <div
          style={{
            marginTop: '30px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <LoginInput
            label={t('enterForgotPasswordEmail.email')}
            value={fields[LoginFieldsKeys.Email]}
            onChange={(e) =>
              setFields((fields) => ({ ...fields, [LoginFieldsKeys.Email]: e.target.value }))
            }
            iconSrc={emailIconSrc}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default EnterForgotPasswordEmail;
