import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

export const lengthLimitValidator =
  (errorMessage: string, { min, max }: { min: number; max: number }) =>
  (fieldKey: string, value: any): ValidatorResponse => {
    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.LengthLimit,
    };

    // regex to check if string is between min and max
    const regex = new RegExp(`^.{${min},${max}}$`);
    const isInvalid = !regex.test(value);

    if (isInvalid) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
