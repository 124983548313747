import { useEffect, useState } from 'react';

import { ensureAccessToken, forceLogout } from '../api/axiosInstance';
import { createThrottleFunc } from '../utils';
import { LAST_ACTIVE_UPPER_LIMIT_MINS } from '../utils/config';

const getNow = () => new Date().getTime();

export function useLastActiveTime() {
  const [lastActiveTime, rawSetActiveTime] = useState(0);

  const setActiveTime = (time: number) => {
    localStorage.setItem('lastActiveTime', String(time));
    rawSetActiveTime(time);
  };

  const hasAction = createThrottleFunc(() => {
    setActiveTime(getNow());
    ensureAccessToken();
  });

  useEffect(() => {
    // login as a trigger to update lastActiveTime
    localStorage.setItem('lastActiveTime', String(getNow()));
    hasAction();
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', hasAction);
    window.addEventListener('keydown', hasAction);

    return () => {
      window.removeEventListener('mousemove', hasAction);
      window.removeEventListener('keydown', hasAction);
    };
  }, []);

  return lastActiveTime;
}

export default function useIsActive() {
  const lastActiveTime = useLastActiveTime();

  const upperLimit = 1000 * 60 * LAST_ACTIVE_UPPER_LIMIT_MINS;
  const [isActive, setIsActive] = useState(getNow() - lastActiveTime < upperLimit);

  useEffect(() => {
    function checker() {
      const storageActiveTime = Number(localStorage.getItem('lastActiveTime')) || 0;
      const latestTime = Math.max(storageActiveTime, lastActiveTime);
      const isActive = getNow() - latestTime < upperLimit;

      setIsActive(isActive);

      if (!isActive) {
        const message = `idle for ${LAST_ACTIVE_UPPER_LIMIT_MINS} minutes`;
        return forceLogout(message);
      }

      return isActive;
    }
    // check isActive every 10 second
    let timerId = setInterval(checker, 1000 * 10);

    return () => {
      clearInterval(timerId);
    };
  }, [lastActiveTime]);

  return isActive;
}
