import React from 'react';

import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import PermissionTab from '../../../components/Layout/PermissionTab';
import DailyReportTab from './components/DailyReportTab';
import MonthlyReportTab from './components/MonthlyReportTab';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

interface IProgramAgentReportProps { }

const translatePrefix = 'programAgentReportDetail' as IPrefix;

const ProgramAgentReportDetail: React.FC<IProgramAgentReportProps> = (props) => {
  const { t } = useTranslation(translatePrefix);
  return (
    <PermissionTab
      contentArr={[
        {
          translatePrefix: `${translatePrefix}.dailyReportTab` as IPrefix,
          element: <DailyReportTab />,
        },
        {
          translatePrefix: `${translatePrefix}.monthlyReportTab` as IPrefix,
          element: <MonthlyReportTab />,
        },
      ]}
      tabsArr={[
        {
          name: t(`dailyReportTab.title`),
          value: `${translatePrefix}.dailyReportTab` as IPrefix,
          permissionCode: AgentPortalFeatureCode.Report.ProgramAgentReportDetail.DailyReportTab.prefix,
        },
        {
          name: t(`monthlyReportTab.title`),
          value: `${translatePrefix}.monthlyReportTab` as IPrefix,
          permissionCode: AgentPortalFeatureCode.Report.ProgramAgentReportDetail.MonthlyReportTab.prefix,
        },
      ]}
    />
  );
};

export default ProgramAgentReportDetail;
