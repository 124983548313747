import { EnumCreditAllocationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';
import { ApiResult } from '../../../../api/types';
import getDisplayApprovalProgress from '../../../../common/approval/helpers/getDisplayApprovalProgress';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';
import { getTransferRequestStatusDisplayValue } from '../../../../helper/displayValue/getTransferRequestStatusDisplayValue';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { tc, translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const toDisplayAmount = displayAmountCurrying(row.creditCurrencyDecimals);

      const approvalProgress = getDisplayApprovalProgress({
        approvalsCount: row.approvalsCount,
        approvalsRequired: row.approvalsRequired,
        applicationStatus: row.status,
        approvedStatus: EnumCreditAllocationStatus.Approved,
        translateFunc: tc,
      });

      const statusDisplayValue = getTransferRequestStatusDisplayValue(row.status, translate);
      const transactionAmountDisplayValue = toDisplayAmount(row.creditAmount);

      return {
        id: index,
        [TableColumnKey.OrderId]: row.targetTransferOrderId,
        [TableColumnKey.ApprovalProgress]: approvalProgress,
        [TableColumnKey.DistributorAgentId]: row.toWalletClientId,
        [TableColumnKey.Currency]: row.creditCurrency,
        [TableColumnKey.TransactionAmount]: transactionAmountDisplayValue,
        [TableColumnKey.Status]: statusDisplayValue,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
