import { EnumLedgerTransactionOrderType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { IdateFilter, Ipage } from '../../../../../api/types';
import { useListMappingTransform, useTranslation } from '../../../../../hooks';
import useSystemCurrency from '../../../../../hooks/useSystemCurrency';
import { displayAmountCurrying, toDisplayTime } from '../../../../../utils';
import { initZusParams } from '../../../../../utils/config';
import { createZusInstance } from '../../../../../zustand/store';
import { filterTableTranslateKey as TK } from '../mappings/translate';
import { IapiGetAllRes } from '../types';

export interface IsearchParams extends Partial<IdateFilter> {
  orderId: string;
  orderType: number | '';
  fromClientId: string;
  toClientId: string;
  currency: string;
}

type ApiParam = Ipage & Partial<IsearchParams>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tc } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const listMapping = (array: IapiGetAllRes[], omitKeys: string[] = []) => {
    const res = array.map((item) => {
      const decimals = CurrencyDecimalsMapping[item.currency] || 0;
      const displayAmount = displayAmountCurrying(item.decimals);
      const mapping: Array<[string, IapiGetAllRes | string | number]> = [
        ['rawData', item],
        [TK.orderId, item.orderId],
        [TK.orderType, t(EnumLedgerTransactionOrderType[item.orderType])],
        [TK.from, item.fromClientId],
        [TK.to, item.toClientId],
        [TK.currency, item.currency],
        [TK.transactionAmount, displayAmount(item.amount)],
        [TK.postBalance, displayAmount(item.postBalance)],
        [TK.ledgerTransactionId, item.ledgerTransactionId],
        [TK.remarks, item.remarks],
        [TK.creationTime, toDisplayTime(item.createdDate)],
      ];

      const mappedResult = mapping.filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export { useZusParams, useListMapping };
