import { ApiResult } from '../../../../api/types';
import { getAgentRoleDisplayValue } from '../../../../helper/displayValue/getAgentRoleDisplayValue';
import { getAgentStatusDisplayValue } from '../../../../helper/displayValue/getAgentStatusDisplayValue';
import { getAgentTypeDisplayValue } from '../../../../helper/displayValue/getAgentTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import useAgentRoles from '../../../../hooks/useAgentRoles';
import { toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc, translate } = useTranslation();
  const { AgentRoleIdEnum } = useAgentRoles();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      return {
        id: index,
        [TableColumnKey.DistributorAgentId]: row.distributorAgentId,
        [TableColumnKey.AgentType]: getAgentTypeDisplayValue(row.agentType, translate) || '',
        [TableColumnKey.LoginEmail]: row.email,
        [TableColumnKey.Name]: row.name,
        [TableColumnKey.Role]: getAgentRoleDisplayValue(row.roles, AgentRoleIdEnum),
        [TableColumnKey.Status]: getAgentStatusDisplayValue(row.status),
        [TableColumnKey.Remarks]: row.remark,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedBy]: row.lastModifiedBy,
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
