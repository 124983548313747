import { parseUserAgent } from 'detect-browser';

export const getUserAgent = () => navigator.userAgent;

export const getParsedUserAgent = () => {
  const userAgent = navigator.userAgent;
  const parsedUserAgent = parseUserAgent(userAgent);

  return parsedUserAgent;
};
