import React from 'react';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';

interface IProps {
  title: string;
  value: any;
  isEditable: boolean;
  children?: React.ReactNode;
  width?: string;
}

const EditableItem = (props: IProps) => {
  const { title, value, isEditable, children, width = '100%' } = props;

  const isMobile = useAppSelector(selectIsMobileView);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px',
        fontSize: isMobile ? '14px' : '16px',
        width: width,
        maxWidth: '360px',
      }}
    >
      <div style={{ padding: '12px', fontWeight: 'bold' }}>{title}</div>
      <div style={{ width: '100%', textAlign: 'center' }}>{isEditable ? children : value}</div>
    </div>
  );
};

export default EditableItem;
