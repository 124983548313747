import { useCallback, useEffect, useState } from 'react';

import APIs from '../../../../api';
import { SingleSelection } from '../../../../components';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import useAgentRoles from '../../../../hooks/useAgentRoles';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const AgentRoleSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc } = useTranslation();
  const { AgentRolesEnum } = useAgentRoles();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('agentRole') })}
      value={value.length > 0 ? value : ''}
      clearSelect={onClear}
      onChange={onChange}
      enumData={AgentRolesEnum}
      nameFn={(item) => item}
    />
  );
};

export default AgentRoleSingleSelection;
