import { defaultListParams } from '../../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../../common/filterTable/helpers/cleanParams';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const profile = useAppSelector(selectProfile);

  const getListParams = (fields: FilterFields) => {
    const { createdDateFrom, createdDateTo, lastModifiedTimeFrom, lastModifiedTimeTo, currency } =
      fields;

    const params: GetListParams = {
      ...defaultListParams,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom: lastModifiedTimeFrom,
      lastModifiedDateTo: lastModifiedTimeTo,
      // customerNumber: pfhNumber,
      currency: currency.currency,
      clientId: profile?.jwtDetails?.programAgentId || '',
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
