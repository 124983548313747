const OptionalValue = ({ value }: { value: string | number }) => {
  if (!value && String(value) !== '0') return <></>;

  return (
    <>
      <br />
      <br />
      {value}
    </>
  );
};

export default OptionalValue;
