import { EnumAgentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';
import { useTranslation } from '../../../../../../hooks';
import useValidation from '../../../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../../../utils/validators/requiredValidator';
import { CreateUserFields } from '../types';

interface IProps {
  fields: CreateUserFields;
}

const useCreateUserValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('userSetting');

  const validationConfig: ValidationConfigProps = [
    {
      key: 'agentType',
      value: fields['agentType'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('agentType'),
          })
        ),
      ],
    },
    {
      key: 'distributorAgentId',
      value: fields['distributorAgentId'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('distributorAgentId'),
          }),
          Number(fields.agentType) === EnumAgentType.DistributorAgent
        ),
      ],
    },
    {
      key: 'loginEmail',
      value: fields['loginEmail'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('loginEmail'),
          })
        ),
      ],
    },
    {
      key: 'name',
      value: fields['name'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('name'),
          })
        ),
      ],
    },
    {
      key: 'role',
      value: fields['role'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('role'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useCreateUserValidation;
