import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import { ApproveHistory } from '../../common/approval/types/ApproveHistory';
import axios from '../axiosInstance';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(AgentPortal.EPtDaManagement.creditAllocation.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(AgentPortal.EPtDaManagement.creditAllocation.export, input);
};

const createTransferRequest = async (
  params: {
    distributorAgentId: string;
    creditCurrency: string;
    creditAmount: string;
    creditCurrencyDecimals: number;
  },
  config = {}
) => {
  const res = await axios.post<never, any>(
    AgentPortal.EPtDaManagement.creditAllocation.paToDa,
    params,
    {}
  );

  return res;
};

const getApproveHistory = async (params: any, config?: any) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, ApproveHistory[]>(
    AgentPortal.EPtDaManagement.creditAllocation.details,
    input
  );
};

const approve = async (params: any, config?: any) => {
  const res = await axios.post(
    AgentPortal.EPtDaManagement.creditAllocation.approve,
    params,
    config
  );

  return res;
};

const reject = async (params: any, config?: any) => {
  const res = await axios.post(AgentPortal.EPtDaManagement.creditAllocation.reject, params, config);

  return res;
};

const getBalance = async (
  params: {
    currency: string;
    tokenName: string;
    clientId: string;
  },
  config?: any
) => {
  const res = await axios.get<
    any,
    {
      currency: string;
      totalBalance: string;
      decimals: number;
      agentSupportedAsset: { tokeName: string };
    }[]
  >(AgentPortal.EPtDaManagement.creditAllocation.balance, {
    params,
    ...config,
  });

  return res;
};

export default {
  getAll,
  getExport,
  createTransferRequest,
  getApproveHistory,
  approve,
  reject,
  getBalance,
};
