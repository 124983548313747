import BigNumber from 'bignumber.js';
import { ChangeEvent, useEffect, useState } from 'react';

import {
  EnumDistributorAgentProgramStatus,
  EnumRebateMode,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import APIs from '../../../../../api';
import { DialogInOne, SingleSelection } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { GridBox } from '../../../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../../../components/MuiGenerals';
import MpTextFieldWithAdornment from '../../../../../components/TextField/MpTextFieldWithAdornment';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useGridBoxInputValidation from '../../../../../hooks/useGridBoxInputValidation';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { isStrictNaN } from '../../../../../utils';
import { useZusDialogStore } from '../../../../../zustand/store';
import { useZusParams } from '../../hooks';
import { editDialogTranslateKey as TK } from '../../mappings/translate';
import { IapiGetAllRes } from '../../types';

export default function EditDialog() {
  const translatePrefix: Iprefix = 'distributorAgentProgram';
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { validateGridBoxInput } = useGridBoxInputValidation();
  const { alerting } = useAlerting();
  const zusParams = useZusParams();

  const {
    distributorAgent,
    programName = '',
    rebateMode = '',
    daRebateRate = '',
    customerRebateRate = '',
    status = '',
  } = (zusDialog.meta as IapiGetAllRes | undefined) || {};

  const initFields = {
    rebateMode: '',
    rebateRateToDistributorAgent: '',
    status: '',
  };

  const displayCustomerRebateRate = !isStrictNaN(customerRebateRate)
    ? new BigNumber(customerRebateRate).multipliedBy(100).toString() + '%'
    : '';

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      rebateMode: String(rebateMode),
      rebateRateToDistributorAgent: !isStrictNaN(daRebateRate)
        ? new BigNumber(daRebateRate).multipliedBy(100).toString()
        : '',
      status: String(status),
    }));
  }, [zusDialog.match('editRequestDialog')]);

  const [fields, setFields] = useState(initFields);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((prev) => ({ ...prev, [field]: e.target.value }));
    };

  const labelElePairArr: [string, JSX.Element][] = [
    [TK.distributorAgentId, <Box>{distributorAgent?.distributorAgentId}</Box>],
    [TK.distributorName, <Box>{distributorAgent?.name}</Box>],
    [TK.programName, <Box>{programName}</Box>],

    [
      TK.rebateMode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.rebateMode) })}
        value={fields.rebateMode}
        onChange={onChange('rebateMode')}
        enumData={EnumRebateMode}
        clearSelect={() => {}}
      />,
    ],
    [
      TK.rebateRateToDistributorAgentAndClient,
      <MpTextFieldWithAdornment
        mode="number"
        label={tc('phInputField', { fieldName: t(TK.rebateRateToDistributorAgentAndClient) })}
        value={fields.rebateRateToDistributorAgent}
        onChange={onChange('rebateRateToDistributorAgent')}
        endAdornment={{ adornmentNode: '%' }}
        decimalCount={4}
        showDecimalKeyboard
      />,
    ],
    [
      TK.status,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.status) })}
        value={fields.status}
        onChange={onChange('status')}
        enumData={EnumDistributorAgentProgramStatus}
        clearSelect={() => {}}
      />,
    ],
  ];

  const onClose = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const checkIsEdited = () => {
    return !!Object.entries(fields).filter(([key, val]) => {
      const typedKey = key as keyof typeof fields;

      if (typedKey === 'rebateRateToDistributorAgent') {
        return new BigNumber(val).dividedBy(100).toNumber() !== Number(daRebateRate);
      }

      return val !== String(zusDialog.meta[key]);
    }).length;
  };

  const onConfirm = async () => {
    const isEdited = checkIsEdited();

    if (!isEdited) {
      return alerting('warning', tc('no_changes_made_warning'));
    }
    const invalidMessage = validateGridBoxInput(labelElePairArr);

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const rebateRateToDaNumber = Number(fields.rebateRateToDistributorAgent);

    if (rebateRateToDaNumber > 100) {
      return alerting(
        'warning',
        tc('cannot_exceed_warning', {
          fieldName: t(TK.rebateRateToDistributorAgent),
          value: '100%',
        })
      );
    }

    const daRebateRate = new BigNumber(rebateRateToDaNumber).dividedBy(100).toNumber();

    const res = await APIs.setting.distributorAgentProgram.postEditProgram({
      distributorAgentId: distributorAgent?.distributorAgentId || '',
      programName: programName,
      daRebateRate,
      customerRebateRate,
      rebateMode: Number(fields.rebateMode),
      status: Number(fields.status),
    });

    if (!res) {
      return;
    }

    await onClose();
    alerting('success', t(TK.editSuccess));
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.editBtn),
    self: {
      open: zusDialog.match('editRequestDialog'),
      onClose: onClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm,
    onCancel: onClose,
  };

  return <DialogInOne {...dialogConfig} />;
}
