import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import api from '../api';
import { setRedirectTo } from '../reducer/alerterSlice';
import { logout } from '../reducer/profileSlice';
import { store } from '../reducer/store';
import { setIsRefreshingToken } from '../reducer/stuffSlice';
import {
  checkIsValidJSON,
  clearTokens,
  decodeJwt,
  getClientDateTimeDiffFromLocalStorage,
  readAccessToken,
  readRefreshToken,
  setClientDateTimeDiff,
  writeTokens,
} from '../utils';
import { ENDPOINT_HOST } from '../utils/config';
import { appError, networkError } from '../utils/errorHandler';

if (!ENDPOINT_HOST) throw Error('endpoint host is undefined');

interface generalResponse {
  result: object;
  error: any;
  requestId?: string;
}
const getAxiosInstance = (baseURL: string) => {
  const config: AxiosRequestConfig = { baseURL };
  const instance: AxiosInstance = axios.create(config);
  const isRefreshingToken = store.getState().stuff.isRefreshingToken;

  instance.interceptors.request.use(
    async (config) => {
      console.log(`[API Call] ${config.method?.toUpperCase()} ${config.url}`);

      const isIgnoreToken = 'ignoreToken' in config;

      if (isIgnoreToken || isRefreshingToken) return config;

      // const isInvalidAccessToken = !(await ensureAccessToken());

      // if (isInvalidAccessToken) {
      //   throw appError({
      //     code: 1001009,
      //     message: 'Invalid Access Token',
      //     requestId: '',
      //   });
      // }

      await ensureAccessToken();

      const accessToken = readAccessToken();

      if (accessToken) {
        config.headers = { Authorization: `Bearer ${accessToken}` };
      }

      return config;
    },
    (error: AxiosError) => {
      console.error(error);
    }
  );

  instance.interceptors.response.use(
    async (response: AxiosResponse<Partial<generalResponse>>) => {
      const data = response.data;
      const { requestId = '' } = data;

      if (response.config.responseType === 'blob') {
        const blobData = data as Blob;
        const blobStr = await blobData.text();

        const isValidJSON = checkIsValidJSON(blobStr);

        if (isValidJSON) {
          const blobDataObj = JSON.parse(blobStr);
          const { error, requestId = '' } = blobDataObj;
          if (error && error !== null) {
            return appError({
              ...error,
              requestId,
            });
          }
        }

        return data;
      }

      if ('error' in data && response.data.error != null)
        return appError({ ...data.error, requestId });

      return data.result;
    },
    (error: AxiosError<Partial<generalResponse>>) => {
      if (error?.code === 'ERR_CANCELED') return;
      if (!error) return;
      return networkError(error);
    }
  );

  return instance;
};

let isIdle = true;
export async function ensureAccessToken() {
  const isRefreshingToken = store.getState().stuff.isRefreshingToken;

  if (isRefreshingToken) return;

  if (window.location.pathname === '/redirectLogin') return true;

  const preAccessToken = readAccessToken();
  const preRefreshToken = readRefreshToken();

  if (!preAccessToken) return forceLogout('No Access Token');
  if (!preRefreshToken) return forceLogout('No Refresh Token');

  const isTokenExpired = checkTokenExpired(preAccessToken, preRefreshToken);

  if (isTokenExpired.refreshBuffer) return forceLogout('Refresh Token expired');

  if (isIdle) {
    if (isTokenExpired.accessBuffer) {
      isIdle = false;

      await fetchNextTokens(preRefreshToken);

      isIdle = true;
    }

    return true;
  }

  if (isTokenExpired.access) {
    return false;
  }
  return true;
}

async function fetchNextTokens(preRefreshToken: string) {
  store.dispatch(setIsRefreshingToken(true));

  const { accessToken = '', refreshToken = '' } =
    (await api.merchantPortal.auth.getNewTokens(preRefreshToken, {
      ignoreToken: true,
    })) || {};

  if (!accessToken || !refreshToken) {
    const message = `accessToken:${accessToken}\n refreshToken:${refreshToken}`;
    store.dispatch(setIsRefreshingToken(false));

    return forceLogout('invalid token');
  }

  const accessTokenInfo = decodeJwt(accessToken);
  setClientDateTimeDiff(accessTokenInfo?.iat);

  store.dispatch(setIsRefreshingToken(false));
  writeTokens({ accessToken, refreshToken });
}

export function forceLogout(reason: string, meta: any = undefined) {
  clearTokens();
  store.dispatch(logout());
  store.dispatch(setRedirectTo('/login'));
  saveLogoutReason(reason, meta);
  return false;
}
function saveLogoutReason(reason: string, meta: any) {
  const record = JSON.parse(localStorage.getItem('logoutRecord') || '[]');
  const newRecord = record.slice(-9).concat({ reason, at: new Date(), ...meta });
  localStorage.setItem('logoutRecord', JSON.stringify(newRecord));
}
export function checkTokenExpired(acc: string, ref: string) {
  const accessRes = decodeJwt(acc)!;
  const refreshRes = decodeJwt(ref)!;

  const now = new Date().getTime();
  const clientDateTimeDiff = getClientDateTimeDiffFromLocalStorage();
  const clientDate = now - clientDateTimeDiff;

  const buffer = 1000 * 30;
  const shortBuffer = 1000 * 3;

  return {
    access: clientDate > accessRes.exp * 1000 - shortBuffer,
    refresh: clientDate > refreshRes.exp * 1000 - shortBuffer,
    accessBuffer: clientDate > accessRes.exp * 1000 - buffer,
    refreshBuffer: clientDate > refreshRes.exp * 1000 - buffer,
  };
}
export { getAxiosInstance };
const instance = getAxiosInstance(`${ENDPOINT_HOST}`);
export default instance;
