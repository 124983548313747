interface IProps {
  approvalsCount: number;
  approvalsRequired: number;
  applicationStatus: number;
  approvedStatus: number;
}

const getIsAutoApprove = (props: IProps) => {
  const { approvalsCount, approvalsRequired, applicationStatus, approvedStatus } = props;

  const isTxApproved = applicationStatus === approvedStatus;

  return isTxApproved && approvalsCount === 0 && approvalsRequired === 0;
};

export default getIsAutoApprove;
