import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../common/filterTable/components/Buttons/SearchButton';
import Filters from '../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../common/filterTable/layout/FilterGrid';
import { useGenFilterField } from '../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import MonthRangePicker from '../../../../components/MonthRangePicker';
import { generateLimitedYearDateTime } from '../../../../utils/dateUtils';
import TaskIdTextField from '../../../../common/filterTable/components/Filters/TaskIdTextField';
import OrderIdTextField from '../../../../common/filterTable/components/Filters/OrderIdTextField';
import ProgramNameSingleSelection from '../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import RebateStatusSingleSelection from '../../../../common/filterTable/components/Filters/RebateStatusSingleSelection';
import ToTextField from '../../../../common/filterTable/components/Filters/ToTextField';
import FromTextField from '../../../../common/filterTable/components/Filters/FromTextField';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const filterFields: ReactNode[] = useGenFilterField([
    ['creationTime', <DateObj.CreationTime.Picker type="dateTime" />],
    ['month',
      <MonthRangePicker
        startMonth={fields[FilterFieldsKeys.MonthFrom]}
        endMonth={fields[FilterFieldsKeys.MonthTo]}
        onChangeStartMonth={(value) => updateField(FilterFieldsKeys.MonthFrom, value)}
        onChangeEndMonth={(value) => updateField(FilterFieldsKeys.MonthTo, value)}
        maxMonth={lastMonth}
        minMonth={generateLimitedYearDateTime(2)}
      />
    ],
    [
      FilterFieldsKeys.TaskId,
      <TaskIdTextField
        value={fields[FilterFieldsKeys.TaskId]}
        onChange={(e) => updateField(FilterFieldsKeys.TaskId, e.target.value)}
      />
    ],
    [
      FilterFieldsKeys.OrderId,
      <OrderIdTextField
        value={fields[FilterFieldsKeys.OrderId]}
        onChange={(e) => updateField(FilterFieldsKeys.OrderId, e.target.value)}
      />,
    ],
    [
      FilterFieldsKeys.From,
      <FromTextField
        value={fields[FilterFieldsKeys.From]}
        onChange={(e) => updateField(FilterFieldsKeys.From, e.target.value)}
      />
    ],
    [
      FilterFieldsKeys.To,
      <ToTextField
        value={fields[FilterFieldsKeys.To]}
        onChange={(e) => updateField(FilterFieldsKeys.To, e.target.value)}
      />,
    ],
    [
      FilterFieldsKeys.ProgramName,
      <ProgramNameSingleSelection
        value={fields[FilterFieldsKeys.ProgramName]}
        onChange={(e) => updateField(FilterFieldsKeys.ProgramName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.ProgramName, '')}
      />,
    ],
    [FilterFieldsKeys.Status, <RebateStatusSingleSelection
      value={fields[FilterFieldsKeys.Status]}
      onChange={(e) => updateField(FilterFieldsKeys.Status, e.target.value)}
      onClear={() => updateField(FilterFieldsKeys.Status, '')}
    />]
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
