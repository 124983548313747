export enum FilterFieldsKeys {
  CreationTimeFrom = 'creationTimeFrom',
  CreationTimeTo = 'creationTimeTo',
  MonthFrom = 'monthFrom',
  MonthTo = 'monthTo',
  TaskId = 'taskId',
  OrderId = 'orderId',
  From = 'from',
  To = 'to',
  ProgramName = 'programName',
  Status = 'status',
}

export interface FilterFields {
  [FilterFieldsKeys.CreationTimeFrom]: string;
  [FilterFieldsKeys.CreationTimeTo]: string;
  [FilterFieldsKeys.MonthFrom]: string;
  [FilterFieldsKeys.MonthTo]: string;
  [FilterFieldsKeys.TaskId]: string;
  [FilterFieldsKeys.OrderId]: string;
  [FilterFieldsKeys.From]: string;
  [FilterFieldsKeys.To]: string;
  [FilterFieldsKeys.ProgramName]: string;
  [FilterFieldsKeys.Status]: string;
}
