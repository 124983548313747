export enum Device {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type Listener = () => void;

export interface MediaQueries {
  [Device.Mobile]: string;
  [Device.Tablet]: string;
  [Device.Desktop]: string;
}
