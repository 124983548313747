import { Currency } from '../../../../common/types/Currency';

export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',

  DistributorAgentId = 'distributorAgentId',
  AgentType = 'agentType',
  Role = 'role',
  Status = 'status',
  LoginEmail = 'loginEmail',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.DistributorAgentId]: string;
  [FilterFieldsKeys.AgentType]: string;
  [FilterFieldsKeys.Role]: string[];
  [FilterFieldsKeys.Status]: string;
  [FilterFieldsKeys.LoginEmail]: string;
}
