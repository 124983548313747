import { useCallback } from 'react';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import {
  EnumCustomerDocumentType,
  EnumIdNumberType
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import api from '../../../../../api';
import { OpDownload } from '../../../../../assets/icons';
import { DialogInOne, OpIconButton } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { Box } from '../../../../../components/MuiGenerals';
import {
  useAlerting,
  usePermission,
  useTranslation
} from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { toDisplayTime } from '../../../../../utils';
import { FreeObj } from '../../../../../utils/constant';
import { useZusDialogStore } from '../../../../../zustand/store';
import { uploadedDocumentsDialogTranslateKey as TK } from '../../mappings/translate';
import { BackDocument, FrontDocument } from '../../types';

export default function UploadedDocumentsDialog() {
  const translatePrefix: Iprefix = 'clientApplication';
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();

  const { applicationNumber, idType, frontDocument, backDocument, firstName, lastName } =
    (zusDialog.meta || {}) as {
      applicationNumber: string | undefined;
      idType: string | undefined;
      frontDocument: FrontDocument[] | undefined;
      backDocument: BackDocument[] | undefined;
      firstName: string | undefined;
      lastName: string | undefined;
    };

  const hasDownloadPermission = hasPermission(
    AgentPortalFeatureCode.ClientManagement.ClientApplication.DownloadDocument
  );

  const onClose = async () => {
    await zusDialog.close();
  };

  const importantStyle = (style: string | number) => style + '!important';

  const idTypeMapping = {
    SSN: 'ssn',
    SocialInsurance: 'social_insurance',
    TaxId: 'tax_id',
    IdentityCard: 'identity_card',
    DrivingLicense: 'driving_license',
    ShareCode: 'share_code',
    VoterId: 'voter_id',
    Passport: 'passport',
    Other: 'other',
  } as FreeObj;

  const getFileFormat = (documentArr: FrontDocument[] | BackDocument[] | undefined) => {
    if (documentArr) {
      const splittedFileName = documentArr[0].fileName.split('.');

      return splittedFileName[splittedFileName.length - 1];
    }
  };

  const getFileName = (direction: 'front' | 'back') => {
    const displayIdType = idType && idTypeMapping[EnumIdNumberType[Number(idType)]];

    const documentArr = direction === 'front' ? frontDocument : backDocument;

    const fileFormat = getFileFormat(documentArr);

    return direction + '_' + displayIdType + '_' + firstName + '_' + lastName + '.' + fileFormat;
  };

  const frontDocumentFileName = frontDocument && getFileName('front');

  const backDocumentFileName = backDocument && getFileName('back');

  const DownloadElement = useCallback(
    ({
      documentType,
      fileName,
      uploadTime,
    }: {
      documentType: EnumCustomerDocumentType;
      fileName: string;
      uploadTime: string;
    }) => {
      const downloadBtnFn = async () => {
        if (!applicationNumber) {
          return alerting('error', 'Internal Error');
        }

        zusDialog.openExtra('loadingDialog');

        const res: Blob = await api.clientManagement.clientApplication.getDownload(
          { applicationNumber, type: documentType },
          { responseType: 'blob' }
        );
        zusDialog.closeExtra();

        if (!res) {
          return;
        }

        const element = document.createElement('a');

        const objUrl = URL.createObjectURL(res);

        document.body.appendChild(element); // Required for this to work in FireFox

        element.href = objUrl;

        element.download = fileName;

        element.click();
        return res;
      };

      return (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ width: 'calc(100% - 12px - 1.4rem)' }}>{fileName}</Box>
            {hasDownloadPermission && (
              <OpIconButton
                sxBox={{
                  width: importantStyle('1.4rem'),
                  marginLeft: importantStyle('12px'),
                }}
                title={tc('download')}
                svgUrl={OpDownload}
                onClick={downloadBtnFn}
              />
            )}
          </Box>

          <Box sx={{ opacity: 0.4, fontSize: 12 }}>{t(TK.uploadTime, { time: uploadTime })}</Box>
        </>
      );
    },
    [applicationNumber, hasDownloadPermission]
  );

  const DownloadSection = useCallback(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '>div:nth-last-of-type(odd)': {
            marginBottom: importantStyle('8px'),
          },
          '>div:nth-last-of-type(even)': {
            marginBottom: importantStyle('2px'),
          },
          '>div:last-of-type': {
            marginBottom: importantStyle(0),
          },
        }}
      >
        {!frontDocumentFileName && !backDocumentFileName && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {tc('noRecord')}
          </Box>
        )}
        {frontDocumentFileName && (
          <DownloadElement
            documentType={EnumCustomerDocumentType.IdFront}
            fileName={frontDocumentFileName}
            uploadTime={toDisplayTime(frontDocument[0].createdDate)}
          />
        )}
        {backDocumentFileName && (
          <DownloadElement
            documentType={EnumCustomerDocumentType.IdBack}
            fileName={backDocumentFileName}
            uploadTime={toDisplayTime(backDocument[0].createdDate)}
          />
        )}
      </Box>
    );
  }, [frontDocumentFileName, backDocumentFileName]);

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.uploadedDocuments),
    self: {
      open: zusDialog.match('uploadedDocumentsDialog'),
      onClose,
    },
    content: <DownloadSection />,
    isConfirmHidden: true,
    onConfirm: () => {},
    onCancel: onClose,
    cancelButtonText: tc('close'),
    size: 'xs',
  };

  return <DialogInOne {...dialogConfig} />;
}
