import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { EnumLedgerTransactionOrderType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { IdefaultFilter } from '../..';
import SystemCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import { MpTextField, SingleSelection } from '../../../../../components';
import DaIdAutoCompleteFilter from '../../../../../components/DaIdAutoCompleteFilter';
import { useDatePicker } from '../../../../../components/DatePicker';
import FilterTemplate from '../../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import {
  useFilterFields,
  useGenFilterFields
} from '../../../../../hooks/useFilter';
import { useZusDialogStore } from '../../../../../zustand/store';
import { getTabTranslatePrefix } from '../../helper';
import { IsearchParams, useZusParams } from '../hooks';
import { filterTableTranslateKey as TK } from '../mappings/translate';

const initFields = {
  orderId: '',
  orderType: '',
  fromClientId: '',
  toClientId: '',
  distributorAgentId: '',
  currency: '',
};

export default function Filter({ defaultFilter }: { defaultFilter: IdefaultFilter | undefined }) {
  const translatePrefix = getTabTranslatePrefix('historyTab');
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const DateFields = { CreationDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    fieldWithDefaultValue: defaultFilter,
    dateFields: DateFields,
  });

  useEffect(() => {
    if (!defaultFilter) {
      return;
    }

    onSearch();
  }, [defaultFilter]);

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const { orderType, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;

    return {
      ...rest,
      orderType: orderType ? Number(orderType) : '',
      createdDateFrom,
      createdDateTo,
    };
  }, [fields, DateFields.CreationDate.start, DateFields.CreationDate.end]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },

      {
        label: t(TK.orderId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.orderId) })}
            value={fields.orderId}
            onChange={onChange('orderId')}
          />
        ),
      },
      {
        label: t(TK.orderType),
        element: (
          <SingleSelection
            label={tc('phInputField', { fieldName: t(TK.orderType) })}
            value={fields.orderType}
            onChange={onChange('orderType')}
            enumData={EnumLedgerTransactionOrderType}
            clearSelect={() => setFields((prev) => ({ ...prev, orderType: '' }))}
          />
        ),
      },

      {
        label: t(TK.from),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.from) })}
            value={fields.fromClientId}
            onChange={onChange('fromClientId')}
          />
        ),
      },
      {
        label: t(TK.to),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.to) })}
            value={fields.toClientId}
            onChange={onChange('toClientId')}
          />
        ),
      },
      {
        label: t(TK.distributorAgent),
        element: (
          <DaIdAutoCompleteFilter
            value={fields.distributorAgentId}
            onChange={(value) => setFields((f) => ({ ...f, distributorAgentId: value }))}
            onClear={() => setFields((f) => ({ ...f, distributorAgentId: '' }))}
          />
        ),
      },
      {
        label: t(TK.currency),
        element: (
          <SystemCurrencySingleSelection
            onClear={() => setFields((prev) => ({ ...prev, currency: '' }))}
            label={tc('phInputField', { fieldName: t(TK.currency) })}
            value={fields.currency}
            onChange={onChange('currency')}
            isSelectingCurrency
          />
        ),
      },
    ],
    [fields, DateFields]
  );

  return <FilterTemplate filterFields={filterFields} onSearch={onSearch} onReset={onReset} />;
}
