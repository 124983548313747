import React, { ReactNode } from 'react';
import { PALETTE } from '../../../../style/colorTheme';

const Wrapper = ({ children, style }: { children: ReactNode; style?: any }) => {
  return (
    <div
      style={{
        width: '100%',
        background: PALETTE.Primary,
        borderRadius: '12px',
        ...(style && style),
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;
