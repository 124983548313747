import React from 'react';
import { PALETTE } from '../../../../../../../style/colorTheme';
import { withHKDollarSign } from '../helpers/withHKDollarSign';

const HkdHeader = ({ value }: { value: string | number }) => {
  return (
    <div
      style={{
        color: `${PALETTE.PrimaryText}`,
        fontSize: '2rem',
        fontWeight: '700',
        marginTop: '0.8rem',
      }}
    >
      {withHKDollarSign(value)}
    </div>
  );
};

export default HkdHeader;
