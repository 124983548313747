import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import { LoginFields, LoginFieldsKeys } from '../types';

interface IProps {
  fields: LoginFields;
}

const useEmailVerificationValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('login');

  const validationConfig: ValidationConfigProps = [
    {
      key: LoginFieldsKeys.EmailVerificationCode,
      value: fields[LoginFieldsKeys.EmailVerificationCode],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('emailVerification.verificationCode'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useEmailVerificationValidation;
