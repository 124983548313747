import { EnumRebateStep } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/AgentRebate/Enum/RebateStep';
import { EnumRebateTaskStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/AgentRebate';
import { ApiResult } from '../../../../api/types';
import getDisplayApprovalProgress from '../../../../common/approval/helpers/getDisplayApprovalProgress';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey, TableRow } from '../types/Table';
import useSystemCurrency from '../../../../hooks/useSystemCurrency';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { tc, translate } = useTranslation();
  const { CurrencyDecimalsMapping } = useSystemCurrency();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const decimals = CurrencyDecimalsMapping[row.currency] || 0;
      const displayAmount = displayAmountCurrying(decimals, 2);

      const translateStep = (step: number) => {
        if (!EnumRebateStep[step]) return '';

        return translate(`EnumRebateStep.${EnumRebateStep[step]}`);
      };

      const translateStatus = (status: number) => {
        if (!EnumRebateTaskStatus[status]) return '';

        return translate(`EnumRebateTaskStatus.${EnumRebateTaskStatus[status]}`);
      };

      const approvalProgress = getDisplayApprovalProgress({
        approvalsCount: row?.approvalsCount,
        approvalsRequired: row?.approvalsRequired,
        applicationStatus: row.status,
        approvedStatus: EnumRebateTaskStatus.Approved,
        translateFunc: tc,
      });

      return {
        id: index,
        [TableColumnKey.TaskId]: row.id,
        [TableColumnKey.ApprovalProgress]: approvalProgress,
        [TableColumnKey.Month]: row.month,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.RebateCurrency]: row.rebateCurrency,
        [TableColumnKey.RebateExchangeRate]: row.rebateExchangeRate,
        [TableColumnKey.TotalDistributorAgentRebateAmount]: displayAmount(row.daRebateAmount),
        [TableColumnKey.TotalClientRebateAmount]: displayAmount(row.customerRebateAmount),
        [TableColumnKey.NetTotalDistributorAgentRebateAmount]: displayAmount(row.daNetRebateAmount),
        [TableColumnKey.Status]: translateStatus(row.status),
        [TableColumnKey.Step]: translateStep(row.step),
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
