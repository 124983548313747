import { GeneralBtn } from '../../../../components';

interface IProps {
  onSearch: () => void;
}

const SearchButton = (props: IProps) => {
  const { onSearch } = props;

  return (
    <GeneralBtn
      label="search"
      onClick={onSearch}
      rest={{
        sx: {
          background:
            'linear-gradient(144.39deg, #4F7CD8 7.57%, #63A3D5 53.85%, #7ED7D2 87.35%, #7ED7D2 95.73%)',
          color: '#FFFFFF',
        },
      }}
    />
  );
};

export default SearchButton;
