import React, { useEffect } from 'react';

import APIs from '../api';
import { PartialSearchDistributorAgentIdResponse } from '../api/types';
import DistributorAgentIdAutoComplete from '../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';

export default function DaIdAutoCompleteFilter({
  value,
  onClear,
  onChange,
}: {
  value: string;
  onClear: () => void;
  onChange: (value: string) => void;
}) {
  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = React.useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);

  const [autoCompleteKey, setAutoCompleteKey] = React.useState(0);
  const [isRerenderNeeded, setIsRerenderNeeded] = React.useState(false);

  useEffect(() => {
    if (value || !isRerenderNeeded) {
      return;
    }

    resetDistributorAgentId();
  }, [value]);

  const resetDistributorAgentId = () => {
    onClear();
    setAutoCompleteKey((prevKey) => prevKey + 1); // increment key to reset Autocomplete
  };

  const handleDistributorAgentIdInputChange = async (value: string) => {
    onChange(value);

    if (value) {
      // showLoading('searchDistributorAgentId');
      const res = await APIs.query.agent.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });
      // hideLoading('searchDistributorAgentId');

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    onChange(value);
  };

  return (
    <DistributorAgentIdAutoComplete
      key={autoCompleteKey}
      value={value}
      options={distributorAgentIdOptions}
      onInputChange={(value: string) => {
        if (!value) {
          setIsRerenderNeeded(false);
        } else {
          setIsRerenderNeeded(true);
        }

        handleDistributorAgentIdInputChange(value);
      }}
      handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
        handleDistributorAgentIdOptionChange(option?.distributorAgentId);
      }}
      setOptions={setDistributorAgentIdOptions}
    />
  );
}
