import { customSx } from '../../utils/styling';
import { GeneralBtn } from '../Button';
import { Box, Container } from '../MuiGenerals';

export default function FilterTemplate({
  filterBtnBar,
  filterFields,
  onSearch,
  onReset,
}: {
  filterBtnBar?: JSX.Element;
  filterFields: JSX.Element;
  onSearch: () => void;
  onReset: () => void;
}) {
  return (
    <Container disableGutters maxWidth={false}>
      {filterBtnBar}
      {filterFields}

      <Box sx={customSx.filterB}>
        <GeneralBtn
          label="search"
          onClick={onSearch}
          rest={{
            sx: {
              background:
                'linear-gradient(144.39deg, #4F7CD8 7.57%, #63A3D5 53.85%, #7ED7D2 87.35%, #7ED7D2 95.73%)',
              color: '#FFFFFF',
            },
          }}
        />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </Container>
  );
}
