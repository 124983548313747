export const getUrlProgramAgentId = (programAgentIdFromInput?: string) => {
  const url = window.location.href;
  // "https://pa-xxx"
  const parts = url.split('.')[0];
  // "pa-xxx"
  const programAgentIdFromUrl = parts.split('//')[1];

  const programAgentId = isUrlProgramAgentIdValid(programAgentIdFromUrl)
    ? programAgentIdFromUrl
    : programAgentIdFromInput || '';

  return programAgentId.toUpperCase();
};

export const isUrlProgramAgentIdValid = (programAgentId: string) => {
  const regex = /^pa-[a-zA-Z0-9]+$/i;

  return regex.test(programAgentId);
};
