import { useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { useAlerting } from '../../hooks';

export default function NoPermissionElement() {
  const { alerting } = useAlerting();
  const translate = useTranslate();
  useEffect(() => alerting('error', translate('common.alert_no_permission')), []);

  return <></>;
}
