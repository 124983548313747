import React from 'react';

import { Button } from '../../../components/MuiGenerals';
import { useTranslation } from '../../../hooks';

interface IProps {
  onClick: () => void;
}

const ApproveButton = ({ onClick }: IProps) => {
  const { tc } = useTranslation();

  return (
    <Button
      className={'inheritColor'}
      color={'primary'}
      variant={'contained'}
      children={tc('approve_btn')}
      onClick={onClick}
      style={{
        width: '160px',
        height: '38px',
        marginRight: '12px',
        backgroundColor: '#5183FF',
        color: '#FFFFFF',
        borderRadius: '8px',
      }}
    />
  );
};

export default ApproveButton;
