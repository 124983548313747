import React from 'react';
import { Button } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  onClick: (e: any) => void;
}

const ConfirmButton = (props: IProps) => {
  const { onClick } = props;

  const { tc } = useTranslation();

  return (
    <Button
      className={'inheritColor'}
      color={'primary'}
      variant={'contained'}
      children={tc('confirm')}
      onClick={onClick}
    />
  );
};

export default ConfirmButton;
