export enum TableColumnKey {
  SettlementDate = 'settlementDate',
  DistributorAgentId = 'distributorAgentId',
  ProgramName = 'programName',
  Currency = 'currency',
  Purchase = 'purchase',
  Refund = 'refund',
  AccountFunding = 'accountFunding',
  Repayment = 'repayment',
  FundingAdjustment = 'fundingAdjustment',
  WaivedLateCharge = 'waivedLateCharge',
  WaivedInterest = 'waivedInterest',
  Reward = 'reward',
  Withdraw = 'withdraw',
  ChargeBack = 'chargeback',
  Transfer = 'transfer',
  RepaymentAdjustment = 'repaymentAdjustment',
  VipServiceFee = 'vipServiceFee',
  Withholding = 'withholding',
  Interest = 'interest',
  Cashback = 'cashback',
  Fee = 'fee',
  FeeAdjustment = 'feeAdjustment',
  WithdrawFeeAdjustment = 'withdrawFeeAdjustment',
  AgentMarkupWithdrawFeeAdjustment = 'agentMarkupWithdrawFeeAdjustment',
  OverseaFeeAdjustment = 'overseaFeeAdjustment',
  AgentMarkupOverseaFeeAdjustment = 'agentMarkupOverseaFeeAdjustment',
  UkFeeAdjustment = 'ukFeeAdjustment',
  E6Fee = 'e6Fee',
  AnnualFee = 'annualFee',
  FastFund = 'fastFund',
  LoadReversal = 'loadReversal',
  LatePaymentFee = 'latePaymentFee',
  Others = 'others',
  TotalIn = 'totalIn',
  TotalOut = 'totalOut',
  TotalRebateTransactionAmount = 'totalRebateTransactionAmount',
  TotalVisaFee = 'totalVisaFee',
  TotalSwapCost = 'totalSwapCost',
  TotalAgentRebate = 'totalAgentRebate',
  TotalSystemCost = 'totalSystemCost',
  TotalProfit = 'totalProfit',
}

export interface TableRow {
  id: number;
  [TableColumnKey.SettlementDate]: string;
  [TableColumnKey.DistributorAgentId]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.Currency]: string;
  [TableColumnKey.Purchase]: string;
  [TableColumnKey.Refund]: string;
  [TableColumnKey.AccountFunding]: string;
  [TableColumnKey.Repayment]: string;
  [TableColumnKey.FundingAdjustment]: string;
  [TableColumnKey.WaivedLateCharge]: string;
  [TableColumnKey.WaivedInterest]: string;
  [TableColumnKey.Reward]: string;
  [TableColumnKey.Withdraw]: string;
  [TableColumnKey.ChargeBack]: string;
  [TableColumnKey.Transfer]: string;
  [TableColumnKey.RepaymentAdjustment]: string;
  [TableColumnKey.VipServiceFee]: string;
  [TableColumnKey.Withholding]: string;
  [TableColumnKey.Interest]: string;
  [TableColumnKey.Cashback]: string;
  [TableColumnKey.Fee]: string;
  [TableColumnKey.FeeAdjustment]: string;
  [TableColumnKey.WithdrawFeeAdjustment]: string;
  [TableColumnKey.AgentMarkupWithdrawFeeAdjustment]: string;
  [TableColumnKey.OverseaFeeAdjustment]: string;
  [TableColumnKey.AgentMarkupOverseaFeeAdjustment]: string;
  [TableColumnKey.UkFeeAdjustment]: string;
  [TableColumnKey.E6Fee]: string;
  [TableColumnKey.AnnualFee]: string;
  [TableColumnKey.FastFund]: string;
  [TableColumnKey.LoadReversal]: string;
  [TableColumnKey.LatePaymentFee]: string;
  [TableColumnKey.Others]: string;
  [TableColumnKey.TotalIn]: string;
  [TableColumnKey.TotalOut]: string;
  [TableColumnKey.TotalRebateTransactionAmount]: string;
  [TableColumnKey.TotalVisaFee]: string;
  [TableColumnKey.TotalSwapCost]: string;
  [TableColumnKey.TotalAgentRebate]: string;
  [TableColumnKey.TotalSystemCost]: string;
  [TableColumnKey.TotalProfit]: string;
  rawData: any;
}
