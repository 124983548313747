import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface SystemCurrency {
  chainCode: string;
  currency: string;
  displayName: string;
  tokenName: string;
  wmChainType: string;
  wmChainId: string;
  wmAssetName: string;
  decimals: number;
  networkDisplayName: string;
}

interface InitState {
  list: SystemCurrency[];
}

const initialState: InitState = { list: [] };

export const systemCurrency = createSlice({
  name: 'systemCurrency',
  initialState,
  reducers: {
    setSystemCurrencyList: (state, action: PayloadAction<SystemCurrency[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setSystemCurrencyList } = systemCurrency.actions;

export const selectSystemCurrencyList = (state: RootState) => state.systemCurrency;

export default systemCurrency.reducer;
