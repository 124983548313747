import clientManagement from './clientManagement';
import dashboard from './dashboard';
import distributorAgentManagement from './distributorAgentManagement';
import merchantPortal from './merchantPortal';
import programAgentManagement from './programAgentManagement';
import query from './query';
import rebate from './rebate';
import report from './report';
import setting from './setting';

const APIs = {
  merchantPortal,
  programAgentManagement,
  distributorAgentManagement,
  query,
  dashboard,
  clientManagement,
  rebate,
  report,
  setting,
};

export default APIs;
