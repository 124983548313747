import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const getCipherKey = () => {
  return process.env.REACT_APP_CIPHER_KEY || '';
};

export function encrypt(message: string) {
  const cipherKey = getCipherKey();

  try {
    const cipherText = AES.encrypt(message, cipherKey).toString();

    return cipherText;
  } catch (error) {
    console.error('Error while encrypting:', error);

    return '';
  }
}

export function decrypt(encryptedMessage: string) {
  const cipherKey = getCipherKey();

  try {
    const bytes = AES.decrypt(encryptedMessage, cipherKey);

    // When CIPHER_KEY is changed, bytes will be empty or lead to error
    if (!bytes) throw Error('Decryption failed, bytes is empty');

    return bytes.toString(Utf8);
  } catch (error) {
    console.error('Error while decrypting:', error);

    return '';
  }
}
