import { useAppSelector } from '../reducer/hooks';
import { selectProgram } from '../reducer/programSlice';

const useProgramDisplayName = () => {
  const programs = useAppSelector(selectProgram);
  const { rawAllPrograms } = programs;

  const getProgramDisplayName = (programName: string) => {
    if (!rawAllPrograms || rawAllPrograms.length === 0) return '';

    return (
      rawAllPrograms.find((program) => program.programName === programName)?.programDisplayName ||
      programName
    );
  };

  return {
    getProgramDisplayName,
  };
};

export default useProgramDisplayName;
