import BigNumber from 'bignumber.js';
import { IOverviewResponse } from '../../../../../api/types';

export function calculateTotalAmount(data: IOverviewResponse['walletBalances'] | null | undefined) {
  if (!data) return '0';

  const totalAmount = data?.reduce((acc, item) => {
    const amount = new BigNumber(item?.balance || 0);
    const decimals = item?.decimals || 0;
    return acc.plus(amount.dividedBy(Math.pow(10, decimals)));
  }, new BigNumber(0));

  return totalAmount?.toFormat() || '0';
}
