import { PALETTE } from '../../../../../style/colorTheme';

const Title = ({ value }: { value?: string }) => {
  return (
    <>
      <div style={{ color: PALETTE.SecondaryText, fontSize: '14px', padding: '24px 32px' }}>
        {value}
      </div>
      <div style={{ width: '100%', backgroundColor: '#485370', height: '1px' }} />
    </>
  );
};

export default Title;
