import { useState } from 'react';

import { useDatePicker } from '../../../../components/DatePicker';
import { usePermission, useTabs } from '../../../../hooks';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import useExport from './helpers/useExport';
import useSyncDatePicker from './helpers/useSyncDatePrikcer';
import useTableQuery from './helpers/useTableQuery';
import useUpdateTranslatePrefix from './helpers/useUpdateTranslatePrefix';
import { useZusTranslatePrefixStore } from '../../../../zustand/store';

interface IProps<FilterFieldsType> {
  initFields: FilterFieldsType;
  translatePrefix: Iprefix;
  DateObj: {
    CreationTime?: ReturnType<typeof useDatePicker>;
    LastModifiedTime?: ReturnType<typeof useDatePicker>;
    [key: string]: any;
  };
  getListParams: (fields: FilterFieldsType) => any;
  getTableDataApiFunc: (params: any) => any;
  exportTableApiFunc: (params: any, { signal }: { signal: any }) => any;
}

const useNewFilterTablePage = <FilterFieldsType, ApiResultListType>(
  props: IProps<FilterFieldsType>
) => {
  const {
    initFields,
    translatePrefix,
    DateObj,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  } = props;

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFieldsType>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResultListType>({ rows: [], count: 0 } as ApiResultListType);

  const { hasPermission } = usePermission();
  const { tc } = useTranslation(translatePrefix);
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  // Export Function
  const { onExport } = useExport<ApiResultListType>({
    fields,
    getListParams,
    exportTableApiFunc,
  });

  // Update Translate Prefix
  useUpdateTranslatePrefix(translatePrefix);

  // Sync Date Picker
  useSyncDatePicker<FilterFieldsType>({ DateObj, setFields });

  // API Query for table
  useTableQuery({
    listParams,
    setList,
    getTableDataApiFunc,
    pageId: translatePrefix,
  });

  // Pagination + Filter Functions
  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    const filterFieldsData = getListParams(fields);

    setPage(0);
    setListParams({
      ...filterFieldsData,
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime && DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime && DateObj.LastModifiedTime.clearDate();
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  // helper for permission checking
  const getIsHavePermission = (permission: string) => {
    return hasPermission(permission);
  };

  return {
    page,
    list,
    fields,
    setFields,
    onPageChange,
    onFilterSearch,
    onFilterReset,
    refreshTable,
    onExport,
    getIsHavePermission,
    Tabs,
  };
};

export default useNewFilterTablePage;
