import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import { enumLanguage } from '../utils/constant';
import axios from './axiosInstance';
import { Itimezone } from './types';

export async function userProfile(config?: any) {
  return await axios.get<
    never,
    null | {
      id: string;
      date_created: string;
      date_last_modified: string;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      merchant_id: string;
    }
  >(`/merchant_portal/portal_user_profiles/me`, config);
}
export async function saveProfile(
  params: { lang: keyof typeof enumLanguage; timezone?: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<
    never,
    {
      id: string;
      date_created: string;
      date_last_modified: string;
      timezone: Itimezone;
      lang: keyof typeof enumLanguage;
      email: string;
      merchant_id: string;
    }
  >(`/merchant_portal/portal_user_profiles/upsert`, input);
}

export async function changePassword(
  params: { oldPassword: string; password: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, null>(AgentPortal.EPtAuth.auth.changePassword, input);
}
