import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Device } from '../hooks/useMedia/types';
import { RootState } from './store';

interface MediaState {
  matches: Record<Device, boolean>;
  size: { width: number; height: number };
}

const initialState: MediaState = {
  matches: {
    mobile: false,
    tablet: false,
    desktop: true,
  },
  size: { width: window.innerWidth, height: window.innerHeight },
};

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    updateMatches: (state, action: PayloadAction<{ matches: Record<Device, boolean> }>) => {
      state.matches = action.payload.matches;
    },
    updateSize: (state, action: PayloadAction<{ size: { width: number; height: number } }>) => {
      state.size = action.payload.size;
    },
  },
});

export const selectIsMobileView = (state: RootState) => state.media.matches.mobile;

export const selectViewportSize = (state: RootState) => state.media.size;

export const { updateMatches, updateSize } = mediaSlice.actions;

export default mediaSlice.reducer;
