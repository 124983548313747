import React, { useEffect } from 'react';
import DashboardPanel from '../../../../components/Dashboard/DashboardPanel';
import DashboardGrid from '../../../../components/Dashboard/DashboardGrid';
import { useTranslation } from '../../../../hooks';
import CountContent from '../../../../components/Dashboard/DashboardContent/CountContent';
import { IOverviewResponse } from '../../../../api/types';
import { displayAmountCurrying } from '../../../../utils';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';

interface IDistributionOverviewProps {
  data: IOverviewResponse | null;
  isLoading: boolean;
}

const DistributionOverview: React.FC<IDistributionOverviewProps> = ({ data, isLoading }) => {
  const { t } = useTranslation('dashboard');

  const isMobile = useAppSelector(selectIsMobileView);

  const CARD_HEIGHT = isMobile ? 160 : 200;

  return (
    <DashboardPanel title={t('distributionOverview')} spacing={2}>
      <DashboardGrid
        xs={8}
        height={CARD_HEIGHT}
        isNoRecord={
          !isLoading && (!data?.daTotal || data?.daTotal === null || data?.daTotal === undefined)
        }
        isLoading={isLoading}
        title={t('totalNoOfDistributorAgent')}
        content={<CountContent count={displayAmountCurrying(0, 0)(data?.daTotal || '0')} />}
        md={6}
      />
      <DashboardGrid
        xs={8}
        height={CARD_HEIGHT}
        isNoRecord={
          !isLoading && (data?.activeDaTotal === null || data?.activeDaTotal === undefined)
        }
        isLoading={isLoading}
        title={t('totalNoOfActiveAgent')}
        content={
          <CountContent
            label={t('totalNoOfActiveAgentSubtitle')}
            count={displayAmountCurrying(0, 0)(data?.activeDaTotal || '0')}
          />
        }
        md={6}
      />
      <DashboardGrid
        xs={8}
        height={CARD_HEIGHT}
        isNoRecord={
          !isLoading && (data?.customerTotal === null || data?.customerTotal === undefined)
        }
        isLoading={isLoading}
        title={t('totalNoOfClient')}
        content={<CountContent count={displayAmountCurrying(0, 0)(data?.customerTotal || '0')} />}
        md={6}
      />
      <DashboardGrid
        xs={8}
        height={CARD_HEIGHT}
        isNoRecord={
          !isLoading &&
          (data?.activeCustomerTotal === null || data?.activeCustomerTotal === undefined)
        }
        isLoading={isLoading}
        title={t('totalNoOfActiveClient')}
        content={
          <CountContent
            label={t('totalNoOfActiveClientSubtitle')}
            count={displayAmountCurrying(0, 0)(data?.activeCustomerTotal || '0')}
          />
        }
        md={6}
      />
    </DashboardPanel>
  );
};

export default DistributionOverview;
