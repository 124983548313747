import { cond } from 'lodash';
import { useTranslate } from 'react-admin';

import { GridColDef } from '@mui/x-data-grid';

import { Tooltip } from '../components/MuiGenerals';
import { COLOR_THEME } from '../style/colorTheme';
import { copyToClipboard } from '../utils';
import { tableConfig } from '../utils/constant';
import { useZusTranslatePrefixStore } from '../zustand/store';
import useAlerting from './useAlerting';
import useTranslation from './useTranslation';

const sx = {
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copyableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: COLOR_THEME.CustomSx.ComponentHelper.AddressCell.Text,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export function useGenTableColumns(
  columnConfigs: ({ translateKey: string } & Partial<GridColDef<any, any, any>>)[]
) {
  const translate = useTranslate();
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const isDateField = (field: string) => tableConfig.dateFields.includes(field);
  const defaultCase = () => true;
  const isCopyable = (field: string) => tableConfig.copyableFields.includes(field);
  const isFreeTextField = (field: string) => tableConfig.freeTextFields.includes(field);
  const renderCell = (params: any) => {
    const { value = '', field = '' } = params;
    const isBoolean = (v: any) => typeof v === 'boolean';
    const content = cond([
      [(v: any) => v === '0', () => '0'],
      [(v: any) => v === 0, () => 0],
      [(v: any) => !v, () => ''],
      [isBoolean, (v: any) => (v ? 'yes' : 'no')],
      [defaultCase, (v: any) => v],
    ])(value);
    return (
      <Tooltip title={content}>
        <span
          style={isCopyable(field) ? sx.copyableCell : sx.cell}
          className="csutable-cell-trucate"
          onClick={(e) => cellOnClick(e, field, value)}
        >
          {content}
        </span>
      </Tooltip>
    );
  };

  const cellOnClick = (e: any, field: string, value: string) => {
    if (isCopyable(field)) {
      copyToClipboard(value);
      alerting('success', translate(`common.copied`));
    }
  };

  const columns: GridColDef[] = columnConfigs.map(({ translateKey, ...rest }) => {
    const minWidth = cond([
      [isCopyable, () => tableConfig.addressWidth],
      [isDateField, () => tableConfig.dateWidth],
      [isFreeTextField, () => tableConfig.freeTextWidth],
      [defaultCase, () => tableConfig.defaultWidth],
    ])(translateKey);

    return {
      field: translateKey,
      headerName: t(translateKey),
      flex: 1,
      minWidth,
      renderCell,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      ...rest,
    };
  });

  return { columns };
}
