import React from 'react';
import { Checkbox } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TrustDeviceCheckbox = (props: IProps) => {
  const { value, onChange } = props;

  const { t } = useTranslation('login');

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Checkbox checked={value} onChange={onChange} inputProps={{ 'aria-label': 'trustDevice' }} />
      <div>{t('enterPasswordWithVerify.trustDevice')}</div>
    </div>
  );
};

export default TrustDeviceCheckbox;
