import React from "react"

import { SingleSelection } from "../../Selection";
import DashboardHeader from "../DashboardGrid/components/DashboardHeader";
import { Box, SelectChangeEvent } from "../../MuiGenerals";

interface IFilterHeaderProps {
    title: string;
    filterList: Record<string, any>;
    value: string;
    label: string;
    onChange: (e: SelectChangeEvent<string>) => void
    nameFn: (str: string) => string;
}

const FilterHeader: React.FC<IFilterHeaderProps> = ({ title, filterList, value, onChange, label, nameFn }) => {
    return (
        <DashboardHeader title={title}
            customComponent={
                <Box sx={{ mr: 2 }}>
                    <SingleSelection
                        onChange={onChange}
                        value={value}
                        label={label}
                        enumData={filterList}
                        isNoSorting={true}
                        clearSelect={() => { }}
                        nameFn={nameFn}
                    />
                </Box>
            }
        />
    )
}

export default FilterHeader
