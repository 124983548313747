import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface Role {
  roleId: number;
  roleName: string;
}

interface InitState {
  list: Role[];
}

const initialState: InitState = { list: [] };

export const agentRolesSlice = createSlice({
  name: 'agentRoles',
  initialState,
  reducers: {
    setAgentRolesList: (state, action: PayloadAction<Role[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setAgentRolesList } = agentRolesSlice.actions;

export const selectAgentRolesList = (state: RootState) => state.agentsRoles;

export default agentRolesSlice.reducer;
