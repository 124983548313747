export enum TableColumnKey {
  Month = 'month',
  ProgramName = 'programName',
  Repayment = 'repayment',
  TotalTransactionAmount = 'totalTransactionAmount',
  Payment = 'payment',
  PaMarkup = 'paMarkup',
  AnnualFee = 'annualFee',
  VisaRebate = 'visaRebate',
  PaRebate = 'paRebate',
}

export interface TableRow {
  id: number;
  [TableColumnKey.Month]: string;
  [TableColumnKey.ProgramName]: string;
  [TableColumnKey.Repayment]: string;
  [TableColumnKey.TotalTransactionAmount]: string;
  [TableColumnKey.Payment]: string;
  [TableColumnKey.PaMarkup]: string;
  [TableColumnKey.AnnualFee]: string;
  [TableColumnKey.VisaRebate]: string;
  [TableColumnKey.PaRebate]: string;
  rawData: any;
}
