import { useTranslate } from "react-admin";

import Stack from "@mui/material/Stack";

import { COLOR_THEME } from "../../style/colorTheme";

export default function SearchFirstDataGridOverlay() {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: COLOR_THEME.CustomSx.SearchFirstDataGridOverlay.Background,
        color: COLOR_THEME.CustomSx.SearchFirstDataGridOverlay.Text,
      }}
    >
      {t("clickSearchToViewRecords")}
    </Stack>
  );
}
