import { defaultListParams } from '../../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../../common/filterTable/helpers/cleanParams';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const profile = useAppSelector(selectProfile);

  const getListParams = (fields: FilterFields) => {
    const { distributorAgentId, currency } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      currency: currency.currency,
      distributorAgentId: distributorAgentId,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
