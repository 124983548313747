import en from '../components/i18nProvider/en';
import zh_cn from '../components/i18nProvider/zh_cn';
import { getEnumTranslationEntries } from '../helper/getEnumTranslationEntries';
import { useAppSelector } from '../reducer/hooks';
import { selectProfile } from '../reducer/profileSlice';
import { useZusTranslatePrefixStore } from '../zustand/store';
import useTranslation from './useTranslation';

const translateMapping = {
  en,
  zh_cn,
};

export default function useListMappingTransform(mode: 'table' | 'export') {
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t } = useTranslation(translatePrefix);
  const { lang: selectedLang } = useAppSelector(selectProfile);
  const listMappingTransform = (item: any, index: number) => {
    const initValue = mode === 'table' ? { id: index + 1 } : {};

    const reduceFn = (acc: any, [key, value]: [keyof (typeof en)[typeof translatePrefix], any]) => {
      let exportKey = '';

      let translationObj = en as any;

      const translatePrefixArr = translatePrefix.split('.');

      if (translatePrefixArr.length > 1) {
        for (const translatePrefix of translatePrefixArr) {
          translationObj = translationObj[translatePrefix];
        }
        exportKey = translationObj[key];
      } else {
        exportKey = en[translatePrefix][key as keyof (typeof en)[typeof translatePrefix]];
      }

      const newObjKey = mode === 'table' ? key : exportKey;

      let resultVal;

      resultVal = value;

      if (mode === 'export' && !/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(value)) {
        const selectedLangEnumMappingsArr = getEnumTranslationEntries(
          translateMapping[selectedLang]
        );
        const englishEnumMappingsArr = getEnumTranslationEntries(en);

        for (const [selectedLangKey, selectedLangVal] of selectedLangEnumMappingsArr) {
          if (selectedLangVal === value) {
            resultVal = englishEnumMappingsArr.find(([engKey]) => selectedLangKey === engKey)?.[1];
            break;
          }
        }
      }

      return { ...acc, [newObjKey]: resultVal };
    };
    return item.reduce(reduceFn, initValue);
  };
  return listMappingTransform;
}
