import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../reducer/hooks';
import { updateMatches, updateSize } from '../../reducer/mediaSlice';
import { Device, Listener, MediaQueries } from './types';

const INIT_WINDOW_SIZE = { width: window.innerWidth, height: window.innerHeight };

export const MEDIA_QUERIES: MediaQueries = {
  [Device.Mobile]: '(max-width: 999px)',
  [Device.Tablet]: '(min-width: 1000px) and (max-width: 1024px)',
  [Device.Desktop]: '(min-width: 1025px)',
};

const createInitMatches = (queries: MediaQueries) => {
  const initMatches = Object.keys(queries).reduce(
    (acc: Record<Device, boolean>, device: string) => {
      acc[device as Device] = window.matchMedia(queries[device as Device]).matches;
      return acc;
    },
    {} as Record<Device, boolean>
  );

  return initMatches;
};

const createMediaQueryList = (queries: MediaQueries): MediaQueryList[] => {
  const deviceKeys = Object.keys(queries);

  return deviceKeys.map((device: string) => {
    const deviceMediaQuery = queries[device as Device];

    return window.matchMedia(deviceMediaQuery);
  });
};

const useMedia = () => {
  const [matches, setMatches] = useState(createInitMatches(MEDIA_QUERIES));

  const [size, setSize] = useState(INIT_WINDOW_SIZE);

  const dispatch = useAppDispatch();

  const createMediaChangeListeners = (mediaQueryLists: MediaQueryList[]): Listener[] => {
    return mediaQueryLists.map((mql, index) => {
      return () => {
        setMatches((prev) => ({ ...prev, [Object.keys(MEDIA_QUERIES)[index]]: mql.matches }));
      };
    });
  };

  const createWindowResizeListener = (): Listener => {
    return () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };
  };

  const appendMediaQueryListeners = (mediaQueryLists: MediaQueryList[], listeners: Listener[]) => {
    mediaQueryLists.forEach((mql, index) => {
      mql.addEventListener('change', listeners[index]);
    });
  };

  const appendWindowResizeListener = (listener: Listener) => {
    window.addEventListener('resize', listener);
  };

  useEffect(() => {
    const mediaQueryLists = createMediaQueryList(MEDIA_QUERIES);

    const mediaChangeListeners = createMediaChangeListeners(mediaQueryLists);

    const windowResizeListener = createWindowResizeListener();

    appendMediaQueryListeners(mediaQueryLists, mediaChangeListeners);
    appendWindowResizeListener(windowResizeListener);

    return () => {
      mediaQueryLists.forEach((mql, index) => {
        mql.removeEventListener('change', mediaChangeListeners[index]);
      });

      window.removeEventListener('resize', windowResizeListener);
    };
  }, []);

  useEffect(() => {
    dispatch(updateMatches({ matches }));
    dispatch(updateSize({ size }));
  }, [matches, size]);

  return { matches, size };
};

export default useMedia;
