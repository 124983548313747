import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

interface GetFeeAdjustmentRateReq {
  programName: string;
  transactionType: number;
}

const getFeeAdjustmentRate = ({ programName, transactionType }: GetFeeAdjustmentRateReq) => {
  return axios.get<never, any>(AgentPortal.EPtPaManagement.feeAdjustmentRate.getAll, {
    params: {
      programName,
      transactionType,
    },
  });
};

export default { getFeeAdjustmentRate };
