import { useQuery } from 'react-query';

import APIs from '../../api';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import {
  selectSystemCurrencyList,
  setSystemCurrencyList
} from '../../reducer/systemCurrencySlice';
import useLoading from '../useLoading';

interface IProps {
  shouldFetch?: boolean;
  tokenNameFilter?: string;
}

const useSystemCurrency = (props?: IProps) => {
  const { shouldFetch, tokenNameFilter } = props || {};

  const dispatch = useAppDispatch();
  const { list: systemCurrencyList } = useAppSelector(selectSystemCurrencyList);
  const { showLoading, hideLoading } = useLoading();

  const getSystemCurrencyEnum = () => {
    if (!systemCurrencyList) return {};

    return systemCurrencyList.reduce((acc: any, curr) => {
      const isExisted = acc[curr.currency];

      if (isExisted) return acc;

      if (tokenNameFilter) {
        if (curr.tokenName === tokenNameFilter) {
          return {
            ...acc,
            [curr.currency]: JSON.stringify(curr),
          };
        } else {
          return acc;
        }
      }

      return {
        ...acc,
        [curr.currency]: JSON.stringify(curr),
      };
    }, {});
  };

  const fetchSystemCurrency = async () => {
    showLoading('getSystemCurrency');
    const res = await APIs.programAgentManagement.programAgentDeposit.getProgramAssetList();
    hideLoading('getSystemCurrency');

    if (!res) return [];

    return res;
  };

  useQuery('systemCurrencyList', fetchSystemCurrency, {
    enabled: shouldFetch && (!systemCurrencyList || systemCurrencyList.length === 0),
    staleTime: Infinity,
    onSuccess: (data) => {
      dispatch(setSystemCurrencyList(data));
    },
  });

  const SystemCurrencyEnum = getSystemCurrencyEnum();

  const CurrencyDecimalsMapping = systemCurrencyList.reduce(
    (acc: Record<string, number>, { currency, decimals, tokenName }) => {
      if (acc[currency]) {
        return acc;
      }

      if (tokenNameFilter) {
        if (tokenName === tokenNameFilter) {
          return {
            ...acc,
            [currency]: decimals,
          };
        } else {
          return acc;
        }
      }

      return { ...acc, [currency]: decimals };
    },
    {}
  );

  return { systemCurrencyList, SystemCurrencyEnum, CurrencyDecimalsMapping };
};

export default useSystemCurrency;
