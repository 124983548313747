import flag from 'country-code-emoji';
import { isNil } from 'lodash';
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import { SxProps } from '@mui/system';
import { GridSearchIcon } from '@mui/x-data-grid';

import COUNTRY_CODE_LIST from '../../components/i18nProvider/countryCodeList.json';
import { PALETTE } from '../../style/colorTheme';
import { importantStyle } from '../../utils';
import { useZusMenuCollapsedStore } from '../../zustand/store';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../MuiGenerals';
import { MpTextField } from '../TextField';

const IMPORTANT_COUNTRIES = ['CN', 'US', 'JP', 'IN', 'ID', 'BR', 'TW', 'GB'] as const;

const SearchField = memo(
  ({
    setDisplayCountryCodes,
    setSearchValue,
  }: {
    setDisplayCountryCodes: Dispatch<SetStateAction<typeof COUNTRY_CODE_LIST>>;
    setSearchValue: Dispatch<SetStateAction<string>>;
  }) => {
    return (
      <ListSubheader sx={{ ':hover': { backgroundColor: importantStyle(PALETTE.Secondary) } }}>
        <MpTextField
          id={'search-country-code-field'}
          focused
          autoFocus
          placeholder={'Search'}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start" children={<GridSearchIcon />} />,
          }}
          onChange={(e) => {
            const input = e.target.value;

            setSearchValue(input);

            setDisplayCountryCodes(
              COUNTRY_CODE_LIST.filter(({ country, countryCodes, isoCode2, isoCode3 }) => {
                const displayCountryCode = countryCodes[0].split('-')[0];

                const isMatchingCountry = country.toLowerCase().includes(input.toLowerCase());

                const isMatchingCountryCodes =
                  input === displayCountryCode.substring(0, input.length) ||
                  input === '+' + displayCountryCode.substring(0, input.length - 1);

                const isMatchingIsoCode2 =
                  input.toLowerCase() === isoCode2.substring(0, input.length).toLowerCase();

                const isMatchingIsoCode3 =
                  input.toLowerCase() === isoCode3.substring(0, input.length).toLowerCase();

                return (
                  isMatchingCountry ||
                  isMatchingCountryCodes ||
                  isMatchingIsoCode2 ||
                  isMatchingIsoCode3
                );
              })
            );
          }}
        />
      </ListSubheader>
    );
  }
);

export default function PhoneCodeSelection(p: {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  onOpen?: () => void;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  sx?: { formControl?: SxProps; select?: SxProps };
  isNoSorting?: boolean;
  disabled?: boolean;
  disabledItemList?: string[];
}) {
  const selectionRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const sx = {
    formControl: {
      width: '100%',
      ...p.sx?.formControl,
    },
    select: p.sx?.select,
  };

  const [displayCountryCodes, setDisplayCountryCodes] = useState(COUNTRY_CODE_LIST);
  const [searchValue, setSearchValue] = useState('');
  const [menuLeftPosition, setMenuLeftPosition] = useState<string>();

  const zusMenuCollapsed = useZusMenuCollapsedStore();
  const timeoutId = useRef<NodeJS.Timeout>();

  const searchFieldMemo = useMemo(
    () => (
      <SearchField
        setDisplayCountryCodes={setDisplayCountryCodes}
        setSearchValue={setSearchValue}
      />
    ),
    []
  );

  useEffect(() => {
    const optionsMenuElement = menuRef.current?.querySelector('.MuiMenu-paper');

    optionsMenuElement?.scrollTo({ top: 0 });
  }, [searchValue]);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      const getMenuLeftPosition = () => {
        const selectionInputFieldXCoordinate = selectionRef.current?.getBoundingClientRect().left;

        if (isNil(selectionInputFieldXCoordinate)) {
          return selectionInputFieldXCoordinate;
        }

        return importantStyle(selectionInputFieldXCoordinate + 'px');
      };

      const newMenuLeftPosition = getMenuLeftPosition();

      setMenuLeftPosition(newMenuLeftPosition);
    }, 300);
  }, [zusMenuCollapsed.isMenuCollapsed]);

  return (
    <FormControl sx={sx.formControl} disabled={p.disabled}>
      {p.label && <InputLabel>{p.label}</InputLabel>}
      <Select
        autoFocus={false}
        ref={selectionRef}
        onClose={() => {
          const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
          setTimeout(() => {
            focusedDivElement.blur();
            setDisplayCountryCodes(COUNTRY_CODE_LIST);
            setSearchValue('');
          }, 150);
        }}
        name={p.label}
        value={p.value}
        onChange={p.onChange}
        onOpen={p.onOpen}
        sx={sx.select}
        MenuProps={{
          sx: {
            height: '320px',
            '.MuiMenu-paper': {
              width: '360px',
              left: menuLeftPosition,
            },
            ul: { padding: 0 },
          },
          ref: menuRef,
          disableScrollLock: true,
        }}
        renderValue={(v) => v}
      >
        {searchFieldMemo}

        {IMPORTANT_COUNTRIES.map((code) => {
          const foundCountry = COUNTRY_CODE_LIST.find(({ isoCode2 }) => isoCode2 === code);

          const displayPhoneCountryCode = `+${foundCountry?.countryCodes[0].split('-')[0]}`;

          return (
            !searchValue && (
              <MenuItem autoFocus={false} key={`top-${code}`} value={displayPhoneCountryCode}>
                <Box sx={{ fontSize: '1.2rem', marginRight: '8px' }}>{flag(code)}</Box>
                <Box sx={{ marginRight: '8px' }}>{displayPhoneCountryCode}</Box>
                <Box>{foundCountry?.country}</Box>
              </MenuItem>
            )
          );
        })}

        {!searchValue && (
          <Box
            sx={{
              height: '1px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                height: '1px',
                background: PALETTE.SecondaryText,
                marginLeft: '16px',
                marginRight: '16px',
              }}
            />
          </Box>
        )}

        {COUNTRY_CODE_LIST.map(({ countryCodes, isoCode2, country }) => {
          const displayPhoneCountryCode = `+${countryCodes[0].split('-')[0]}`; // To remove subfix
          return (
            <MenuItem
              autoFocus={false}
              key={`bottom-${isoCode2}`}
              value={displayPhoneCountryCode}
              sx={{
                display: displayCountryCodes.find((display) => isoCode2 === display.isoCode2)
                  ? undefined
                  : 'none',
              }}
            >
              <Box sx={{ fontSize: '1.2rem', marginRight: '8px' }}>{flag(isoCode2)}</Box>
              <Box sx={{ marginRight: '8px' }}>{displayPhoneCountryCode}</Box>
              <Box>{country}</Box>
            </MenuItem>
          );
        })}

        {!displayCountryCodes.length && (
          <MenuItem disabled style={{ opacity: 1, fontSize: '0.8rem', height: '44px' }}>
            No Matching
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
