import APIs from '../../../../api';
import { convertDateOrDatetimeToDbFormat } from '../../../../helper';

interface IProps {
  fromCurrency: string;
  toCurrency: string;
  currentExchangeRate: string;
  nextExchangeRate?: string;
  nextExchangeRateTime?: Date | null;
  isInfinity: boolean;
}

export const handleExchangeRateUpdate = async (props: IProps) => {
  const {
    fromCurrency,
    toCurrency,
    currentExchangeRate,
    nextExchangeRate,
    nextExchangeRateTime = null,
    isInfinity,
  } = props;

  const startFromDbFormat = convertDateOrDatetimeToDbFormat(nextExchangeRateTime, 'datetime', {
    isTimezoneConvert: true,
  });
  const payload = {
    fromCurrency,
    toCurrency,
    currentExchangeRate,
    nextExchangeRate,
    nextExchangeRateTime: startFromDbFormat,
    currentExchangeRateInfinity: isInfinity,
  };

  const res = await APIs.setting.exchangeRate.createOrEditExchangeRate(payload);

  return res;
};
