const commonTranslateKey = {
  distributorName: 'distributor_name',
  distributorAgentId: 'distributor_agent_id',
  programName: 'program_name',
  programDisplayName: 'program_display_name',
  rebateMode: 'rebate_mode',
  rebateRateToDistributorAgent: 'rebate_rate_to_distributor_agent',
  rebateRateToDistributorAgentAndClient: 'rebate_rate_to_distributor_agent_and_client',
  percentageToDistributorAgent: 'percentage_to_distributor_agent',
  percentageToClient: 'percentage_to_client',
  status: 'status',
};

const assignProgramDialogTranslateKey = {
  assignProgramBtn: 'assign_program_btn',
  assignBtn: 'assign_btn',
  assignProgramSuccess: 'assign_program_success',
  ...commonTranslateKey,
};

const editDialogTranslateKey = {
  editBtn: 'edit_btn',
  editSuccess: 'edit_success',
  ...commonTranslateKey,
};

const filterTableTranslateKey = {
  operation: 'operation',
  rebate: 'rebate',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  createdBy: 'created_by',
  lastModifiedBy: 'last_modified_by',
  ...commonTranslateKey,
};

export { assignProgramDialogTranslateKey, editDialogTranslateKey, filterTableTranslateKey };
