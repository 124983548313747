import React from 'react';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../../hooks';
import { TableRow } from '../../types/Table';

interface IProps {
  selectedRow: TableRow | null;
}

const ApproveRejectContent = (props: IProps) => {
  const { selectedRow } = props;

  const {
    taskId,
    month,
    programName,
    currency,
    rebateCurrency,
    rebateExchangeRate,
    totalDistributorAgentRebateAmount,
    netTotalDistributorAgentRebateAmount,
    totalClientRebateAmount,
    creationTime,
  } = selectedRow || {};

  const { t } = useTranslation('rebateDistributionTask');

  return (
    <>
      <Container sx={{ padding: '40px' }}>
        <Row>
          <RowHeader>{t('taskId')}</RowHeader>
          <RowContent>{taskId}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('month')}</RowHeader>
          <RowContent>{month}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('programName')}</RowHeader>
          <RowContent>{programName}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('currency')}</RowHeader>
          <RowContent>{currency}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('rebateCurrency')}</RowHeader>
          <RowContent>{rebateCurrency}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('rebateExchangeRate')}</RowHeader>
          <RowContent>{rebateExchangeRate}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('totalDistributorAgentRebateAmount')}</RowHeader>
          <RowContent>{totalDistributorAgentRebateAmount}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('totalClientRebateAmount')}</RowHeader>
          <RowContent>{totalClientRebateAmount}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('netTotalDistributorAgentRebateAmount')}</RowHeader>
          <RowContent>{netTotalDistributorAgentRebateAmount}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('creationTime')}</RowHeader>
          <RowContent>{creationTime}</RowContent>
        </Row>
      </Container>
    </>
  );
};

export default ApproveRejectContent;
