import { CreateExchangeRateFields } from '../types';

export const getIsNoChangeForUpdate = (
  fields: CreateExchangeRateFields,
  isInfinityChanged: boolean,
  exchangeRateApiRes: any
) => {
  const { currentExchangeRate, nextExchangeRate, startFrom } = fields;
  const dataSrc = exchangeRateApiRes;

  const apiCurrentExchangeRate = dataSrc.currentExchangeRate;
  const apiNextExchangeRate = dataSrc.nextExchangeRate;
  const apiStartTime = dataSrc.StartFrom;

  const isNoChange =
    currentExchangeRate === apiCurrentExchangeRate &&
    nextExchangeRate === apiNextExchangeRate &&
    new Date(startFrom || '').toISOString() === apiStartTime &&
    !isInfinityChanged;

  return isNoChange;
};
