import Header from '../../../../../../../common/approval/components/DetailView/Header';
import { useTranslation } from '../../../../../../../hooks';
import DeclarationTermsAndConditions from './Section/DeclarationTermsAndConditions';
import LevelOfActivityAnticipated from './Section/LevelOfActivityAnticipated';
// import PersonalInfo1 from './Section/PersonalInfo1';
import PersonalInfo2 from './Section/PersonalInfo2';
import PersonalInfo3 from './Section/PersonalInfo3';

export interface SeniorPublicFigureList {
  country: string;
  lastName: string;
  position: string;
  sequence: number;
  firstName: string;
  department: string;
  relationship: string;
}

export interface TcspApplicationAnswer {
  remarks: string;
  signature: string;
  signatureDate: string;
  sourceOfFunds: number;
  sourceOfFundsOthers: string | null;
  totalAssetHKD: string;
  sourceOfWealth: number;
  sourceOfWealthOthers: string | null;
  enablePrePayment: boolean;
  expectedTxPerMonth: number;
  obtainPersonalInfo: boolean;
  trustAccountReason: number;
  enableAdvancePayment: boolean;
  authorizedToThirdParty: boolean;
  authorizedToThirdPartyReason?: string;
  seniorPublicFigureList?: SeniorPublicFigureList[];
  expectedTxAmountPerMonth: string;
  trustAccountReasonOthers: string;
  seniorPublicFigureDeclaration: boolean;
  agreementOfTAndC?: boolean;
}

const TcspApplicationDetail = ({ data }: { data: TcspApplicationAnswer | undefined }) => {
  const { t } = useTranslation('tcspAccountApplication');

  if (!data) return <></>;

  return (
    <div style={{ marginTop: '24px' }}>
      {/* <Header title={t('personalInfoTitle')} />
      <PersonalInfo1 data={data} /> */}

      {/* Reason for setting up a trust account in Hong Kong / PPTA */}
      <Header title={t('personalInfoTitle', { index: '1' })} />
      <PersonalInfo2 data={data} />

      {/* Senior Public Figure Declaration */}
      <Header title={t('personalInfoTitle', { index: '2' })} />
      <PersonalInfo3 data={data} />

      <Header title={t('levelOfActivityAnticipatedHeader')} />
      <LevelOfActivityAnticipated data={data} />

      <Header title={t('declarationTermsAndConditions')} />
      <DeclarationTermsAndConditions data={data} />
    </div>
  );
};

export default TcspApplicationDetail;
