import React from 'react';

import emailIconSrc from '../../../../assets/icons/login-page-email-icon.png';
import { useTranslation } from '../../../../hooks';
import { LoginFields, LoginFieldsKeys } from '../types';
import LoginInput from './LoginInput';
import RequestEmailVerificationCodeBtn, {
  RequestEmailVerificationCodeBtnUiStyle,
} from './RequestEmailVerificationCodeBtn';

interface IProps {
  fields: LoginFields;
  setFields: React.Dispatch<React.SetStateAction<LoginFields>>;
  handleRequestVerificationCode: () => Promise<any>;
  noInputIcon?: boolean;
  btnUiStyle?: RequestEmailVerificationCodeBtnUiStyle;
  requestCodeOnMount?: boolean;
}

const EmailVerificationCodeInputWithRequestBtn = (props: IProps) => {
  const {
    fields,
    setFields,
    handleRequestVerificationCode,
    noInputIcon,
    btnUiStyle,
    requestCodeOnMount,
  } = props;

  const { t } = useTranslation('login');

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <LoginInput
        label={t('emailVerification.enterVerificationCode')}
        value={fields[LoginFieldsKeys.EmailVerificationCode]}
        onChange={(e) =>
          setFields((fields) => ({
            ...fields,
            [LoginFieldsKeys.EmailVerificationCode]: e.target.value,
          }))
        }
        iconSrc={emailIconSrc}
        noIcon={noInputIcon}
        numberOnly
        type={'one-time-code'}
        showNumericKeyboard
      />
      <div style={{ marginLeft: '12px' }}>
        <RequestEmailVerificationCodeBtn
          onClick={handleRequestVerificationCode}
          uiStyle={btnUiStyle}
          requestCodeOnMount={requestCodeOnMount}
        />
      </div>
    </div>
  );
};

export default EmailVerificationCodeInputWithRequestBtn;
