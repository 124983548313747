import React from 'react';
import Wrapper from './Wrapper';
import { useTranslation } from '../../../../hooks';

const NoRecords = () => {
  const { t } = useTranslation('feeAdjustmentRate');

  return (
    <Wrapper
      style={{
        padding: '62px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {t('noRecords')}
    </Wrapper>
  );
};

export default NoRecords;
