import { OpRedirect } from '../../../assets/icons';
import { OpIconButton } from '../../../components';
import { Box, Tooltip } from '../../../components/MuiGenerals';
import { useTranslation } from '../../../hooks';
import getDisplayApprovalProgress from '../helpers/getDisplayApprovalProgress';

interface ApprovalProgressTableCellProps {
  approvalsCount: number;
  approvalsRequired: number;
  applicationStatus: number;
  approvedStatus: number;
  onButtonClick: () => void;
}

const ApprovalProgressTableCell = (props: ApprovalProgressTableCellProps) => {
  const { onButtonClick, approvalsCount, approvalsRequired, applicationStatus, approvedStatus } =
    props;
  const { tc } = useTranslation();

  const cellText = getDisplayApprovalProgress({
    approvalsCount,
    approvalsRequired,
    applicationStatus,
    approvedStatus,
    translateFunc: tc,
  });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip title={cellText}>
        <Box sx={{ marginRight: '12px' }}>{cellText}</Box>
      </Tooltip>

      <OpIconButton
        title={tc('approvalProgressDialogTitle')}
        size="1rem"
        svgUrl={OpRedirect}
        onClick={onButtonClick}
      />
    </Box>
  );
};

export default ApprovalProgressTableCell;
