import { Currency } from '../../../../../common/types/Currency';

export enum FilterFieldsKeys {
  DistributorAgentId = 'distributorAgentId',
  Currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.DistributorAgentId]: string;
  [FilterFieldsKeys.Currency]: Currency;
}
