import { useEffect, useState } from 'react';
import APIs from '../api';
import { PartialSearchDistributorAgentIdResponse } from '../api/types';

export function useDistributorAgentMap() {
  const [distributorAgentMap, setDistributorAgentMap] = useState<Record<string, string>>({});

  async function getDistributorAgentList() {
    const res: PartialSearchDistributorAgentIdResponse[] =
      await APIs.setting.userSetting.partialSearchDistributorAgentId({});

    if (!res) return;

    const tempDistributorAgentMap: Record<string, string> = {};
    res.forEach((item: PartialSearchDistributorAgentIdResponse) => {
      tempDistributorAgentMap[item.distributorAgentId] = item.name;
    });

    setDistributorAgentMap(tempDistributorAgentMap);
  }

  useEffect(() => {
    getDistributorAgentList();
  }, []);

  return { distributorAgentMap };
}
