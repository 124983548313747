const commonTranslateKey = {
  distributorName: 'distributor_name',
  distributorAgentId: 'distributor_agent_id',
  programName: 'program_name',
};

const createAgentDialogTranslateKey = {
  createAgentBtn: 'create_agent_btn',
  createdAgentSuccess: 'created_agent_success',
  createAgentDulplicateWarning: 'create_agent_dulplicate_warning',
  ...commonTranslateKey,
};

const assignProgramDialogTranslateKey = {
  assignProgramBtn: 'assign_program_btn',
  selectedProgram: 'selected_program',
  unassignedProgram: 'unassigned_program',
  assignBtn: 'assign_btn',
  confirmAssignProgramMessage: 'confirm_assign_program_message',
  assignProgramSuccess: 'assign_program_success',
};

const programNameAndEditDialogTranslateKey = {
  editBtn: 'edit_btn',
  rebateMode: 'rebate_mode',
  daRebateRate: 'da_rebate_rate',
  clientRebateRate: 'client_rebate_rate',
  status: 'status',
  ...commonTranslateKey,
};

const filterTableTranslateKey = {
  operation: 'operation',
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  createdBy: 'created_by',
  lastModifiedBy: 'last_modified_by',
  ...commonTranslateKey,
};

export {
  createAgentDialogTranslateKey,
  assignProgramDialogTranslateKey,
  programNameAndEditDialogTranslateKey,
  filterTableTranslateKey,
};
