import { Dispatch, HTMLAttributes, SetStateAction, useCallback } from 'react';

import { PartialSearchDistributorAgentIdResponse } from '../../../../api/types';
import AutoComplete from '../../../../components/AutoComplete';
import { Box } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';

interface IProps {
  value: string;
  options: PartialSearchDistributorAgentIdResponse[];
  isDisabled?: boolean;
  // onInputChange is for text input change, callback value will be string, which = label
  onInputChange: (value: string) => void;
  // handleChange is for option change, callback value will be object, which = option
  handleChange: (value: PartialSearchDistributorAgentIdResponse) => void;
  setOptions: Dispatch<SetStateAction<PartialSearchDistributorAgentIdResponse[]>>;
}

const DistributorAgentIdAutoComplete = (props: IProps) => {
  const { value, onInputChange, options, handleChange, isDisabled, setOptions } = props;

  const { tc } = useTranslation();

  const optionCountLimit = 10;

  const getOptionLabel = (option: PartialSearchDistributorAgentIdResponse) =>
    `${option.distributorAgentId} ${option.name ? `(${option.name})` : ''}`;

  const getIsFirstOption = (value: string) => value === getOptionLabel(options[0]);

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: PartialSearchDistributorAgentIdResponse) => {
      const optionLabel = getOptionLabel(option);

      const isFirstOption = getIsFirstOption(optionLabel);

      const shouldShowLimit = isFirstOption && options.length >= optionCountLimit;

      const displayEle = shouldShowLimit ? (
        <li {...props} style={{ marginTop: 24 }}>
          <Box>{optionLabel}</Box>

          <Box
            sx={{
              width: 'calc(100% - 30px)',
              position: 'absolute',
              top: 0,
              borderBottom: '1px solid #fff',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Box sx={{ padding: '4px 0', fontSize: 11 }}>
              {tc('display_records_limit', { limit: optionCountLimit })}
            </Box>
          </Box>
        </li>
      ) : (
        <li {...props}>{optionLabel}</li>
      );

      return displayEle;
    },
    [options]
  );

  return (
    <div>
      <AutoComplete
        label={tc('phInputField', { fieldName: tc('distributorAgentId') })}
        value={value as any}
        onInputChange={(_, value) => onInputChange(value)}
        options={options}
        optionCountLimit={optionCountLimit}
        triggerLength={1}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        handleChange={(_, value) => handleChange(value)}
        disablePortal
        disabled={isDisabled}
        setOptions={setOptions}
      />
    </div>
  );
};

export default DistributorAgentIdAutoComplete;
