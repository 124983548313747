import { useState } from 'react';

import APIs from '../../../../../api';
import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useLoading from '../../../../../hooks/useLoading';
import { TableRow } from '../../types/Table';
import Content from './Content';
import useCreateUserValidation from './helpers/useCreateUserValidation';
import { CreateUserFields } from './types';

type IProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

const initFields = {
  agentType: '',
  distributorAgentId: '',
  loginEmail: '',
  name: '',
  role: '',
  status: 1,
  remarks: '',
};

export default function CreateUserDialog(props: IProps) {
  const { isOpen, selectedRow, closeDialog, refreshTable } = props;

  const [fields, setFields] = useState<CreateUserFields>(initFields);

  const { t, tc } = useTranslation('userSetting');
  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();

  const { validate } = useCreateUserValidation({ fields });

  const resetFields = () => {
    setFields(initFields);
  };

  const handleCloseDialog = () => {
    resetFields();
    closeDialog();
  };

  const handleConfirm = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    showLoading('createUser');
    const res = await APIs.setting.userSetting.createUser({
      email: fields.loginEmail,
      name: fields.name,
      roles: [Number(fields.role)],
      remark: fields.remarks,
      status: fields.status,
      distributorAgentId: fields.distributorAgentId,
      agentType: Number(fields.agentType),
    });
    hideLoading('createUser');

    if (!res) return;

    alerting('success', t('createUserSuccessAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t('createUser'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: <Content fields={fields} setFields={setFields} />,
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
    size: 'md',
  };

  return <DialogInOne {...dialogConfig} />;
}
