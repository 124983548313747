import { COLOR_THEME } from '../../style/colorTheme';

export default function DivideLine() {
  const lineStyle = {
    position: 'relative' as const,
    width: 'calc(100% + 4rem)',
    height: ' 1rem',
    backgroundColor: COLOR_THEME.Page.Background,
    left: ' -2rem',
  };
  return <div style={lineStyle} />;
}
