import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { selectPermissions } from '../reducer/profileSlice';
import { setView } from '../reducer/stuffSlice';

//###add-new-page
// [translateKey, route, permission]
export const routeList: { [key: string]: string[] } = [
  ['homepage', '', ''],
  ['accountSetting', 'accountSetting', ''],
  ['changePassword', 'accountSetting', ''],
  ['dashboard', '/', AgentPortalFeatureCode.Dashboard.Dashboard.prefix],
  // TODO: add back permission later

  // Setting
  [
    'distributorAgentList',
    'distributorAgentList',
    AgentPortalFeatureCode.Setting.DistributorAgentList.prefix,
  ],
  [
    'distributorAgentProgram',
    'distributorAgentProgram',
    AgentPortalFeatureCode.Setting.DistributorAgentProgram.prefix,
  ],
  ['exchangeRate', 'exchangeRateAms', AgentPortalFeatureCode.Setting.ExchangeRate.prefix],
  [
    'exchangeRateConfiguration',
    'exchangeRateConfiguration',
    AgentPortalFeatureCode.Setting.ExchangeRateConfiguration.prefix,
  ],
  ['userSetting', 'userSetting', AgentPortalFeatureCode.Setting.UserSetting.prefix],

  // Program Agent Management
  ['programList', 'programList', AgentPortalFeatureCode.ProgramAgentManagement.ProgramList.prefix],
  [
    'programAgentDeposit',
    'programAgentDeposit',
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramAgentDeposit.prefix,
  ],
  [
    'programAgentWallet',
    'programAgentWallet',
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramAgentWallet.prefix,
  ],
  [
    'programAgentRebateWallet',
    'programAgentRebateWallet',
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramAgentRebateWallet.prefix,
  ],
  [
    'custodyWallet',
    'custodyWallet',
    AgentPortalFeatureCode.ProgramAgentManagement.CustodyWallet.prefix,
  ],
  [
    'creditSwapRecord',
    'creditSwapRecord',
    AgentPortalFeatureCode.ProgramAgentManagement.CreditSwapRecord.prefix,
  ],
  [
    'feeAdjustmentRate',
    'feeAdjustmentRate',
    AgentPortalFeatureCode.ProgramAgentManagement.FeeAdjustmentRate.prefix,
  ],
  [
    'creditAllocation',
    'creditAllocation',
    AgentPortalFeatureCode.DistributorAgentManagement.CreditAllocation.prefix,
  ],

  // Distributor Agent Management

  [
    'distributorAgentWallet',
    'distributorAgentWallet',
    AgentPortalFeatureCode.DistributorAgentManagement.DistributorAgentWallet.prefix,
  ],

  // Client Management
  [
    'clientApplication',
    'clientApplication',
    AgentPortalFeatureCode.ClientManagement.ClientApplication.prefix,
  ],
  ['clientList', 'clientList', AgentPortalFeatureCode.ClientManagement.ClientList.prefix],

  // Report
  // TODO: permissions
  [
    'programAgentReport',
    'programAgentReport',
    AgentPortalFeatureCode.Report.ProgramAgentReport.prefix,
  ],
  [
    'distributorAgentReport',
    'distributorAgentReport',
    AgentPortalFeatureCode.Report.DistributorAgentReport.prefix,
  ],
  ['clientReport', 'clientReport', AgentPortalFeatureCode.Report.ClientReport.prefix],
  [
    'creditAllocationRecord',
    'creditAllocationRecord',
    AgentPortalFeatureCode.Report.CreditAllocationRecord.prefix,
  ],
  [
    'programAgentReportDetail',
    'programAgentReportDetail',
    AgentPortalFeatureCode.Report.ProgramAgentReportDetail.prefix,
  ],
  [
    'distributorAgentReportDetail',
    'distributorAgentReportDetail',
    AgentPortalFeatureCode.Report.DistributorAgentReportDetail.prefix,
  ],
  [
    'clientReportDetail',
    'clientReportDetail',
    AgentPortalFeatureCode.Report.ClientReportDetail.prefix,
  ],
  [
    'creditAllocationRecord',
    'creditAllocationRecord',
    AgentPortalFeatureCode.Report.CreditAllocationRecord.prefix,
  ],

  [
    'rebateDistributionTask',
    'rebateDistributionTask',
    AgentPortalFeatureCode.Rebate.RebateDistributionTask.prefix,
  ],
  ['rebateRecord', 'rebateRecord', AgentPortalFeatureCode.Rebate.RebateRecord.prefix],
  [
    'monthlyRebateReport',
    'monthlyRebateReport',
    AgentPortalFeatureCode.Report.MonthlyRebateReport.prefix,
  ],
  [
    'customerSpendingReport',
    'customerSpendingReport',
    AgentPortalFeatureCode.Report.CustomerSpendingReport.prefix,
  ],
  // ['transferRecord', 'transferRecord', ''],
  // ['cardTransaction', 'cardTransactionAms', ''],
].reduce((acc, [key, snakeCase, premission]) => {
  return { ...acc, [key]: [snakeCase, key, premission] };
}, {});

export default function useRoutePermission() {
  const pathname = useLocation().pathname.replace('/', '');
  const dispatch = useAppDispatch();
  const permissionList = useAppSelector(selectPermissions);
  const hasPermission = (key: string) => permissionList.includes(String(key).toLowerCase());
  const viewPermission = getViewPermission(pathname);

  useEffect(() => {
    dispatch(setView(viewPermission));
  }, [viewPermission]);

  const hasRoutePermission = (key: string) => {
    if (key === 'homepage' || key === 'accountSetting' || key === 'changePassword') return true;

    const [_, __, permission] = routeList[key] || [];
    return hasPermission(permission);
  };

  return { hasRoutePermission, viewPermission };
}

export function getViewPermission(pathname: string) {
  const [permission] = Object.values(routeList).find(([_, path]) => path === pathname) || [
    'homepage',
  ];
  return permission;
}
