import { EnumVerificationType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Access';
import APIs from '../../../../api';
import useLoading from '../../../../hooks/useLoading';
import { updateVerificationToken } from '../../../../utils';
import { useLocaleState } from 'react-admin';

interface IRequestEmailVerificationCodeProps {
  email: string;
  type: EnumVerificationType;
}

// zh-CN not working
const EnumLocaleMapping: any = {
  en: 'en',
  zh_cn: 'zh-TW',
};

const useEmailCodeApi = () => {
  const { showLoading, hideLoading } = useLoading();

  const [locale] = useLocaleState();

  const requestEmailVerificationCode = async ({
    email,
    type,
  }: IRequestEmailVerificationCodeProps) => {
    // [API Call] request temp token to gain access for request email code
    showLoading('requestTempToken');
    const tempTokenRes = await APIs.merchantPortal.auth.requestTempToken({
      email,
      type,
    });
    hideLoading('requestTempToken');

    if (!tempTokenRes) return null;

    const verifyToken = tempTokenRes.token;
    updateVerificationToken(verifyToken);

    const lang = EnumLocaleMapping[locale];
    // [API Call] request to send email code
    showLoading('requestEmailVerificationCode');
    const emailCodeRes = await APIs.merchantPortal.auth.requestEmailVerificationCode({
      lang,
    });
    hideLoading('requestEmailVerificationCode');

    if (!emailCodeRes) return null;

    return emailCodeRes;
  };

  const verifyEmailVerificationCode = async (code: string) => {
    showLoading('verifyEmailVerificationCode');
    const res = await APIs.merchantPortal.auth.verifyEmailVerificationCode({
      code,
    });
    hideLoading('verifyEmailVerificationCode');

    if (!res || (res && !res.verifyResult)) return null;

    const verifyToken = res.token;

    updateVerificationToken(verifyToken);

    return res;
  };

  return { requestEmailVerificationCode, verifyEmailVerificationCode };
};

export default useEmailCodeApi;
