import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

export interface IGetExchangeRateRes {
  currentExchangeRate: string;
  nextExchangeRate: string;
  StartFrom: Date;
}

const getExchangeRate = async (
  params: {
    fromCurrency: string;
    toCurrency: string;
  },
  config?: any
) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, IGetExchangeRateRes>(
    AgentPortal.EptSetting.exchangeRate.getAll,
    input
  );
};

const createOrEditExchangeRate = async (
  params: {
    fromCurrency: string;
    toCurrency: string;
    currentExchangeRate: string;
    nextExchangeRate?: string;
    nextExchangeRateTime?: string;
    currentExchangeRateInfinity: boolean;
  },
  config = {}
) => {
  const res = await axios.post<never, any>(AgentPortal.EptSetting.exchangeRate.edit, params, {});

  return res;
};

export default { getExchangeRate, createOrEditExchangeRate };
