import { forwardRef, MouseEvent, useState } from 'react';

import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => Promise<any> | any;
  component?: React.ElementType;
}

export default forwardRef(function CheckIsCallingApiButton(
  props: CustomButtonProps,
  forwardRef: React.ForwardedRef<HTMLButtonElement>
) {
  const [isCallingApi, setIsCallingApi] = useState(false);
  const { onClick, ...rest } = props;
  const handledOnClick =
    onClick &&
    (async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (isCallingApi) {
        return;
      }
      setIsCallingApi(true);
      await onClick(event);
      setIsCallingApi(false);
    });
  return <Button {...rest} ref={forwardRef} onClick={handledOnClick} />;
});
