import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';
import { PartialSearchDistributorAgentIdResponse } from '../types';

const partialSearchDistributorAgentId = async (params: {
  keywords?: string;
  limit?: number;
  offset?: number;
}) => {
  const requestParams: any = {};
  if (params.keywords !== undefined) {
    requestParams.keywords = params.keywords;
  }

  if (params.limit !== undefined) {
    requestParams.limit = params.limit;
  }
  if (params.offset !== undefined) {
    requestParams.offset = params.offset;
  }

  return await axios.get<any, PartialSearchDistributorAgentIdResponse[]>(
    AgentPortal.EptQuery.agent.getDistributorAgentId,
    {
      params: requestParams,
    }
  );
};

export default { partialSearchDistributorAgentId };
