import React from "react";
import { Box, Typography } from "@mui/material";
import { COLOR_THEME } from "../../../../style/colorTheme";

interface ICountContentProps {
    count: number | string;
    label?: string;
    height?: string;
}

const CountContent: React.FC<ICountContentProps> = ({ count, label }) => {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.2rem',
        }}>
            <Box sx={{
                flex: 1.5,
                lineHeight: '0.8rem',
            }}>
                <span
                    style={{
                        fontSize: "0.6rem",
                        color: COLOR_THEME.CustomSx.Dashboard.label,
                    }}
                >
                    {label}
                </span>
            </Box>
            <Box
                sx={{
                    flex: 2,
                    display: "flex",
                }}
            >
                <Typography sx={{
                    fontSize: "2.5rem",
                }}>{count}</Typography>
            </Box>
        </Box>
    );
};

export default CountContent;
