import { useState } from 'react';

import APIs from '../../../../../api';
import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useLoading from '../../../../../hooks/useLoading';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { SystemCurrency } from '../../../../../reducer/systemCurrencySlice';
import { amountMultipleDecimals } from '../../../../../utils';
import Content from './Content';
import useCreateRequestValidation from './helpers/useCreateRequestValidation';
import { CreateRequestFields } from './types';

type IProps = {
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
};

const initFields = {
  distributorAgentId: '',
  currency: {} as SystemCurrency,
  transactionAmount: '',
};

export default function CreateRequestDialog(props: IProps) {
  const { isOpen, closeDialog, refreshTable } = props;

  const [fields, setFields] = useState<CreateRequestFields>(initFields);
  const [balance, setBalance] = useState<string | null>(null);

  const translatePrefix = 'creditAllocation' as Iprefix;

  const { t } = useTranslation(translatePrefix);

  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();

  const { validate } = useCreateRequestValidation({ fields });

  const removeComma = (str: string) => str.replace(/,/g, '');

  const resetFields = () => {
    setFields(initFields);
    setBalance(null);
  };

  const handleCloseDialog = () => {
    resetFields();
    closeDialog();
  };

  const handleConfirm = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    // transaction amount must be less than balance
    if (Number(fields.transactionAmount) > Number(removeComma(balance || ''))) {
      alerting('error', t('insufficientAvailableBalance'));
      return;
    }

    showLoading('createTransferRequest');
    const res = await APIs.distributorAgentManagement.creditAllocation.createTransferRequest({
      distributorAgentId: fields.distributorAgentId,
      creditCurrency: fields.currency.currency,
      creditAmount: amountMultipleDecimals(fields.transactionAmount, fields.currency.decimals),
      creditCurrencyDecimals: fields.currency.decimals,
    });
    hideLoading('createTransferRequest');

    if (!res) return;

    alerting('success', t('createTransferRequestSuccessAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t('createRequest'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: (
      <Content fields={fields} setFields={setFields} balance={balance} setBalance={setBalance} />
    ),
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
    size: 'md',
  };

  return <DialogInOne {...dialogConfig} />;
}
