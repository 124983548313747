import React, { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../../../../../api';
import { ApiResult } from '../../../../../../api/types';
import Table from '../../../../../../common/filterTable/components/Table';
import { defaultListParams } from '../../../../../../common/filterTable/constants/defaultListParams';
import useLoading from '../../../../../../hooks/useLoading';
import { useGenGridCol } from '../../../../../../utils/ComponentHelper';
import { TableRow } from '../../../types/Table';

export interface ApiRes {
  id: number;
  programAgentId: string;
  distributorAgentId: string;
  programName: string;
  createdBy: string;
  status: number;
  rebateMode: number;
  daRebateRate: string;
  customerRebateRate: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  distributorAgent: DistributorAgent;
}

export interface DistributorAgent {
  distributorAgentId: string;
  name: string;
}

interface IProps {
  selectedRow?: TableRow;
}

const Content = (props: IProps) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState<ApiResult<ApiRes>>();

  const { showLoading, hideLoading } = useLoading();

  const programName = props?.selectedRow?.rawData?.programName;

  const totalCounts = data?.count || 0;

  const fetchAssignedTo = async () => {
    showLoading('assignedTo');
    const res = await APIs.programAgentManagement.programList.getAssignedToList({
      ...defaultListParams,
      programName,
      page,
    });
    hideLoading('assignedTo');

    if (!res) return;

    return res;
  };

  const getRowData = () => {
    if (!data || !data.rows) return [];

    return data.rows.map((item: ApiRes) => ({
      id: item.id,
      distributorAgentId: item.distributorAgentId,
      distributorAgentName: item.distributorAgent.name,
    }));
  };

  const rows = getRowData();

  const columns = [useGenGridCol('distributorAgentId'), useGenGridCol('distributorAgentName')];

  useQuery(['assignedTo', page], fetchAssignedTo, {
    onSuccess: (response: ApiResult<ApiRes>) => {
      setData(response);
    },
  });

  return (
    <div>
      <Table
        columns={columns}
        rows={rows}
        rowCount={totalCounts}
        page={page}
        onPageChange={(page) => setPage(page)}
      />
    </div>
  );
};

export default Content;
