import { useState } from 'react';

import { getAllErrors } from './helpers/getAllErrors';
import { getInitValidationResult } from './helpers/getInitValidationResult';
import { getValidationResult } from './helpers/getValidationResult';
import { normalizeConfig } from './helpers/normalizeConfig';
import {
  ValidationConfigProps,
  ValidationResult,
  ValidationType
} from './types';

const useValidation = (config: ValidationConfigProps, validationTypeSorting?: ValidationType[]) => {
  const [isAllClean, setIsAllClean] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResult>(
    getInitValidationResult(config)
  );
  const [allErrors, setAllErrors] = useState<string[]>([]);

  const validate = () => {
    const validationConfig = normalizeConfig(config);

    const { result, isClean } = getValidationResult(validationConfig);

    const allValidationErrors = getAllErrors(result, validationConfig, validationTypeSorting);

    setValidationResult(result);
    setAllErrors(allValidationErrors);
    setIsAllClean(isClean);

    return {
      validationResult: result,
      allErrors: allValidationErrors,
      isAllClean,
    };
  };

  return {
    validate,
    validationResult,
    allErrors,
    isAllClean,
  };
};

export default useValidation;
