import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../../api';
import { selectAgentCurrencyList, setAgentCurrencyList } from '../../reducer/agentCurrencySlice';
import { useAppDispatch, useAppSelector } from '../../reducer/hooks';
import useLoading from '../useLoading';
import {
  selectAgentProgramCurrency,
  setAgentProgramCurrencyList,
} from '../../reducer/agentProgramCurrencySlice';

interface IProps {
  shouldFetch?: boolean;
}

const useAgentProgramCurrency = (props?: IProps) => {
  const { shouldFetch } = props || {};

  const dispatch = useAppDispatch();
  const { list: agentProgramCurrencyList } = useAppSelector(selectAgentProgramCurrency);
  const { showLoading, hideLoading } = useLoading();

  const getAgentProgramCurrencyEnum = () => {
    if (!agentProgramCurrencyList) return {};

    return agentProgramCurrencyList.reduce((acc: any, curr: any) => {
      const isExisted = acc[curr.creditTokenName];

      if (isExisted) return acc;

      return {
        ...acc,
        [curr.creditTokenName]: JSON.stringify(curr),
      };
    }, {});
  };

  const fetchAgentProgramCurrency = async () => {
    showLoading('getAgentProgramCurrency');
    const res = await APIs.merchantPortal.query.getAgentProgramCurrency();
    hideLoading('getAgentProgramCurrency');

    if (!res) return [];

    return res;
  };

  useQuery('agentProgramCurrency', fetchAgentProgramCurrency, {
    staleTime: Infinity,
    enabled: shouldFetch && (!agentProgramCurrencyList || agentProgramCurrencyList.length === 0),
    onSuccess: (data) => {
      dispatch(setAgentProgramCurrencyList(data.rows));
    },
  });

  const AgentProgramCurrencyEnum = getAgentProgramCurrencyEnum();

  return {
    agentProgramCurrencyList: agentProgramCurrencyList,
    AgentProgramCurrencyEnum,
  };
};

export default useAgentProgramCurrency;
