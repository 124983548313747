import { ValidationConfig, ValidationResult, ValidationType } from '../types';

const getResultsSortByFieldKey = (result: ValidationResult, fieldKeys: string[]) => {
  const results = [];

  for (let i = 0; i < fieldKeys.length; i++) {
    const resultItem = result[fieldKeys[i]];

    if (!resultItem) {
      continue;
    }

    results.push(resultItem);
  }

  return results;
};

const getAllErrorsSortByFieldKey = (result: ValidationResult, fieldKeys: string[]) => {
  const allErrors: string[] = [];
  const allResults = getResultsSortByFieldKey(result, fieldKeys);

  for (let i = 0; i < allResults.length; i++) {
    const { isValid, errorInfo } = allResults[i];

    if (!isValid) {
      for (let j = 0; j < errorInfo.length; j++) {
        allErrors.push(errorInfo[j].message);
      }
    }
  }

  return allErrors;
};

const getAllErrorsSortByValidatorType = (
  result: ValidationResult,
  fieldKeys: string[],
  validatorKeys: string[]
) => {
  const allErrors: string[] = [];
  const allResults = getResultsSortByFieldKey(result, fieldKeys);

  for (let i = 0; i < validatorKeys.length; i++) {
    const validatorKey = validatorKeys[i];

    for (let i = 0; i < allResults.length; i++) {
      const errors = allResults[i].errorInfo.filter((item) => item.validatorType === validatorKey);

      if (errors.length > 0) {
        allErrors.push(errors[0].message);
      }
    }
  }

  return allErrors;
};

export const getAllErrors = (
  result: ValidationResult,
  config: ValidationConfig[],
  validatorTypeSorting?: ValidationType[]
) => {
  const configKeys = config.map((item) => item.key);
  let allErrors = [];

  if (validatorTypeSorting && validatorTypeSorting.length > 0) {
    allErrors = getAllErrorsSortByValidatorType(result, configKeys, validatorTypeSorting);
  } else {
    allErrors = getAllErrorsSortByFieldKey(result, configKeys);
  }

  return allErrors;
};
