import { useCallback } from 'react';

import { isStrictNaN } from '../utils';
import { useZusTranslatePrefixStore } from '../zustand/store';
import useTranslation from './useTranslation';

export interface Iconfig {
  fieldName: string;
  specificLabel?: string; // for special placeholder or one field has multiple input fields case (multiple placeholder)
  isSkipped?: boolean;
  customMessage?: string;
  notAllowZeroMode?: 'below' | 'exact';
  isNumberOnlyField?: boolean;
}

export default function useGridBoxInputValidation() {
  const { translatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const validateGridBoxInput = useCallback(
    (labelElePairArr: [string, JSX.Element][], config: Iconfig[] = []) => {
      let notNumberFieldObj: Iconfig | undefined = undefined;
      let valueZeroFieldObj: Iconfig | undefined = undefined;
      for (let i = 0; i < labelElePairArr.length; i++) {
        const [currentFieldName, outtestEle] = labelElePairArr[i];

        const getMustBeNumberObj = (currentLabel: string | undefined) =>
          config.find(({ fieldName, specificLabel, isNumberOnlyField }) => {
            if (!specificLabel) {
              return fieldName === currentFieldName && isNumberOnlyField;
            }
            return specificLabel === currentLabel && isNumberOnlyField;
          });

        const getNotAllowZeroConfigObj = (currentLabel: string | undefined) =>
          config.find(({ fieldName, specificLabel, notAllowZeroMode }) => {
            if (!specificLabel) {
              return fieldName === currentFieldName && notAllowZeroMode;
            }
            return specificLabel === currentLabel && notAllowZeroMode;
          });

        const checkElement = (
          object: JSX.Element | JSX.Element[] | undefined
        ): { fieldName: string; label: string } | undefined => {
          if (!object) {
            return undefined;
          }

          if (Array.isArray(object)) {
            const invalidInputElement = object.find((ele) => {
              if (!ele) {
                return false;
              }

              if (!notNumberFieldObj && isStrictNaN(ele.props.value)) {
                notNumberFieldObj = getMustBeNumberObj(ele.props.label);
              }

              if (!valueZeroFieldObj && Number(ele.props.value) === 0) {
                // UI restricted below 0
                valueZeroFieldObj = getNotAllowZeroConfigObj(ele.props.label);
              }

              return !!ele.props.label && !ele.props.value && !ele.props.timeValue;
            });
            if (invalidInputElement) {
              return {
                fieldName: currentFieldName,
                label: invalidInputElement.props.label as string,
              };
            }
            const childrenElement = object.find((ele) => {
              if (!ele) {
                return false;
              }
              return ele.props.children;
            });
            if (!childrenElement) {
              return undefined;
            }
            return checkElement(childrenElement);
          }
          const inputLabel = object.props?.label as string | undefined;

          const inputValue = object.props?.value as string | undefined;
          const inputTimeValue = object.props?.timeValue as string | undefined;

          if (!notNumberFieldObj && isStrictNaN(inputValue)) {
            notNumberFieldObj = getMustBeNumberObj(inputLabel);
          }

          if (!valueZeroFieldObj && Number(inputValue) === 0) {
            // UI restricted below 0
            valueZeroFieldObj = getNotAllowZeroConfigObj(inputLabel);
          }

          const functionalComponentName = object?.type?.name as string | undefined;
          const isInputComponent = functionalComponentName || inputLabel;

          if (isInputComponent && !inputValue && !inputTimeValue) {
            const isSelectionComponent = functionalComponentName
              ?.toLowerCase()
              .includes('selection');

            const withoutLabelPropsDefaultLabel = isSelectionComponent
              ? tc('phSelection', { fieldName: t(currentFieldName) })
              : tc('phInputField', { fieldName: t(currentFieldName) }); // for custom components without label provided props can be replaced in config

            const label = inputLabel ? inputLabel : withoutLabelPropsDefaultLabel;

            return { fieldName: currentFieldName, label };
          }

          const children = object.props?.children as JSX.Element | JSX.Element[] | undefined;
          if (children) {
            if (Array.isArray(children)) {
              const invalidInputElement = children.find((ele) => {
                if (!ele) {
                  return false;
                }

                if (!notNumberFieldObj && isStrictNaN(ele.props.value)) {
                  notNumberFieldObj = getMustBeNumberObj(ele.props.label);
                }

                if (!valueZeroFieldObj && Number(ele.props.value) === 0) {
                  // UI restricted below 0
                  valueZeroFieldObj = getNotAllowZeroConfigObj(ele.props.label);
                }
                return !!ele.props.label && !ele.props.value && !ele.props.timeValue;
              });
              if (invalidInputElement) {
                return {
                  fieldName: currentFieldName,
                  label: invalidInputElement.props.label as string,
                };
              }
            }
            return checkElement(children);
          }

          return undefined;
        };

        const inputLabelFound = checkElement(outtestEle);
        const { isSkipped, customMessage } =
          config.find(({ fieldName, specificLabel }) => {
            if (!specificLabel) {
              return fieldName === currentFieldName;
            }
            return specificLabel === inputLabelFound?.label;
          }) || {};

        if (inputLabelFound && !isSkipped) {
          return customMessage || inputLabelFound.label;
        }
      }

      if (notNumberFieldObj) {
        const typedNotNumberFieldObj = notNumberFieldObj as Iconfig;

        return tc('input_field_check_number', { fieldName: t(typedNotNumberFieldObj.fieldName) });
      }

      if (valueZeroFieldObj) {
        const typedValueZeroFieldObj = valueZeroFieldObj as Iconfig;

        return tc(
          typedValueZeroFieldObj.notAllowZeroMode === 'below'
            ? 'validate_below_zero'
            : 'validate_zero',
          { fieldName: t(typedValueZeroFieldObj.fieldName) }
        );
      }

      return undefined;
    },
    [translatePrefix]
  );
  return { validateGridBoxInput };
}
