import {
  ValidationType,
  ValidatorResponse
} from '../../hooks/useValidation/types';

export const largerThanZeroValidator =
  (errorMessage: string, isEnabled: boolean = true) =>
  (fieldKey: string, value: string | number): ValidatorResponse => {
    const val = Number(value);

    const errorInfo = {
      message: errorMessage,
      fieldKey,
      value,
      validatorType: ValidationType.LargerThanZero,
    };

    if (!isEnabled) {
      return { isValid: true, errorInfo };
    }

    if (!val || val <= 0 || isNaN(val)) {
      return { isValid: false, errorInfo };
    }

    return { isValid: true, errorInfo };
  };
