import React from 'react';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { forceLogout } from '../../api/axiosInstance';
import { useTranslation } from '../../hooks';
import { COLOR_THEME } from '../../style/colorTheme';
import { importantStyle } from '../../utils';
import { Button } from '../MuiGenerals';

const sx = {
  logoutBtn: {
    width: '86px',
    height: '28px',
    color: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text,
    backgroundColor: COLOR_THEME.Layout.LayoutBar.LogoutBtn.Background,
    borderRadius: '30px 29.5px 29.5px 30px',
    padding: '.3rem 7px .3rem 10px',
    boxShadow: 'none',
    marginRight: '8px',
    '>span': {
      marginRight: '4px',
    },
    svg: { color: importantStyle(COLOR_THEME.Layout.LayoutBar.LogoutBtn.Text) },
  },
};

interface IProps {
  isHide?: boolean;
  title?: string;
}

const LogoutButton = (props: IProps) => {
  const { isHide, title } = props;

  const { tc } = useTranslation();

  const logoutFn = () => {
    forceLogout('', { from: 'menu-button' });
  };

  if (isHide) return <></>;

  return (
    <Button
      variant="contained"
      startIcon={<PowerSettingsNewIcon />}
      onClick={logoutFn}
      sx={sx.logoutBtn}
    >
      {title || tc('logout')}
    </Button>
  );
};

export default LogoutButton;
