import { defaultListParams } from '../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../common/filterTable/helpers/cleanParams';
import { convertDateStringOrDateToYearMonth } from '../../../../utils/dateUtils';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const { createdDateFrom, createdDateTo, monthFrom, monthTo, taskId, programName, status } =
      fields;

    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const last2Years = new Date();
    last2Years.setFullYear(last2Years.getFullYear() - 2);

    const params: GetListParams = {
      ...defaultListParams,
      creationTimeFrom: createdDateFrom,
      creationTimeTo: createdDateTo,
      monthFrom:
        monthFrom !== ''
          ? convertDateStringOrDateToYearMonth(monthFrom)
          : monthTo !== ''
          ? convertDateStringOrDateToYearMonth(last2Years)
          : '',
      monthTo:
        monthTo !== ''
          ? convertDateStringOrDateToYearMonth(monthTo)
          : monthFrom !== ''
          ? convertDateStringOrDateToYearMonth(lastMonth)
          : '',
      taskId,
      programName,
      status,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
