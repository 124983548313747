import { defaultListParams } from '../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../common/filterTable/helpers/cleanParams';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const { programName, from, adjustmentType } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      programName,
      fromCurrency: from?.currency || '',
      adjustTypes: adjustmentType,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
