export const COLUMN_WIDTH = {
  TxAddress: {
    MinWidth: 350,
  },
  TxHash: {
    MinWidth: 350,
  },
  CreatedBy: {
    MinWidth: 150,
  },
  CreatedDate: {
    MinWidth: 170,
  },
  LastModifiedBy: {
    MinWidth: 150,
  },
  LastModifiedDate: {
    MinWidth: 170,
  },
  ApprovalProgress: {
    MinWidth: 150,
  },

  // project scope
  DistributorAgentId: {
    MinWidth: 200,
  },
};
