import auth from './auth';
import customer from './customer';
import profile from './profile';
import query from './query';

export default {
  auth,
  profile,
  query,
  customer,
};
