import React from 'react';

import { Cross } from '../../assets/icons';

const CrossIcon = ({ width = '20px', height = '20px' }: { width?: string; height?: string }) => {
  return (
    <div>
      <img src={Cross} alt="close" style={{ width, height }} />
    </div>
  );
};

export default CrossIcon;
