import React from 'react';
import { Modal } from '../MuiGenerals';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { COLOR_THEME } from '../../style/colorTheme';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsShowLoading } from '../../reducer/loadingSlice';

const LoadingModal = () => {
  const isShowLoading = useAppSelector(selectIsShowLoading);

  return (
    <Modal open={isShowLoading}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            width: '80px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: `${COLOR_THEME.Loading.Background}`,
          }}
        >
          <CircularProgress
            sx={{
              color: `${COLOR_THEME.Loading.CircularProgress}`,
            }}
          />
        </Paper>
      </div>
    </Modal>
  );
};

export default LoadingModal;
