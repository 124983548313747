import { CSSProperties, ReactNode } from 'react';

import { Breakpoint } from '@mui/system';

import { importantStyle } from '../../utils';
import { Dialog, DialogContent, DialogTitle } from '../MuiGenerals';
import ActionSection from './components/ActionSection';
import CrossButton from './components/CrossButton';

export interface IdialogInOneProps {
  title: string;
  customTitle?: any;
  self: {
    open: boolean;
    onClose: any;
  };
  content: ReactNode | string;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  actionButtons?: ReactNode;
  isLoadingDialog?: boolean;
  size?: Breakpoint;
  isReversedActionSection?: boolean;
  isFullScreen?: boolean;
  isShowCrossButton?: boolean;
  dialogActionStyling?: CSSProperties;
  dialogHeight?: {
    height?: string;
    maxHeight?: string;
  };
  maxContentHeight?: string;

  rest?: any;
}

export default function DialogInOne(props: IdialogInOneProps) {
  //   const translate = useTranslate();

  const {
    size = 'md',
    self,
    title,
    customTitle,
    content,
    onConfirm,
    onCancel,
    isConfirmHidden,
    isCancelHidden,
    cancelButtonText,
    confirmButtonText,
    actionButtons,
    rest,
    isLoadingDialog,
    isReversedActionSection,
    isFullScreen,
    isShowCrossButton,
    dialogActionStyling,
    dialogHeight,
    maxContentHeight,
  } = props;

  return (
    <Dialog
      open={self.open}
      onClose={self.onClose}
      fullWidth={true}
      maxWidth={size}
      PaperProps={{
        ...(rest?.PaperProps || {}),
        sx: {
          height: dialogHeight?.height || 'auto',
          maxHeight: dialogHeight?.maxHeight || 'none',
        },
      }}
      {...rest}
      sx={{
        ...(isFullScreen && {
          '& .MuiPaper-root': {
            width: importantStyle('100dvw'),
            maxWidth: importantStyle('100dvw'),
            minWidth: importantStyle('100dvw'),
            height: importantStyle('100dvh'),
            maxHeight: importantStyle('100dvh'),
            minHeight: importantStyle('100dvh'),
            margin: 0,
          },
        }),
      }}
    >
      {isReversedActionSection && (
        <ActionSection
          actionButtons={actionButtons}
          isConfirmHidden={isConfirmHidden}
          isCancelHidden={isCancelHidden}
          onConfirm={onConfirm}
          onCancel={onCancel}
          dialogActionStyling={dialogActionStyling}
        />
      )}

      <DialogTitle>
        {!customTitle && title && (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>{title}</div>
        )}
        {customTitle && customTitle}

        {isShowCrossButton && <CrossButton onClick={() => self.onClose()} />}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: `${isLoadingDialog ? '1rem 2.5rem' : '0 2rem'}`,
          ...(maxContentHeight && { maxHeight: maxContentHeight }),
        }}
      >
        {content}
      </DialogContent>

      {!isReversedActionSection && (
        <ActionSection
          actionButtons={actionButtons}
          isConfirmHidden={isConfirmHidden}
          isCancelHidden={isCancelHidden}
          onConfirm={onConfirm}
          onCancel={onCancel}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          dialogActionStyling={dialogActionStyling}
        />
      )}
    </Dialog>
  );
}
