import React from 'react';

import { GridRenderCellParams } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../api';
import { ApiResult } from '../../../api/types';
import Table from '../../../common/filterTable/components/Table';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterTableLayoutContainer from '../../../common/filterTable/layout/FilterTableLayoutContainer';
import FilterTopActionRow from '../../../common/filterTable/layout/FilterTopActionRow';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import useNewFilterTablePage from '../../../common/page/hooks/useNewFilterTablePage';
import { ExportBtn } from '../../../components/Button';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import useTranslation, { Iprefix as IPrefix } from '../../../hooks/useTranslation';
import CreateUserDialog from './components/CreateUserDialog';
import EditUserDialog from './components/EditUserDialog';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/Table';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.DistributorAgentId]: '',
  [FilterFieldsKeys.AgentType]: '',
  [FilterFieldsKeys.Role]: [],
  [FilterFieldsKeys.Status]: '',
  [FilterFieldsKeys.LoginEmail]: '',
};

const translatePrefix = 'userSetting' as IPrefix;
const getTableDataApiFunc = APIs.setting.userSetting.getAll;
const exportTableApiFunc = APIs.setting.userSetting.getExport;

const exportBtnPermission = AgentPortalFeatureCode.Setting.UserSetting.Export;

const UserSetting = () => {
  const { getListParams } = useGetListParams();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const pageConfig = {
    initFields,
    DateObj,
    translatePrefix,
    getListParams,
    getTableDataApiFunc,
    exportTableApiFunc,
  };

  const {
    page,
    onPageChange,
    fields,
    setFields,
    list,
    onFilterSearch,
    onFilterReset,
    onExport,
    getIsHavePermission,
    Tabs,
    refreshTable,
  } = useNewFilterTablePage<FilterFields, ApiResult<GetListRes>>(pageConfig);

  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = React.useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<TableRow | undefined>(undefined);

  const { t } = useTranslation('userSetting');
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });
  const columns = useTableColumns({ renderOperationCell });

  const isShowExportBtn = getIsHavePermission(exportBtnPermission);
  const isShowCreateUserBtn = getIsHavePermission(
    AgentPortalFeatureCode.Setting.UserSetting.CreateUser
  );
  const isShowEditBtn = getIsHavePermission(AgentPortalFeatureCode.Setting.UserSetting.Edit);

  const handleExport = async () => {
    await onExport('User Setting', totalCounts, convertToTableRows);
  };

  function renderOperationCell(params: GridRenderCellParams) {
    const handleOperationEditClick = () => {
      setIsEditUserDialogOpen(true);
      setSelectedRow(params.row);
    };

    return (
      <ButtonMenu
        title={t('operation')}
        options={[
          {
            name: t('edit'),
            onClickFn: handleOperationEditClick,
            isDisabled: !isShowEditBtn,
          },
        ]}
      />
    );
  }

  return (
    <>
      <FilterTableLayoutContainer>
        <CreateUserDialog
          isOpen={isCreateUserDialogOpen}
          selectedRow={selectedRow}
          closeDialog={() => {
            setIsCreateUserDialogOpen(false);
          }}
          refreshTable={refreshTable}
        />
        <EditUserDialog
          isOpen={isEditUserDialogOpen}
          selectedRow={selectedRow}
          closeDialog={() => {
            setIsEditUserDialogOpen(false);
          }}
          refreshTable={refreshTable}
        />

        <FilterContainer>
          <FilterTopActionRow>
            <FilterBarCreateRecordButton
              title={t('createUser')}
              onClick={() => {
                setIsCreateUserDialogOpen(true);
              }}
              isShow={isShowCreateUserBtn}
            />
          </FilterTopActionRow>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={onFilterSearch}
            onReset={onFilterReset}
            DateObj={DateObj}
          />
        </FilterContainer>

        <DivideLine />

        <TableContainer>
          <ExportBtn onExport={handleExport} isShow={isShowExportBtn} />
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={totalCounts}
              page={page}
              onPageChange={onPageChange}
            />
          </Tabs>
        </TableContainer>
      </FilterTableLayoutContainer>
    </>
  );
};

export default UserSetting;
