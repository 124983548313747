import { useTranslation } from '../../../../../../hooks';
import useValidation from '../../../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../../../utils/validators/requiredValidator';
import { EditUserFields } from '../types';

interface IProps {
  fields: EditUserFields;
}

const useEditUserValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('userSetting');

  const validationConfig: ValidationConfigProps = [
    {
      key: 'roles',
      value: fields['roles'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('roles'),
          })
        ),
      ],
    },
    {
      key: 'name',
      value: fields['name'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('name'),
          })
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useEditUserValidation;
