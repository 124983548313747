import React from 'react';

const Column = ({
  children,
  withDivider = false,
  style,
}: {
  children: React.ReactNode;
  withDivider?: boolean;
  style?: Record<string, string>;
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '50%',
        padding: '20px 40px',
        boxSizing: 'border-box',
        ...(style || {}),
      }}
    >
      <div>{children}</div>
      {withDivider && (
        <div
          style={{
            width: '2px',
            height: '80%',
            backgroundColor: '#DDE1E3',
            borderRadius: '12px',
            position: 'absolute',
            right: '0',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        ></div>
      )}
    </div>
  );
};

export default Column;
