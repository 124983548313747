import React, { ReactNode, SetStateAction, useEffect } from 'react';

import APIs from '../../../../../../api';
import { PartialSearchDistributorAgentIdResponse } from '../../../../../../api/types';
import ResetButton from '../../../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../../../common/filterTable/components/Buttons/SearchButton';
import AgentProgramDisplayNameSingleSelection from '../../../../../../common/filterTable/components/Filters/AgentProgramDisplayNameSingleSelection';
import DistributorAgentIdAutoComplete from '../../../../../../common/filterTable/components/Filters/DistributorAgentIdAutoComplete';
import Filters from '../../../../../../common/filterTable/components/Filters/Filters';
import PfhNumberTextFields from '../../../../../../common/filterTable/components/Filters/PfhNumberTextFields';
import FilterActions from '../../../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../../../common/filterTable/layout/FilterGrid';
import { useTranslation } from '../../../../../../hooks';
import { useGenFilterField } from '../../../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const [distributorAgentIdOptions, setDistributorAgentIdOptions] = React.useState<
    PartialSearchDistributorAgentIdResponse[]
  >([]);

  const { t } = useTranslation('customerSpendingReport');

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const handleDistributorAgentIdInputChange = async (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));

    if (value) {
      const res = await APIs.setting.userSetting.partialSearchDistributorAgentId({
        keywords: value.toUpperCase(),
        limit: 10,
        offset: 0,
      });

      if (!res) return;

      setDistributorAgentIdOptions(res);
    }
  };

  const handleDistributorAgentIdOptionChange = (value: string) => {
    setFields((fields) => ({ ...fields, distributorAgentId: value }));
  };

  useEffect(() => {
    updateField(FilterFieldsKeys.settlementDateFrom, DateObj.SettlementDate.start);
    updateField(FilterFieldsKeys.settlementDateTo, DateObj.SettlementDate.end);
  }, [DateObj.SettlementDate.start, DateObj.SettlementDate.end]);

  const filterFields: ReactNode[] = useGenFilterField([
    ['settlementDate', <DateObj.SettlementDate.Picker type="date" />],
    [
      FilterFieldsKeys.distributorAgentId,
      <DistributorAgentIdAutoComplete
        value={fields.distributorAgentId}
        options={distributorAgentIdOptions}
        onInputChange={(value: string) => {
          handleDistributorAgentIdInputChange(value);
        }}
        handleChange={(option: PartialSearchDistributorAgentIdResponse) => {
          handleDistributorAgentIdOptionChange(option?.distributorAgentId);
        }}
        setOptions={setDistributorAgentIdOptions}
      />,
    ],
    [
      FilterFieldsKeys.pfhNumber,
      <PfhNumberTextFields
        label={t('dailyReportTab.pfhNumber')}
        value={fields[FilterFieldsKeys.pfhNumber]}
        onChange={(e) => updateField(FilterFieldsKeys.pfhNumber, e.target.value)}
      />,
    ],
    [
      FilterFieldsKeys.programName,
      <AgentProgramDisplayNameSingleSelection
        value={fields[FilterFieldsKeys.programName]}
        onChange={(e) => updateField(FilterFieldsKeys.programName, e.target.value)}
        onClear={() => updateField(FilterFieldsKeys.programName, '')}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
