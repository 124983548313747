import { useLayoutEffect, useState } from 'react';

import APIs from '../../../../../api';
import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useLoading from '../../../../../hooks/useLoading';
import { TableRow } from '../../types/Table';
import Content from './Content';
import useEditUserValidation from './helpers/useEditUserValidation';
import { EditUserFields } from './types';

type IProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

export default function EditUserDialog(props: IProps) {
  const { isOpen, selectedRow, closeDialog, refreshTable } = props;

  const initFields = {
    name: selectedRow?.rawData.name || '',
    roles: selectedRow?.rawData.roles || [],
    status: selectedRow?.rawData.status || 1,
    remarks: selectedRow?.rawData.remark || '',
  };

  const [fields, setFields] = useState<EditUserFields>(initFields);

  const { t, tc } = useTranslation('userSetting');
  const { alerting } = useAlerting();
  const { showLoading, hideLoading } = useLoading();

  const { validate } = useEditUserValidation({ fields });

  useLayoutEffect(() => {
    setFields({
      name: selectedRow?.rawData.name || '',
      roles: selectedRow?.rawData.roles || [],
      status: Number(selectedRow?.rawData.status),
      remarks: selectedRow?.rawData.remark || '',
    });
  }, [selectedRow]);

  const resetFields = () => {
    setFields(initFields);
  };

  const handleCloseDialog = () => {
    resetFields();
    closeDialog();
  };

  const handleConfirm = async () => {
    const validationResult = validate();

    if (validationResult.allErrors.length > 0) {
      alerting('error', validationResult.allErrors[0]);
      return;
    }

    showLoading('editUser');
    const res = await APIs.setting.userSetting.editUser({
      distributorAgentId: selectedRow?.rawData.distributorAgentId || '',
      name: fields.name,
      roles: fields.roles.map((role) => Number(role)),
      status: fields.status,
      remark: fields.remarks,
      email: selectedRow?.rawData.email || '',
      agentType: selectedRow?.rawData.agentType,
    });
    hideLoading('editUser');

    if (!res) return;

    alerting('success', t('editUserSuccessAlert'));
    handleCloseDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t('editUser'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog,
    },
    content: <Content fields={fields} setFields={setFields} selectedRow={selectedRow} />,
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
    size: 'md',
  };

  return <DialogInOne {...dialogConfig} />;
}
