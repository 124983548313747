import React from "react";

import { AreaChart, ResponsiveContainer, Area, XAxis, YAxis, Tooltip, CartesianGrid, Dot } from "recharts";
import { COLOR_THEME } from "../../../style/colorTheme";
import CustomizedDot from "./components/CustomizedDot";

interface ILineChartProps {
    data: any[];
    height: number;
    valueKey: string;
    color: string;
    dotColor: string;
    xAxisDisplay?: boolean;
    yAxisDisplay?: boolean;
    tooltipValueKey: string;
}

const LineChart: React.FC<ILineChartProps> = ({
    data,
    height = 200,
    valueKey,
    color,
    dotColor,
    tooltipValueKey,
    xAxisDisplay = false,
    yAxisDisplay = false
}) => {
    return (
        <ResponsiveContainer
            width={"100%"}
            height={height}
        >
            <AreaChart
                key={Math.random()}
                data={data}
            >
                <XAxis dataKey="name" hide={!xAxisDisplay} />
                <YAxis hide={!yAxisDisplay} />
                <CartesianGrid vertical={false} stroke={COLOR_THEME.CustomSx.Chart.LineChart.CartesianGridColor} strokeOpacity={0.3} />
                <Tooltip cursor={false}
                    contentStyle={{
                        backgroundColor: COLOR_THEME.CustomSx.Chart.LineChart.TooltipContentBackground,
                    }}
                    formatter={(value: any) => {
                        return [`${value}`, `${tooltipValueKey}`];
                    }}
                />
                <Area
                    type="monotone"
                    dataKey={valueKey}
                    stroke={color}
                    fillOpacity={0.1}
                    fill={color}
                    activeDot={false}
                    dot={
                        <CustomizedDot dotColor={dotColor} />
                    }
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default LineChart;
