import React, { Fragment, ReactNode } from 'react';

const Filters = ({ filterFields }: { filterFields: ReactNode[] }) => {
  return (
    <>
      {filterFields.map((item, index) => {
        return <Fragment key={index}>{item}</Fragment>;
      })}
    </>
  );
};

export default Filters;
