import Column from '../../../../../../../../../common/approval/components/DetailView/Column';
import RowItem from '../../../../../../../../../common/approval/components/DetailView/RowItem';
import Section from '../../../../../../../../../common/approval/components/DetailView/Section';
import { useTranslation } from '../../../../../../../../../hooks';

const SeniorPublicFigureDeclarationSection = ({
  isHaveDeclaration,
}: {
  isHaveDeclaration: boolean;
}) => {
  const { t } = useTranslation('tcspAccountApplication');

  const declaration = isHaveDeclaration
    ? t('seniorPublicFigureDeclaration')
    : t('noSeniorPublicFigureDeclaration');

  return (
    <>
      <Section>
        <Column>
          <RowItem title={t('seniorPublicFigureDeclarationTitle')}>{declaration}</RowItem>
        </Column>
      </Section>
    </>
  );
};

export default SeniorPublicFigureDeclarationSection;
