import { useTranslation } from '../../../../hooks';
import useValidation from '../../../../hooks/useValidation';
import { ValidationConfigProps } from '../../../../hooks/useValidation/types';
import { confirmPasswordValidator } from '../../../../utils/validators/confirmPasswordValidator';
import { lengthLimitValidator } from '../../../../utils/validators/lengthLimitValidator';
import { requiredNumberAmount } from '../../../../utils/validators/requiredNumberAmount';
import { requiredSymbolValidator } from '../../../../utils/validators/requiredSymbolValidator';
import { requiredUpperCaseLetterValidator } from '../../../../utils/validators/requiredUpperCaseLetterValidator';
import { requiredValidator } from '../../../../utils/validators/requiredValidator';
import { LoginFields, LoginFieldsKeys } from '../types';

interface IProps {
  fields: LoginFields;
}

const useResetPasswordValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('login');

  const validationConfig: ValidationConfigProps = [
    {
      key: LoginFieldsKeys.NewPassword,
      value: fields[LoginFieldsKeys.NewPassword],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', { fieldName: t('resetPassword.password') })
        ),
        lengthLimitValidator(
          translate('validation.requiredLength', {
            fieldName: t('resetPassword.password'),
            min: 8,
            max: 40,
          }),
          {
            min: 8,
            max: 40,
          }
        ),
        requiredUpperCaseLetterValidator(
          translate('validation.requiredUpperCase', {
            fieldName: t('resetPassword.password'),
            min: 1,
          }),
          {
            min: 1,
          }
        ),
        requiredSymbolValidator(translate('validation.requiredSymbol')),
        requiredNumberAmount(
          translate('validation.requiredContainNumber', {
            fieldName: t('resetPassword.password'),
            min: 1,
          }),
          { min: 1 }
        ),
      ],
    },
    {
      key: LoginFieldsKeys.ConfirmNewPassword,
      value: fields[LoginFieldsKeys.ConfirmNewPassword],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('resetPassword.confirmPassword'),
          })
        ),
        confirmPasswordValidator(translate('validation.confirmPassword'), {
          password: fields[LoginFieldsKeys.NewPassword],
          confirmPassword: fields[LoginFieldsKeys.ConfirmNewPassword],
        }),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useResetPasswordValidation;
