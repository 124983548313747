import { defaultListParams } from '../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../common/filterTable/helpers/cleanParams';
import { convertDateStringOrDateToYearMonth } from '../../../../utils/dateUtils';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    const last2Years = new Date();
    last2Years.setFullYear(last2Years.getFullYear() - 2);

    const {
      creationTimeFrom,
      creationTimeTo,
      monthFrom,
      monthTo,
      taskId,
      orderId,
      from,
      to,
      programName,
      status,
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      creationTimeFrom,
      creationTimeTo,
      // API require MonthFrom and MonthTo need to provide in same time
      monthFrom:
        monthFrom !== ''
          ? convertDateStringOrDateToYearMonth(monthFrom)
          : monthTo !== ''
          ? convertDateStringOrDateToYearMonth(last2Years)
          : '',
      monthTo:
        monthTo !== ''
          ? convertDateStringOrDateToYearMonth(monthTo)
          : monthFrom !== ''
          ? convertDateStringOrDateToYearMonth(lastMonth)
          : '',
      taskId,
      orderId,
      fromClientId: from,
      toClientId: to,
      programName,
      status,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
