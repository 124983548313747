import React, { ReactNode, SetStateAction } from 'react';

import ResetButton from '../../../../common/filterTable/components/Buttons/ResetButton';
import SearchButton from '../../../../common/filterTable/components/Buttons/SearchButton';
import AgentLoginEmailTextField from '../../../../common/filterTable/components/Filters/AgentLoginEmailTextField';
import AgentRoleMultipleSelection from '../../../../common/filterTable/components/Filters/AgentRoleMultipleSelection';
import AgentStatusSingleSelection from '../../../../common/filterTable/components/Filters/AgentStatusSingleSelection';
import AgentTypeSingleSelection from '../../../../common/filterTable/components/Filters/AgentTypeSingleSelection';
import Filters from '../../../../common/filterTable/components/Filters/Filters';
import FilterActions from '../../../../common/filterTable/layout/FilterActions';
import FilterGrid from '../../../../common/filterTable/layout/FilterGrid';
import DaIdAutoCompleteFilter from '../../../../components/DaIdAutoCompleteFilter';
import { useGenFilterField } from '../../../../utils/ComponentHelper';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameSingleSelection from '../../../../common/filterTable/components/Filters/ProgramNameSingleSelection';
import AgentProgramDisplayNameSingleSelection from '../../../../common/filterTable/components/Filters/AgentProgramDisplayNameSingleSelection';
import AgentProgramNameSelection from '../../../../components/AgentProgramNameSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filterFields: ReactNode[] = useGenFilterField([
    ['creationTime', <DateObj.CreationTime.Picker type="dateTime" />],
    [
      FilterFieldsKeys.ProgramName,
      <AgentProgramNameSelection
        mode={'all'}
        value={fields[FilterFieldsKeys.ProgramName]}
        onChange={(e) => updateField(FilterFieldsKeys.ProgramName, e.target.value)}
        clearSelect={() => updateField(FilterFieldsKeys.ProgramName, '')}
      />,
    ],
  ]);

  return (
    <>
      <FilterGrid>
        <Filters filterFields={filterFields} />
      </FilterGrid>
      <FilterActions>
        <SearchButton onSearch={onSearch} />
        <ResetButton onReset={onReset} />
      </FilterActions>
    </>
  );
};

export default FilterSection;
