import React from 'react';
import { Box } from '../../../../components/MuiGenerals';
import { usePermission, useTranslation } from '../../../../hooks';
import { COLOR_THEME } from '../../../../style/colorTheme';
import RightArrowIconSrc from '../../../../assets/icons/redirect-right-arrow.png';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../reducer/mediaSlice';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
interface ITotalProgramOverviewProps {
  data: any;
}

const TotalProgramOverview: React.FC<ITotalProgramOverviewProps> = ({ data }) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const isMobile = useAppSelector(selectIsMobileView);
  const { hasPermission } = usePermission();

  const hasViewProgramListPermission = hasPermission(
    AgentPortalFeatureCode.ProgramAgentManagement.ProgramList.prefix
  );

  const navigateToProgramList = () => {
    return navigate('/programList');
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          ...(isMobile && {
            marginTop: '24px',
          }),
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              color: COLOR_THEME.CustomSx.Dashboard.programAgentBalance.Title.Color,
              fontSize: '1rem',
            }}
          >
            {t('totalProgramNumber')}
          </span>

          {/* View Details */}
          {hasViewProgramListPermission && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ADB7C3',
                fontSize: '10px',
                cursor: 'pointer',
              }}
              onClick={navigateToProgramList}
            >
              <div style={{ marginRight: '4px' }}>{t('viewDetails')}</div>
              <img
                src={RightArrowIconSrc}
                alt={t('viewDetails')}
                style={{ width: '10px', height: '8px' }}
              />
            </div>
          )}
        </Box>

        <Box
          sx={{
            marginTop: '0.5rem',
          }}
        >
          {data ? (
            <span
              style={{
                color: COLOR_THEME.CustomSx.Dashboard.programAgentBalance.Amount.Color,
                fontSize: '2rem',
              }}
            >
              {`${data}`}
            </span>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  );
};

export default TotalProgramOverview;
