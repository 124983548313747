export enum PageMode {
  CREATE = 'create',
  CREATE_BUTTON = 'create_button',
  EDIT = 'edit',
  READ = 'read',
}

export interface CreateExchangeRateFields {
  currentExchangeRate: string;
  nextExchangeRate: string;
  startFrom: Date | null;
}
