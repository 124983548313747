import { ChangeEvent, useMemo, useState } from 'react';

import APIs from '../../../../../api';
import { DialogInOne, MpTextField } from '../../../../../components';
import { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { GridBox } from '../../../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../../../components/MuiGenerals';
import MpTextFieldWithAdornment from '../../../../../components/TextField/MpTextFieldWithAdornment';
import { useAlerting, useTranslation } from '../../../../../hooks';
import useGridBoxInputValidation from '../../../../../hooks/useGridBoxInputValidation';
import { Iprefix } from '../../../../../hooks/useTranslation';
import { useAppSelector } from '../../../../../reducer/hooks';
import { selectProfile } from '../../../../../reducer/profileSlice';
import { checkOnlyLettersAndDigits } from '../../../../../utils';
import { useZusDialogStore } from '../../../../../zustand/store';
import { useZusParams } from '../../hooks';
import { createAgentDialogTranslateKey as TK } from '../../mappings/translate';

const initFields = { distributorAgentId: '', distributorName: '' };

export default function CreateAgentDialog() {
  const translatePrefix: Iprefix = 'distributorAgentList';

  const [fields, setFields] = useState(initFields);
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { validateGridBoxInput } = useGridBoxInputValidation();
  const { alerting } = useAlerting();
  const { jwtDetails } = useAppSelector(selectProfile);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const onClose = async () => {
    await zusDialog.close();

    setFields(initFields);
  };

  const handleDistributorAgentIdChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;

    if (!checkOnlyLettersAndDigits(inputValue) || inputValue.length > 6) {
      return;
    }

    setFields((prev) => ({ ...prev, distributorAgentId: inputValue.toUpperCase() }));
  };

  const daIdEndAdornment = useMemo(() => {
    if (!jwtDetails?.programAgentId) {
      return undefined;
    }

    return (
      <Box sx={{ width: 'max-content', textWrap: 'nowrap' }}>@{jwtDetails.programAgentId}</Box>
    );
  }, [jwtDetails?.programAgentId]);

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.distributorAgentId,
      <MpTextFieldWithAdornment
        mode={'free'}
        startAdornment={{ adornmentNode: 'DA-' }}
        endAdornment={
          daIdEndAdornment && {
            adornmentNode: daIdEndAdornment,
          }
        }
        label={tc('phInputField', { fieldName: t(TK.distributorAgentId) })}
        value={fields.distributorAgentId}
        onChange={handleDistributorAgentIdChange}
      />,
    ],
    [
      TK.distributorName,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.distributorName) })}
        value={fields.distributorName}
        onChange={onChange('distributorName')}
      />,
    ],
  ];

  const onConfirm = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr);

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const { distributorAgentId: distributorAgentIdDigitOnly, distributorName: name } = fields;

    // const distributorAgentId = 'DA-' + distributorAgentIdDigitOnly;

    const res = await APIs.setting.distributorAgentList.postCreateAgent({
      distributorAgentId: distributorAgentIdDigitOnly,
      // distributorAgentId,
      name,
    });

    if (!res) {
      return;
    }

    await onClose();
    alerting('success', t(TK.createdAgentSuccess));
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.createAgentBtn),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: onClose,
    },
    content: <GridBox labelElePairArr={labelElePairArr} />,
    onConfirm,
    onCancel: onClose,
  };

  return <DialogInOne {...dialogConfig} />;
}
