import { ChangeEvent, useCallback, useMemo } from 'react';

import SystemCurrencySingleSelection from '../../../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import { MpTextField } from '../../../../../components';
import { useDatePicker } from '../../../../../components/DatePicker';
import FilterTemplate from '../../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import { useFilterFields, useGenFilterFields } from '../../../../../hooks/useFilter';
import { useZusDialogStore } from '../../../../../zustand/store';
import { getTabTranslatePrefix } from '../../helper';
import { IsearchParams, useZusParams } from '../hooks';
import { filterTableTranslateKey as TK } from '../mappings/translate';

const initFields = {
  ledgerClientId: '',
  pfhNumber: '',
  currency: '',
  sendingAddress: '',
  receivingAddress: '',
  transactionHash: '',
  referenceNumber: '',
  lastLedgerTransactionId: '',
  sendingClientId: '',
  receivingClientId: '',
};

export default function Filter() {
  const translatePrefix = getTabTranslatePrefix('historyTab');
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const DateFields = { CreationDate: useDatePicker(), LastModifiedDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    dateFields: DateFields,
  });

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const {
      ledgerClientId: clientId,
      pfhNumber: customerNumber,
      sendingAddress: fromAddress,
      receivingAddress: toAddress,
      sendingClientId: fromClientId,
      receivingClientId: toClientId,
      transactionHash: txHash,
      referenceNumber: refNo,
      ...rest
    } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateFields.LastModifiedDate;

    return {
      ...rest,
      clientId,
      customerNumber,
      txHash,
      fromAddress,
      toAddress,
      fromClientId,
      toClientId,
      refNo,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  }, [
    fields,
    DateFields.CreationDate.start,
    DateFields.CreationDate.end,
    DateFields.LastModifiedDate.start,
    DateFields.LastModifiedDate.end,
  ]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },
      {
        label: t(TK.lastModifiedTime),
        element: <DateFields.LastModifiedDate.Picker type={'dateTime'} />,
      },
      {
        label: t(TK.ledgerClientId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.ledgerClientId) })}
            value={fields.ledgerClientId}
            onChange={onChange('ledgerClientId')}
          />
        ),
      },
      {
        label: t(TK.pfhNumber),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.pfhNumber) })}
            value={fields.pfhNumber}
            onChange={onChange('pfhNumber')}
          />
        ),
      },
      {
        label: t(TK.currency),
        element: (
          <SystemCurrencySingleSelection
            onClear={() => () => setFields((prev) => ({ ...prev, currency: '' }))}
            label={tc('phInputField', { fieldName: t(TK.currency) })}
            value={fields.currency}
            onChange={onChange('currency')}
            isSelectingCurrency
          />
        ),
      },
      {
        label: t(TK.sendingAddress),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.sendingAddress) })}
            value={fields.sendingAddress}
            onChange={onChange('sendingAddress')}
          />
        ),
      },
      {
        label: t(TK.receivingAddress),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.receivingAddress) })}
            value={fields.receivingAddress}
            onChange={onChange('receivingAddress')}
          />
        ),
      },
      {
        label: t(TK.transactionHash),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.transactionHash) })}
            value={fields.transactionHash}
            onChange={onChange('transactionHash')}
          />
        ),
      },
      {
        label: t(TK.referenceNumber),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.referenceNumber) })}
            value={fields.referenceNumber}
            onChange={onChange('referenceNumber')}
          />
        ),
      },
      {
        label: t(TK.lastLedgerTransactionId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.lastLedgerTransactionId) })}
            value={fields.lastLedgerTransactionId}
            onChange={onChange('lastLedgerTransactionId')}
          />
        ),
      },
      {
        label: t(TK.sendingClientId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.sendingClientId) })}
            value={fields.sendingClientId}
            onChange={onChange('sendingClientId')}
          />
        ),
      },
      {
        label: t(TK.receivingClientId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.receivingClientId) })}
            value={fields.receivingClientId}
            onChange={onChange('receivingClientId')}
          />
        ),
      },
    ],
    [fields, DateFields]
  );

  return <FilterTemplate filterFields={filterFields} onSearch={onSearch} onReset={onReset} />;
}
