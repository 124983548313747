import React, { useState } from 'react';
import { Box } from '../../components/MuiGenerals';

import APIs from '../../api';
import { useQuery } from 'react-query';
import HkdmAgentBalance from './components/HkdmAgentBalance';
import DistributionOverview from './components/DistributionOverview';
import AssetOverview from './components/AssetOverview';
import ProgramAgentOverview from './components/ProgramAgentOverview';
import { EnumFilterDay } from './components/ProgramAgentOverview/types/EnumFilterDay';
import { convertDateTimeStringToFormat } from '../../utils/dateUtils';
import { usePermission } from '../../hooks';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import { useAppSelector } from '../../reducer/hooks';
import { selectProfile } from '../../reducer/profileSlice';
import { selectIsMobileView } from '../../reducer/mediaSlice';
import TotalProgramOverview from './components/TotalProgramOverview';
import { useGetProgramEnum } from '../../hooks/useProgram';

export default function DashBoard() {
  const { hasPermission } = usePermission();
  const hasDashboardPermission = hasPermission(AgentPortalFeatureCode.Dashboard.Dashboard.prefix);
  const { EnumAllProgram } = useGetProgramEnum();

  const [overview, setOverview] = useState<any>(null);
  const [programAgentDailyPurchase, setProgramAgentDailyPurchase] = useState<any>(null);
  const [programAgentDailyRebate, setProgramAgentDailyRebate] = useState<any>(null);
  const [totalCustodyAsset, setTotalCustodyAsset] = useState<any>(null);
  const [programAgentDailyPurchaseDate, setProgramAgentDailyPurchaseDate] = useState<string>(
    EnumFilterDay.SevenDay
  );
  const [programAgentDailyRebateDate, setProgramAgentDailyRebateDate] = useState<string>(
    EnumFilterDay.SevenDay
  );

  const profile = useAppSelector(selectProfile);
  const isMobile = useAppSelector(selectIsMobileView);
  const isLogin = profile.isLogged;

  async function getOverview() {
    const res = await APIs.dashboard.overview.get();
    if (!res) return null;
    return res;
  }

  async function getProgramAgentDailyPurchase() {
    const settlementDateFrom = new Date();
    settlementDateFrom.setDate(
      settlementDateFrom.getDate() - parseInt(programAgentDailyPurchaseDate)
    );
    const settlementDateTo = new Date();
    settlementDateTo.setDate(settlementDateTo.getDate() - 1);

    const dateFrom = convertDateTimeStringToFormat(settlementDateFrom.toISOString(), 'yyyy-MM-dd');
    const dateTo = convertDateTimeStringToFormat(settlementDateTo.toISOString(), 'yyyy-MM-dd');

    const currency = 'HKD';
    const res = await APIs.dashboard.programAgentDailyPurchase.get({
      settlementDateFrom: dateFrom,
      settlementDateTo: dateTo,
      currency,
    });
    if (!res) return null;
    return res;
  }

  async function getProgramAgentDailyRebate() {
    const settlementDateFrom = new Date();
    settlementDateFrom.setDate(
      settlementDateFrom.getDate() - parseInt(programAgentDailyRebateDate)
    );
    const settlementDateTo = new Date();
    settlementDateTo.setDate(settlementDateTo.getDate() - 1);

    const dateFrom = convertDateTimeStringToFormat(settlementDateFrom.toISOString(), 'yyyy-MM-dd');
    const dateTo = convertDateTimeStringToFormat(settlementDateTo.toISOString(), 'yyyy-MM-dd');

    const currency = 'HKD';
    const res = await APIs.dashboard.programAgentDailyRebate.get({
      settlementDateFrom: dateFrom,
      settlementDateTo: dateTo,
      currency,
    });
    if (!res) return null;
    return res;
  }

  async function getTotalCustodyAsset() {
    const res = await APIs.dashboard.totalCustodyAsset.get();
    if (!res) return null;
    return res;
  }

  const { isLoading: isOverviewLoading, isRefetching: isOverviewRefetching } = useQuery(
    'getOverview',
    getOverview,
    {
      enabled: isLogin,
      onSuccess: (data) => {
        setOverview(data);
      },
    }
  );

  const {
    isLoading: isProgramAgentDailyPurchaseLoading,
    isRefetching: isProgramAgentDailyPurchaseRefetching,
  } = useQuery(
    ['getProgramAgentDailyPurchase', programAgentDailyPurchaseDate],
    getProgramAgentDailyPurchase,
    {
      enabled: isLogin,
      onSuccess: (data) => {
        setProgramAgentDailyPurchase(data);
      },
    }
  );

  const {
    isLoading: isProgramAgentDailyRebateLoading,
    isRefetching: isProgramAgentDailyRebateRefetching,
  } = useQuery(
    ['getProgramAgentDailyRebate', programAgentDailyRebateDate],
    getProgramAgentDailyRebate,
    {
      enabled: isLogin,
      onSuccess: (data) => {
        setProgramAgentDailyRebate(data);
      },
    }
  );

  const { isLoading: isTotalCustodyAssetLoading, isRefetching: isTotalCustodyAssetRefetching } =
    useQuery('getTotalCustodyAsset', getTotalCustodyAsset, {
      enabled: isLogin,
      onSuccess: (data) => {
        setTotalCustodyAsset(data);
      },
    });

  if (!isLogin) return <></>;

  return (
    <>
      {hasDashboardPermission ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            padding: isMobile ? '0 32px' : '0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
                lg: 'row',
              },
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <HkdmAgentBalance
              data={overview?.walletBalances || null}
              isLoading={isOverviewLoading || isOverviewRefetching}
            />
            <TotalProgramOverview data={!EnumAllProgram ? "" : Object.keys(EnumAllProgram).length} />
          </Box>
          <Box
            sx={{
              marginTop: '0.5rem',
              display: 'flex',
              width: '100%',
              minHeight: '400px',
              flexDirection: {
                xs: 'column',
                md: 'column',
                lg: 'row',
              },
            }}
          >
            <DistributionOverview
              data={overview}
              isLoading={isOverviewLoading || isOverviewRefetching}
            />
            <AssetOverview
              data={totalCustodyAsset}
              isLoading={isTotalCustodyAssetLoading || isTotalCustodyAssetRefetching}
            />
          </Box>
          <Box>
            <ProgramAgentOverview
              purchaseData={programAgentDailyPurchase}
              isPurchaseLoading={
                isProgramAgentDailyPurchaseLoading || isProgramAgentDailyPurchaseRefetching
              }
              rebateData={programAgentDailyRebate}
              isRebateLoading={
                isProgramAgentDailyRebateLoading || isProgramAgentDailyRebateRefetching
              }
              purchaseFilterDay={programAgentDailyPurchaseDate}
              setPurchaseFilterDay={setProgramAgentDailyPurchaseDate}
              rebateFilterDay={programAgentDailyRebateDate}
              setRebateFilterDay={setProgramAgentDailyRebateDate}
            />
          </Box>
        </Box>
      ) : (
        <>Welcome to CAMS</>
      )}
    </>
  );
}
