import { defaultListParams } from '../../../../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../../../../common/filterTable/helpers/cleanParams';
import { convertDateTimeStringToFormat } from '../../../../../../utils/dateUtils';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      settlementDateFrom,
      settlementDateTo,
      distributorAgentId,
      programName,
      pfhNo,
      currency,
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      settlementDateFrom: convertDateTimeStringToFormat(settlementDateFrom, 'yyyy-MM-dd'),
      settlementDateTo: convertDateTimeStringToFormat(settlementDateTo, 'yyyy-MM-dd'),
      distributorAgentId,
      programName,
      pfhNo,
      currency,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
