import { ChangeEvent, useCallback, useMemo } from 'react';

import { EnumAgentCustomerApplicationStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent/Enum/AgentCustomerApplicationStatus';

import { MpTextField, SingleSelection } from '../../../../components';
import AgentProgramNameSelection from '../../../../components/AgentProgramNameSelection';
import DaIdAutoCompleteFilter from '../../../../components/DaIdAutoCompleteFilter';
import { useDatePicker } from '../../../../components/DatePicker';
import FilterTemplate from '../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import PhoneCodeNumberBundle from '../../../../components/PhoneCodeNumberBundle';
import { useTranslation } from '../../../../hooks';
import {
  useFilterFields,
  useGenFilterFields
} from '../../../../hooks/useFilter';
import { Iprefix } from '../../../../hooks/useTranslation';
import { IsearchParams, useZusParams } from '../hooks';
import { filterTableTranslateKey as TK } from '../mappings/translate';

const initFields = {
  distributorAgentId: '',
  applicationStatus: '',
  programName: '',
  referralCode: '',
  emailAddress: '',
  phoneCountryCode: '',
  phoneNumber: '',
};

export default function Filter() {
  const translatePrefix: Iprefix = 'clientApplication';

  const { t, tc } = useTranslation(translatePrefix);

  const DateFields = { CreationDate: useDatePicker(), LastModifiedDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    dateFields: DateFields,
  });

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const { emailAddress: email, applicationStatus: status, ...rest } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateFields.LastModifiedDate;

    return {
      ...rest,
      email,
      status,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  }, [
    fields,
    DateFields.CreationDate.start,
    DateFields.CreationDate.end,
    DateFields.LastModifiedDate.start,
    DateFields.LastModifiedDate.end,
  ]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
    (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFields((f) => ({ ...f, [field]: e.target.value }));
    };
  const onClearField = (field: keyof typeof fields) => () => {
    setFields((f) => ({ ...f, [field]: '' }));
  };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },
      {
        label: t(TK.lastModifiedTime),
        element: <DateFields.LastModifiedDate.Picker type={'dateTime'} />,
      },
      {
        label: t(TK.distributorAgentId),
        element: (
          <DaIdAutoCompleteFilter
            value={fields.distributorAgentId}
            onChange={(value) => setFields((f) => ({ ...f, distributorAgentId: value }))}
            onClear={() => setFields((f) => ({ ...f, distributorAgentId: '' }))}
          />
        ),
      },
      {
        label: t(TK.applicationStatus),
        element: (
          <SingleSelection
            label={tc('phSelection', { fieldName: t(TK.applicationStatus) })}
            value={fields.applicationStatus}
            clearSelect={onClearField('applicationStatus')}
            onChange={onChange('applicationStatus')}
            enumData={EnumAgentCustomerApplicationStatus}
          />
        ),
      },
      {
        label: t(TK.programName),
        element: (
          <AgentProgramNameSelection
            mode="all"
            clearSelect={onClearField('programName')}
            value={fields.programName}
            onChange={onChange('programName')}
          />
        ),
      },
      {
        label: t(TK.referralCode),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.referralCode) })}
            value={fields.referralCode}
            onChange={onChange('referralCode')}
          />
        ),
      },
      {
        label: t(TK.emailAddress),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.emailAddress) })}
            value={fields.emailAddress}
            onChange={onChange('emailAddress')}
          />
        ),
      },
      {
        label: t(TK.phoneNumber),
        element: (
          <PhoneCodeNumberBundle
            phoneCode={{
              label: t(TK.phoneCountryCode),
              value: fields.phoneCountryCode,
              onChange: onChange('phoneCountryCode'),
            }}
            phoneNumber={{
              label: t(TK.phoneNumber),
              value: fields.phoneNumber,
              onChange: onChange('phoneNumber'),
            }}
          />
        ),
      },
    ],
    [fields, DateFields]
  );

  return <FilterTemplate filterFields={filterFields} onSearch={onSearch} onReset={onReset} />;
}
