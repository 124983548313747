import React, { useEffect } from 'react';
import useRunOnceEffect from '../../../../hooks/useRunOnceEffect';
import { useTranslation } from '../../../../hooks';

export enum RequestEmailVerificationCodeBtnUiStyle {
  COLOR_FILLED = 'colorFilled',
  OUTLINED = 'outlined',
}
interface IProps {
  // async function
  onClick: () => Promise<any>;
  uiStyle?: RequestEmailVerificationCodeBtnUiStyle;
  requestCodeOnMount?: boolean;
}

// in seconds
const THROTTLE_TIME = 60;

const RequestEmailVerificationCodeBtn = ({
  onClick,
  uiStyle = RequestEmailVerificationCodeBtnUiStyle.COLOR_FILLED,
  requestCodeOnMount,
}: IProps) => {
  const { t } = useTranslation('login');

  const [buttonText, setButtonText] = React.useState(t('send'));
  const [allowClick, setAllowClick] = React.useState(true);

  let currentTime: number;

  useRunOnceEffect(() => {
    requestCodeOnMount && handleClick();
  });

  const handleClick = async () => {
    if (!onClick || !allowClick) return;

    const res = await onClick();

    if (!res) return;

    currentTime = new Date().getTime();
    setAllowClick(false);
    setButtonText(`${THROTTLE_TIME}s`);
    countDown();
  };

  // once button click => button will be disable and show count down text
  // after THROTTLE_TIME, button will be re-enable
  const countDown = () => {
    const intervalId = setInterval(() => {
      const diff = new Date().getTime() - currentTime;
      const seconds = THROTTLE_TIME - Math.floor(diff / 1000);

      if (seconds > 0) {
        setButtonText(`${seconds}s`);
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setAllowClick(true);
      setButtonText(t('resend'));
    }, THROTTLE_TIME * 1000);
  };

  const ColorFilledBtn = () => {
    return (
      <div
        style={{
          width: '120px',
          height: '36px',
          boxSizing: 'border-box',
          background:
            'linear-gradient(144.39deg, #4F7CD8 7.57%, #63A3D5 53.85%, #7ED7D2 87.35%, #7ED7D2 95.73%)',
          borderRadius: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '12px',
          padding: '12px',
          fontSize: '12px',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {buttonText}
      </div>
    );
  };

  const OutlinedBtn = () => {
    return (
      <div
        style={{
          width: '104px',
          height: '32px',
          border: '1px solid #919BB4',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {buttonText}
      </div>
    );
  };

  return uiStyle === RequestEmailVerificationCodeBtnUiStyle.COLOR_FILLED ? (
    <ColorFilledBtn />
  ) : (
    <OutlinedBtn />
  );
};

export default RequestEmailVerificationCodeBtn;
