import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import { ApproveHistory } from '../../../common/approval/types/ApproveHistory';
import axios from '../../axiosInstance';
import { IlistResult } from '../../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(AgentPortal.EPtRebate.rebateTask.getAll, input);
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<any>>(AgentPortal.EPtRebate.rebateTask.export, input);
};

const getApproveHistory = async (params: any, config?: any) => {
  const input = {
    params,
    ...config,
  };
  return await axios.get<any, ApproveHistory[]>(AgentPortal.EPtRebate.rebateTask.detail, input);
};

const approve = async (params: { id: string | number; remarks: string }, config?: any) => {
  return await axios.post<any, any>(AgentPortal.EPtRebate.rebateTask.approve, params, config);
};

const reject = async (params: { id: string | number; remarks: string }, config?: any) => {
  return await axios.post<any, any>(AgentPortal.EPtRebate.rebateTask.reject, params, config);
};

export default { getAll, getExport, getApproveHistory, approve, reject };
