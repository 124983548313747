import React from "react";
import DashboardPanel from "../../../../components/Dashboard/DashboardPanel";
import TotalRebateAmountGraph from "./components/TotalRebateAmountGraph";
import { useTranslation } from "../../../../hooks";
import TotalPurchaseAmountGraph from "./components/TotalPurchaseAmountGraph";
import { IProgramAgentDailyPurchase, IProgramAgentDailyRebate } from "../../../../api/types";

interface IProgramAgentOverviewProps {
    rebateData: IProgramAgentDailyRebate | null;
    purchaseData: IProgramAgentDailyPurchase | null;
    isRebateLoading: boolean;
    isPurchaseLoading: boolean;
    rebateFilterDay: string;
    setRebateFilterDay: (day: string) => void;
    purchaseFilterDay: string;
    setPurchaseFilterDay: (day: string) => void;
}

const ProgramAgentOverview: React.FC<IProgramAgentOverviewProps> = ({
    rebateData,
    purchaseData,
    isRebateLoading,
    isPurchaseLoading,
    rebateFilterDay,
    setRebateFilterDay,
    purchaseFilterDay,
    setPurchaseFilterDay
}) => {
    const { t } = useTranslation("dashboard");
    return (
        <DashboardPanel title={t("programAgentOverview")} spacing={2}>
            <TotalRebateAmountGraph
                isLoading={isRebateLoading}
                totalAmount={rebateData?.totalRebateAmount || 0}
                filterDay={rebateFilterDay}
                setFilterDay={setRebateFilterDay}
                currency={t("hkd")}
                graphData={rebateData?.data || []}
            />
            <TotalPurchaseAmountGraph
                isLoading={isPurchaseLoading}
                totalAmount={purchaseData?.totalPurchaseAmount || 0}
                currency={t("hkd")}
                filterDay={purchaseFilterDay}
                setFilterDay={setPurchaseFilterDay}
                graphData={purchaseData?.data || []}
            />
        </DashboardPanel>
    );
}

export default ProgramAgentOverview;
