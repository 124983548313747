import { AgentPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumCustomerDocumentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { IapiGetAllRes } from '../../views/ClientManagement/ClientApplication/types';
import axios from '../axiosInstance';
import { IlistResult } from '../types';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EPtClientManagement.clientApplication.getAll,
    input
  );
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<IapiGetAllRes>>(
    AgentPortal.EPtClientManagement.clientApplication.export,
    input
  );
};

const getDownload = async (
  params: { applicationNumber: string; type: EnumCustomerDocumentType },
  config?: any
) => {
  const input = {
    params,
    ...config,
  };

  return await axios.get<any, any>(
    AgentPortal.EPtClientManagement.clientApplication.viewDocument,
    input
  );
};
export default { getAll, getExport, getDownload };
