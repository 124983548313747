import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

export interface StuffState {
  loadingTimeMs: number;
  progress: number;
  showLoadingSpin: boolean;
  viewPermission: string;
  isRefreshingToken: boolean;
}

const initialState: StuffState = {
  loadingTimeMs: 0,
  progress: 0,
  showLoadingSpin: false,
  viewPermission: '',
  isRefreshingToken: false,
};

export const stuffSlice = createSlice({
  name: 'stuff',
  initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<number>) => {
      // state.loadingTimeMs = action.payload;
      state.progress = action.payload;
      state.showLoadingSpin = true;
    },
    endLoading: (state) => {
      // state.loadingTimeMs = 0;
      state.progress = 1;
      state.showLoadingSpin = false;
    },
    setView: (state, action: PayloadAction<string>) => {
      state.viewPermission = action.payload;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
    },
  },
});

export const { startLoading, endLoading, setView, setIsRefreshingToken } = stuffSlice.actions;

export const selectStuff = (state: RootState) => state.stuff;

export default stuffSlice.reducer;
